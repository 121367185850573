import React from "react";
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';

export function LostObjectDetail(props) { 
    let statusObject = '';
    try {
        switch (props.lostObjectSelected.ESTADO) {
            case 1:
                statusObject = 'Registrado';
                break;
            case 2:
                statusObject = 'Reclamado';
                break;
            case 3:
                statusObject = 'Entregado';
                break;
            case 4:
                statusObject = 'Archivado';
                break;
            default:
                statusObject = '';
                break;
        }
    } catch (error) {
        console.error(error.message);
    }

    return (
        <div>
            <Dialog 
                header="Header" 
                visible={props.showHide > 0}
                onHide={ () => { props.setShowHide(false) } }
                style={{ width: '50vw' }} 
                breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <p className="m-0">
                    <label className="font-bold text-2xl">Campus:</label> <span className="text-xl"> { props.lostObjectSelected.LUGAR }</span>
                </p>
                <p className="m-0">
                    <label className="font-bold text-2xl">Fecha:</label> <span className="text-xl"> { props.lostObjectSelected.FECHA }</span>
                </p>
                <p className="m-0">
                    <label className="font-bold text-2xl">Objeto:</label> <span className="text-xl"> { props.lostObjectSelected.TIPO===1?'Encontrado':'Buscado' }</span>
                </p>
                <p className="m-0">
                    <label className="font-bold text-2xl">Descripción:</label> <span className="text-xl"> { props.lostObjectSelected.DETALLE }</span>
                </p>
                <p className="m-0">
                    <label className="font-bold text-2xl">Estado:</label> <span className="text-xl"> { statusObject }</span>
                </p>
                
                <Image 
                    className="text-center m-3"
                    src={`${process.env.REACT_APP_UPLOAD_URL+'/lostObjects'}/${props.lostObjectSelected.RUTA}`}
                    width="300"
                    preview 
                    template="Ver" 
                    alt="Foto" />

            </Dialog>
        </div>
    );
}
        