import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { addLocale } from "primereact/api";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";
import calConfig from "../../../constants/calendar.config";
import { InstitucionService } from "../../../services/convenios/InstitucionService";
import { ConvenioService } from "../../../services/convenios/ConvenioService";
import { RepresentanteService } from "../../../services/convenios/RepresentanteService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCircle } from "@fortawesome/free-solid-svg-icons";

export const DialogAddEdit = (props) => {
    const [instituciones, setInstituciones] = useState([]);
    const [representantes, setRepresentantes] = useState([]);
    const [institucionService] = useState(new InstitucionService());
    const [convenioService] = useState(new ConvenioService());
    const [representanteService] = useState(new RepresentanteService());

    const dtObjetosConvenios = [
        {
            label: "CONVENIOS ESPECÍFICOS",
            code: "CE",
            items: [
                { label: "Prácticas", value: "PRACTICA", data: "PREGRADO" },
                { label: "Pasantías", value: "PASANTIA", data: "PREGRADO" },
                {
                    label: "Intercambio pregrado",
                    value: "INTER_PREG",
                    data: "PREGRADO",
                },
                {
                    label: "Intercambio posgrado",
                    value: "INTER_POSG",
                    data: "POSGRADO",
                },
                {
                    label: "Proyectos de investigación",
                    value: "PROY_INVEST",
                    data: "INVETIGADOR",
                },
                {
                    label: "Convenios de programas de doble titulación-posgrado",
                    value: "TITUL_POSG",
                    data: "POSGRADO",
                },
                {
                    label: "Convenios de programas de doble titulación-Pregrado",
                    value: "TITUL_PREG",
                    data: "PREGRADO",
                },
                { label: "Otros", value: "OTHER", data: "" },
            ],
        },
        {
            label: "CONVENIOS MARCO",
            code: "CM",
            items: [
                {
                    label: "Programas de colaboración institucional",
                    value: "PROG_INST",
                    data: "INVETIGADOR",
                },
            ],
        },
    ];

    const formik = useFormik({
        initialValues: {
            cod_empresa: "",
            id_representante: "",
            nombre_rep: "",
            docId_rep: "",
            cargo_rep: "",
            fechaFirma: "",
            fechaIni: "",
            fechaFin: "",
            cantPlazas: 1,
            grado: "",
            doc_digital: "",
            radioOldNew_rep: "NEW",
        },
        validationSchema: Yup.object({
            cod_empresa: Yup.string().required("Seleccione una empresa!!"),
            id_representante: Yup.string().when("radioOldNew_rep", {
                is: "OLD",
                then: Yup.string().required("Seleccione un representante!!"),
            }),
            nombre_rep: Yup.string().when("radioOldNew_rep", {
                is: "NEW",
                then: Yup.string().required("Campo requerido!!"),
            }),
            docId_rep: Yup.string().when("radioOldNew_rep", {
                is: "NEW",
                then: Yup.string().required("Campo requerido!!"),
            }),
            cargo_rep: Yup.string().when("radioOldNew_rep", {
                is: "NEW",
                then: Yup.string().required("Campo requerido!!"),
            }),
            fechaFirma: Yup.date().required("Seleccione una fecha!!"),
            fechaIni: Yup.date().required("Seleccione una fecha!!"),
            fechaFin: Yup.date().required("Seleccione una fecha!!"),
            cantPlazas: Yup.number(),
            doc_digital: Yup.string().required("Documento requerido!!"),
            grado: Yup.string().required("Campo requerido!!"),
        }),
        onSubmit: async (data) => {
            console.log(data);
            if (props.showHide === 1) {
                //Create
                await createData(data);
            } else if (props.showHide === 2) {
                //Update
                await updateData(data);
            }

            onHide();
            props.loadDataTable();
        },
    });

    const createData = async (data) => {
        if (formik.values.radioOldNew_rep === "OLD") {
            delete data.nombre_rep;
            delete data.docId_rep;
            delete data.cargo_rep;
            await convenioService.create(data);
        } else if (formik.values.radioOldNew_rep === "NEW") {
            let newRep = {
                nombre_rep: data.nombre_rep,
                docId_rep: data.docId_rep,
                cargo_rep: data.cargo_rep,
                cod_empresa: data.cod_empresa,
            };
            let representante = await representanteService.create(newRep);
            delete data.nombre_rep;
            delete data.docId_rep;
            delete data.cargo_rep;
            data.id_representante = representante.ID;
            await convenioService.create(data);
        }
    };

    const updateData = async (data) => {
        if (formik.values.radioOldNew_rep === "OLD") {
            delete data.nombre_rep;
            delete data.docId_rep;
            delete data.cargo_rep;
            convenioService.update(props.selectedConvenio.ID, data);
        } else if (formik.values.radioOldNew_rep === "NEW") {
            let newRepresentante = {
                nombre_rep: data.nombre_rep,
                docId_rep: data.docId_rep,
                cargo_rep: data.cargo_rep,
                cod_empresa: data.cod_empresa,
            };
            let representante = await representanteService.create(newRepresentante);
            delete data.nombre_rep;
            delete data.docId_rep;
            delete data.cargo_rep;
            data.id_representante = representante.ID;
            convenioService.update(props.selectedConvenio.ID, data);
        }
    };

    addLocale(calConfig.sp.name, calConfig.sp.config);

    const onInstitucionChange = (e) => {
        formik.handleChange(e);
        let representantes = getSelectedRespresentantes(e.value);
        setRepresentantes(representantes);
        formik.setFieldValue("radioOldNew_rep", representantes.length ? "OLD" : "NEW");
    };

    const getSelectedRespresentantes = (idInstitucion) => {
        let institucion = instituciones.find((institucion) => idInstitucion === institucion.COD_INSTITUCION);
        let representantes = institucion && institucion.REPRESENTANTES ? institucion.REPRESENTANTES : [];
        return representantes;
    };

    const selectedInstitucionTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.RAZON_SOCIAL}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const institucionOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.RAZON_SOCIAL}</div>
            </div>
        );
    };

    const selectedRepresentanteTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.NOMBRE}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const representanteOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.NOMBRE}</div>
            </div>
        );
    };

    const onHide = () => {
        formik.resetForm();
        props.setShowHide(0);
    };

    const onShow = () => {
        formik.resetForm();
        let convenio = props.selectedConvenio;
        if (!(convenio && props.showHide === 2)) {
            return;
        }
        let representantes = getSelectedRespresentantes(convenio.REPRESENTANTE.COD_INSTITUCION);
        setRepresentantes(representantes);
        convenio.FECHA_FIRMA = new Date(parseInt(convenio.FECHA_FIRMA));
        convenio.FECHAINI = new Date(parseInt(convenio.FECHAINI));
        convenio.FECHAFIN = new Date(parseInt(convenio.FECHAFIN));
        formik.setValues(
            {
                radioOldNew_rep: "OLD",
                cod_empresa: convenio.INSTITUCION.COD_INSTITUCION,
                id_representante: convenio.REPRESENTANTE.ID,
                fechaFirma: convenio.FECHA_FIRMA,
                fechaIni: convenio.FECHAINI,
                fechaFin: convenio.FECHAFIN,
                cantPlazas: convenio.PLAZAS,
                grado: convenio.GRADO_ACADEMICO,
                doc_digital: convenio.CARNET,
                nombre_rep: "",
                docId_rep: "",
                cargo_rep: "",
            },
            false
        );
    };

    useEffect(() => {
        institucionService.getInstituciones().then((data) => {
            setInstituciones(data);
        });
    }, [institucionService]);

    const classOld = () => {
        return formik.values.radioOldNew_rep !== "OLD" ? "ocultar" : "";
    };

    const classNew = () => {
        return formik.values.radioOldNew_rep !== "NEW" ? "ocultar" : "";
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const chooseOptions = {
        label: "Seleccionar documento",
        icon: "pi pi-fw pi-upload",
    };

    const onUploadFile = (file) => {
        let response = JSON.parse(file.xhr.response);
        formik.setFieldValue("doc_digital", response.filename);
    };

    const groupedItemTemplate = (option) => {
        return (
            <div className="p-d-flex p-ai-center country-item">
                <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "2em", color: "var(--teal-500)" }} />
                &nbsp;
                <b>{option.label}</b>
            </div>
        );
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="country-item">
                &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={faCircle} style={{ fontSize: "0.8em", color: "var(--teal-500)" }} />
                &nbsp;
                <i>{option.label}</i>
            </div>
        );
    };

    return (
        <div>
            <Dialog header="Agregar/Editar Convenio" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                <form onSubmit={formik.handleSubmit} className="fluid">
                    <div className="card">
                        <h3>Datos de Empresa</h3>
                        <div className="fluid">
                            <div className="field grid">
                                <label htmlFor="cod_empresa" className="col-12 md:col-2">
                                    Nombre:
                                </label>
                                <div className="col-12 md:col-10">
                                    <Dropdown
                                        id="cod_empresa"
                                        name="cod_empresa"
                                        value={formik.values.cod_empresa}
                                        options={instituciones}
                                        onChange={onInstitucionChange}
                                        optionLabel="RAZON_SOCIAL"
                                        optionValue="COD_INSTITUCION"
                                        filter
                                        showClear
                                        filterBy="RAZON_SOCIAL"
                                        placeholder="Selecione una empresa"
                                        valueTemplate={selectedInstitucionTemplate}
                                        itemTemplate={institucionOptionTemplate}
                                        autoFocus={true}
                                        className="inputfield w-full"
                                    />
                                    {getFormErrorMessage("cod_empresa")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h3>Datos de Representante</h3>
                        <div className="field grid">
                            <label htmlFor="representanteRegistro" className="col-12 md:col-2">
                                Registro
                            </label>
                            <div className="col-12 md:col-10">
                                <div className="formgroup-inline">
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="radioOldNew_rep01" name="radioOldNew_rep" value="OLD" onChange={formik.handleChange} checked={formik.values.radioOldNew_rep === "OLD"} />
                                        <label htmlFor="radioOldNew_rep01">Existente</label>
                                    </div>
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="radioOldNew_rep02" name="radioOldNew_rep" value="NEW" onChange={formik.handleChange} checked={formik.values.radioOldNew_rep === "NEW"} />
                                        <label htmlFor="radioOldNew_rep02">Nuevo</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classOld()}>
                            <div className="fluid">
                                <div className="field grid">
                                    <label htmlFor="id_representante" className="col-12 md:col-2">
                                        Nombre
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <Dropdown
                                            id="id_representante"
                                            name="id_representante"
                                            value={formik.values.id_representante}
                                            options={representantes}
                                            onChange={formik.handleChange}
                                            optionLabel="NOMBRE"
                                            optionValue="ID"
                                            filter
                                            showClear
                                            filterBy="NOMBRE"
                                            placeholder="Selecione un representante"
                                            valueTemplate={selectedRepresentanteTemplate}
                                            itemTemplate={representanteOptionTemplate}
                                            className="inputfield w-full"
                                        />
                                        {getFormErrorMessage("id_representante")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classNew()}>
                            <div className="fluid">
                                <div className="field grid">
                                    <label htmlFor="nombre_rep" className="col-12 md:col-2">
                                        Nombre completo
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText id="nombre_rep" name="nombre_rep" type="text" value={formik.values.nombre_rep} onChange={formik.handleChange} className="inputfield w-full"/>
                                        {getFormErrorMessage("nombre_rep")}
                                    </div>
                                </div>
                            </div>
                            <div className="fluid">
                                <div className="field grid">
                                    <label htmlFor="docId_rep" className="col-12 md:col-2">
                                        Doc. Identificación
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText id="docId_rep" name="docId_rep" type="text" value={formik.values.docId_rep} onChange={formik.handleChange} className="inputfield w-full"/>
                                        {getFormErrorMessage("docId_rep")}
                                    </div>
                                </div>
                            </div>
                            <div className="fluid">
                                <div className="field grid">
                                    <label htmlFor="cargo_rep" className="col-12 md:col-2">
                                        Cargo
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText id="cargo_rep" name="cargo_rep" type="text" value={formik.values.cargo_rep} onChange={formik.handleChange} className="inputfield w-full"/>
                                        {getFormErrorMessage("cargo_rep")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h3>Datos de Convenio</h3>
                        <div className="fluid">
                            <div className="field grid">
                                <label htmlFor="grado" className="col-12 md:col-2">
                                    Objeto Del Convenio
                                </label>
                                <div className="col-12 md:col-10">
                                    <div>
                                        <Dropdown
                                            id="grado"
                                            name="grado"
                                            value={formik.values.grado}
                                            options={dtObjetosConvenios}
                                            onChange={formik.handleChange}
                                            optionLabel="label"
                                            optionGroupLabel="label"
                                            optionGroupChildren="items"
                                            optionGroupTemplate={groupedItemTemplate}
                                            itemTemplate={countryOptionTemplate}
                                            className="inputfield w-full"
                                        />
                                        {getFormErrorMessage("grado")}
                                    </div>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="doc_digital" className="col-12 md:col-2">
                                    Documento digital
                                </label>
                                <div className="col-12 md:col-10">
                                    <sub>Maximo 3MB</sub>
                                    <FileUpload
                                        id="doc_digital"
                                        mode="basic"
                                        name="doc_digital"
                                        url={process.env.REACT_APP_UPLOAD_URL}
                                        chooseOptions={chooseOptions}
                                        multiple={false}
                                        onUpload={onUploadFile}
                                        auto
                                        maxFileSize="3145728"
                                        invalidFileSizeMessageSummary="{0} Tamaño de archivo no válido"
                                        invalidFileSizeMessageDetail="El tamaño máximo de carga es {0}"
                                        accept=".pdf,image/*"
                                        className="inputfield w-full"
                                    ></FileUpload>
                                    <b>{formik.values.doc_digital}</b>
                                    {getFormErrorMessage("doc_digital")}
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="fechaFirma" className="col-12 md:col-2">
                                    Fecha firma
                                </label>
                                <div className="col-12 md:col-10">
                                    <Calendar id="fechaFirma" name="fechaFirma" value={formik.values.fechaFirma} onChange={formik.handleChange} locale="es" dateFormat="dd/mm/yy" className="inputfield w-full"/>
                                    {getFormErrorMessage("fechaFirma")}
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="fechaIni" className="col-12 md:col-2">
                                    Fecha inicio
                                </label>
                                <div className="col-12 md:col-10">
                                    <Calendar id="fechaIni" name="fechaIni" value={formik.values.fechaIni} onChange={formik.handleChange} locale="es" dateFormat="dd/mm/yy" className="inputfield w-full"/>
                                    {getFormErrorMessage("fechaIni")}
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="fechaFin" className="col-12 md:col-2">
                                    Fecha fin
                                </label>
                                <div className="col-12 md:col-10">
                                    <Calendar id="fechaFin" name="fechaFin" value={formik.values.fechaFin} onChange={formik.handleChange} locale="es" dateFormat="dd/mm/yy" className="inputfield w-full"/>
                                    {getFormErrorMessage("fechaFin")}
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="cantPlazas" className="col-12 md:col-2">
                                    Cantidad de plazas
                                </label>
                                <div className="col-12 md:col-10">
                                    <InputText id="cantPlazas" name="cantPlazas" type="number" value={formik.values.cantPlazas} onChange={formik.handleChange} className="inputfield w-full" />
                                    {getFormErrorMessage("cantPlazas")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button label="Cancelar" type="reset" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                        <Button type="submit" label="Guardar" icon="pi pi-save" autoFocus />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
