module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getInstituciones: () => {
            return `
            instituciones{
                COD_INSTITUCION
                RAZON_SOCIAL
                TELEFONO_INS
                REPRESENTANTES{
                    ID
                    NOMBRE
                }
            }`;
        },
        getInstitucion: (id) => {
            return `
            institucion(id:${id}){
                RAZON_SOCIAL
                TELEFONO_INS
                DOMICILIO_INST
                REPRESENTANTES{
                    NOMBRE
                }
            }`;
        },
    },
};
