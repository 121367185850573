import React from "react";
import { DocumentosList } from "../components/gestionCalidad/DocumentosList";

export const GestionCalidad = (props) => {
    return (
        <div>
            <div className="card">
                <DocumentosList></DocumentosList>
            </div>
        </div>
    );
};
