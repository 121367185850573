import React from "react";
//import { Timmer } from "../components/smartCertificate/Timmer";
import { ValidateDiploma } from "../components/smartCertificate/ValidateDiploma";

export const SmartCertificate = (props) => {
    return (
        <div>
            <div className="card">
                {/* <Timmer></Timmer> */}
                <ValidateDiploma></ValidateDiploma>
            </div>
        </div>
    );
};
