import { faCheckSquare, faLink } from "@fortawesome/free-solid-svg-icons";

const iconObj = {
    "check-square": faCheckSquare,
    "sign-out": "pi pi-fw pi-sign-out",
    sitemap: "pi pi-fw pi-sitemap",
    sync: "pi pi-fw pi-sync",
    briefcase: "pi pi-fw pi-briefcase",
    search: "pi pi-fw pi-search",
    "share-alt": "pi pi-fw pi-share-alt",
    globe: "pi pi-fw pi-globe",
    building: "pi pi-fw pi-building",
    desktop: "pi pi-fw pi-desktop",
    mobile: "pi pi-fw pi-mobile",
    "circle-off": "pi pi-fw pi-circle-off",
    "chart-bar": "pi pi-fw pi-chart-bar",
    link: faLink,
};

export { iconObj };
