import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
//import { Editor } from "primereact/editor";
import { MultiSelect } from "primereact/multiselect";
import { addLocale } from "primereact/api";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";

import calConfig from "../../../constants/calendar.config";
import { EditorialService } from "../../../services/publicaciones/EditorialService";
import { CentrosInvService } from "../../../services/publicaciones/CentrosInvService";
import { AutorService } from "../../../services/publicaciones/AutorService";
import { PublicacionService } from "../../../services/publicaciones/PublicacionService";
import { DialogAddEditorial } from "./DialogAddEditorial";
import { DialogAddAutor } from "./DialogAddAutor";
import { AreaEspecialidadService } from "../../../services/publicaciones/AreaEspecialidadService";
import { IndexacionService } from "../../../services/publicaciones/IndexacionService";
import { DialogAddIndexacion } from "./DialogAddIndexador";
import { DialogAddAreaEspecialidad } from "./DialogAddAreaEspecialidad";
import { getFormErrorMessage } from "../../../helpers/form.helper";
import { FileUpload } from "primereact/fileupload";

export const DialogAddEdit = (props) => {
    let today = new Date();
    const [centrosInv, setCentrosInv] = useState([]);
    const [editoriales, setEditoriales] = useState([]);
    const [autores, setAutores] = useState([]);
    const [areasEspecialidad, setAreasEspecialidad] = useState([]);
    const [indexaciones, SetIndexaciones] = useState([]);
    const [centrosInvService] = useState(new CentrosInvService());
    const [editorialService] = useState(new EditorialService());
    const [publicacionService] = useState(new PublicacionService());
    const [autorService] = useState(new AutorService());
    const [areaEspecialidadService] = useState(new AreaEspecialidadService());
    const [indexacionService] = useState(new IndexacionService());
    const [dialogAddEditorState, setDialogAddEditorState] = useState(false);
    const [dialogAddAutorState, setDialogAddAutorState] = useState(false);
    const [dialogAddAreaState, setDialogAddAreaState] = useState(false);
    const [dialogAddIndexadorState, setDialogAddIndexadorState] = useState(false);
    const [labelInputs, setLabelInputs] = useState({
        pub_titulo: "Título Artículo / Capítulo de Libro",
        pub_descripcion: "Revista / Conferencia / Libro",
        areasEspecialidad: "Areas de Especialidad",
        indexaciones: "Bases de Datos",
        id_autor: "Primer Autor y Afiliaciones",
        co_autores: "Co-Autores y Afiliaciones",
        id_editor: "Primer Editor y Afiliaciones",
        co_editores: "Co-Editores y Afiliaciones",
        indicador_sjr: "SCImago Journal Rank (SJR)",
        indicador_jcr: "Journal Citation Reports (JCR)",
        indicador_cs: "CiteScore",
        indicador_q: "Cuartil (Q)",
        indicador_h5: "H5-Index",
        indicador_is: "Impact Score",
        pub_editorial: "Editorial",
        pub_fecha: "Fecha",
        pub_estadoPub: "Estado de Publicación",
        pub_link: "Enlace DOI (Digital Object Identifier)",
        fecha_pres: "Fecha de Presentación",
        lugar_pres: "Lugar de Presentación",
        modalidad: "Modalidad",
        organizador: "Organizador",
        doc_digital: "Documento de Respaldo",
        link_scopus: "Enlace SCOPUS Autor UPB",
        tipo_doc: "",
        doc_completo: "Documento digital completo",
    });

    const estadoPubOptions = [
        { name: "Publicado", value: 1 },
        { name: "Forthcoming", value: 0 },
    ];

    const [showInputs, setShowInputs] = useState({
        pub_descripcion: true,
        editores: true,
        indicadores: true,
        indicadoresCS: true,
        pub_editorial: true,
        art_conferencia: true,
    });

    const formik = useFormik({
        initialValues: {
            id_centro: "",
            tipoPublicacion: "1",
            pub_titulo: "",
            pub_descripcion: "",

            indicador_sjr: 0,
            indicador_jcr: 0,
            indicador_cs: 0,
            indicador_q: 0,
            indicador_h5: 0,
            indicador_is: 0,

            id_autor: "",
            co_autores: [],

            id_editor: "",
            co_editores: [],

            areasEspecialidad: [],
            indexaciones: [],

            pub_editorial: "",
            pub_fecha: today,
            pub_estadoPub: 0,
            pub_link: "",
            fecha_pres: today,
            lugar_pres: "",
            modalidad: "",
            organizador: "",
            doc_digital: "",
            link_scopus: "",
            tipo_doc: "",
        },
        validationSchema: Yup.object({
            id_centro: Yup.string().required("Seleccione un Centro de investigacion!!"),
            tipoPublicacion: Yup.string().required("Seleccione un tipo de investigacion!"),
            pub_titulo: Yup.string().required("Escriba el titulo!!"),
            pub_descripcion: Yup.string().when("tipoPublicacion", {
                is: "4",
                then: Yup.string().required("Campo requerido!!"),
            }),

            indicador_sjr: Yup.number(),
            indicador_jcr: Yup.number(),
            indicador_cs: Yup.number(),
            indicador_q: Yup.number(),
            indicador_h5: Yup.number(),
            indicador_is: Yup.number(),

            id_autor: Yup.string().required("Campo requerido!!"),
            co_autores: Yup.array(),

            id_editor: Yup.string(),
            co_editores: Yup.array(),

            areasEspecialidad: Yup.array().required("Campo requerido!!"),
            indexaciones: Yup.array().required("Campo requerido!!"),

            pub_editorial: Yup.string().when("tipoPublicacion", {
                is: "3" || "4",
                then: Yup.string().required("Campo requerido!!"),
            }),
            pub_fecha: Yup.date().required("Campo requerido!!"),
            pub_estadoPub: Yup.number().required("Campo requerido!!"),
            pub_link: Yup.string().url("ingrese una URL valida!!"),
            doc_digital: Yup.string().required("Campo requerido!!"),
            tipo_doc: Yup.string().required("Campo requerido!!"),
            link_scopus: Yup.string().required("Campo requerido!!").url("ingrese una URL valida!!"),
        }),
        validate: (data) => {
            console.log(data);
        },
        onSubmit: async (data) => {
            if (props.showHide === 1) {
                //Create
                await publicacionService.create(data);
            } else if (props.showHide === 2) {
                //Update
                const id = props.selectedPublicacion.ID;
                await publicacionService.update(id, data);
            }

            props.loadDataTable();
            onHide();
        },
    });

    const changeTipoPublicacion = (event) => {
        formik.handleChange(event);
        redrawInputs(event.value);
    };

    const redrawInputs = (tipo) => {
        switch (tipo) {
            case "1": //Artículo Revista
                showInputsArtRev();
                break;
            case "2": //Artículo Conferencia
                showInputsArtCon();
                break;
            case "3": //Libro
                showInputsLibro();
                break;
            case "4": //Capítulo de Libro
                showInputsCapLib();
                break;
            default:
                break;
        }
    };

    const showInputsArtRev = () => {
        let cpLabelInputs = { ...labelInputs };
        cpLabelInputs.pub_titulo = "Título Revista";
        cpLabelInputs.pub_descripcion = "Título Artículo";
        cpLabelInputs.pub_editorial = "Nombre Journal (Periodico)";
        cpLabelInputs.pub_link = "Enlace DOI (Digital Object Identifier)";
        cpLabelInputs.doc_completo = "Articulo de revista completo";
        setLabelInputs(cpLabelInputs);
        let cpShowInputs = { ...showInputs };
        cpShowInputs.editores = false;
        cpShowInputs.pub_descripcion = true;
        cpShowInputs.indicadores = true;
        cpShowInputs.indicadoresCS = false;
        cpShowInputs.pub_editorial = false;
        cpShowInputs.art_conferencia = false;
        setShowInputs(cpShowInputs);
    };

    const showInputsArtCon = () => {
        let cpLabelInputs = { ...labelInputs };
        cpLabelInputs.pub_titulo = "Título Conferencia";
        cpLabelInputs.pub_descripcion = "Título Presentación";
        cpLabelInputs.pub_editorial = "Nombre Journal (Periodico)";
        cpLabelInputs.pub_link = "Enlace DOI (Digital Object Identifier)";
        cpLabelInputs.doc_completo = "Articulo de conferencia completo";
        setLabelInputs(cpLabelInputs);
        let cpShowInputs = { ...showInputs };
        cpShowInputs.editores = false;
        cpShowInputs.pub_descripcion = true;
        cpShowInputs.indicadores = false;
        cpShowInputs.indicadoresCS = true;
        cpShowInputs.pub_editorial = false;
        cpShowInputs.art_conferencia = true;
        setShowInputs(cpShowInputs);
    };

    const showInputsLibro = () => {
        let cpLabelInputs = { ...labelInputs };
        cpLabelInputs.pub_titulo = "Título Libro";
        cpLabelInputs.pub_descripcion = "";
        cpLabelInputs.pub_editorial = "Editorial";
        cpLabelInputs.pub_link = "Enlace Libro";
        cpLabelInputs.doc_completo = "Libro completo";
        setLabelInputs(cpLabelInputs);
        let cpShowInputs = { ...showInputs };
        cpShowInputs.editores = true;
        cpShowInputs.pub_descripcion = false;
        cpShowInputs.indicadores = false;
        cpShowInputs.indicadoresCS = false;
        cpShowInputs.pub_editorial = true;
        cpShowInputs.art_conferencia = false;
        setShowInputs(cpShowInputs);
    };

    const showInputsCapLib = () => {
        let cpLabelInputs = { ...labelInputs };
        cpLabelInputs.pub_titulo = "Título del Libro";
        cpLabelInputs.pub_descripcion = "Título del capítulo";
        cpLabelInputs.pub_editorial = "Editorial";
        cpLabelInputs.pub_link = "Enlace Libro";
        cpLabelInputs.doc_completo = "Capítulo de libro completo";
        setLabelInputs(cpLabelInputs);
        let cpShowInputs = { ...showInputs };
        cpShowInputs.editores = true;
        cpShowInputs.pub_descripcion = true;
        cpShowInputs.indicadores = false;
        cpShowInputs.indicadoresCS = false;
        cpShowInputs.pub_editorial = true;
        cpShowInputs.art_conferencia = false;
        setShowInputs(cpShowInputs);
    };

    addLocale(calConfig.sp.name, calConfig.sp.config);

    const selectedDropdownTemplate = (option, props, valueKey) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option[valueKey]}</div>
                </div>
            );
        }

        if (props) {
            return <span>{props.placeholder}</span>;
        }

        return "";
    };

    const selectedAutorTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>
                        {option.NOMBRES}&nbsp; ({option.INSTITUCION.RAZON_SOCIAL})
                    </div>
                </div>
            );
        }

        if (props) {
            return <span>{props.placeholder}&nbsp;</span>;
        }

        return "";
    };

    const dropDownOptionTemplate = (option, valueKey) => {
        return (
            <div className="country-item">
                <div>{option[valueKey]}</div>
            </div>
        );
    };

    const optionAutorTemplate = (option) => {
        return (
            <div className="country-item">
                <div>
                    {option.NOMBRES}&nbsp;({option.INSTITUCION.RAZON_SOCIAL})
                </div>
            </div>
        );
    };

    const loadCentrosInv = () => {
        centrosInvService.getAll().then((data) => {
            setCentrosInv(data);
        });
    };

    const loadEditorials = () => {
        editorialService.getAll().then((data) => {
            setEditoriales(data);
        });
    };

    const loadAutores = () => {
        autorService.getAll().then((data) => {
            setAutores(data);
        });
    };

    const loadAreasEspecialidad = () => {
        areaEspecialidadService.getAll().then((data) => {
            setAreasEspecialidad(data);
        });
    };

    const loadIndexaciones = () => {
        indexacionService.getAll().then((data) => {
            SetIndexaciones(data);
        });
    };

    const onHide = () => {
        props.setShowHide(0);
        formik.resetForm();
    };

    const onShow = async () => {
        loadCentrosInv();
        loadEditorials();
        loadAutores();
        loadAreasEspecialidad();
        loadIndexaciones();
        let tipoPublicacion = formik.values.tipoPublicacion;
        if (props.selectedPublicacion && props.showHide === 2) {
            const publicacion = props.selectedPublicacion;
            tipoPublicacion = publicacion.TIPO_PUBLICACION.ID;
            const allAutores = publicacion.AUTORES;
            const autor = allAutores.find((autor) => autor.TIPO === 1);
            const coAutores = await Promise.all(allAutores.filter((autor) => autor.TIPO === 2).map(async (autor) => await autorService.getOne(autor.ID)));
            const editor = allAutores.find((autor) => autor.TIPO === 3);
            const coEditores = await Promise.all(allAutores.filter((autor) => autor.TIPO === 4).map(async (autor) => await autorService.getOne(autor.ID)));
            const allAreasEspecialidad = publicacion.AREAS_ESPECIALIDAD;
            const allIndexaciones = publicacion.INDEXACIONES;

            const allMetricas = publicacion.METRICAS; //Number('123')
            const array_sjr = allMetricas.find((metrica) => metrica.ID === 1);
            const array_jcr = allMetricas.find((metrica) => metrica.ID === 2);
            const array_cs = allMetricas.find((metrica) => metrica.ID === 3);
            const array_q = allMetricas.find((metrica) => metrica.ID === 4);
            const array_h5 = allMetricas.find((metrica) => metrica.ID === 5);
            const array_is = allMetricas.find((metrica) => metrica.ID === 6);

            const description = publicacion.DESCRIPCION ? publicacion.DESCRIPCION : "";
            formik.setValues({
                id_centro: publicacion.CENTRO_INVESTIGACION.ID,
                tipoPublicacion: publicacion.TIPO_PUBLICACION.ID,
                pub_titulo: publicacion.TITULO,
                pub_descripcion: description,
                pub_link: publicacion.ENLACE,
                link_scopus: publicacion.ENLACE_SCOPUS ? publicacion.ENLACE_SCOPUS : "",
                pub_editorial: publicacion.EDITORIAL ? publicacion.EDITORIAL.ID : "",
                id_autor: autor ? autor.ID : null,
                co_autores: coAutores,
                id_editor: editor ? editor.ID : null,
                co_editores: coEditores,
                //pub_resumen: publicacion.RESUMEN,
                indicador_sjr: array_sjr ? Number(array_sjr.CANTIDAD) : 0,
                indicador_jcr: array_jcr ? Number(array_jcr.CANTIDAD) : 0,
                indicador_cs: array_cs ? Number(array_cs.CANTIDAD) : 0,
                indicador_q: array_q ? Number(array_q.CANTIDAD) : 0,
                indicador_h5: array_h5 ? Number(array_h5.CANTIDAD) : 0,
                indicador_is: array_is ? Number(array_is.CANTIDAD) : 0,
                pub_fecha: new Date(parseInt(publicacion.FECHA_PUB)),
                pub_estadoPub: publicacion.ESTADO_PUB ? publicacion.ESTADO_PUB : 0,
                indexaciones: allIndexaciones,
                areasEspecialidad: allAreasEspecialidad,
                fecha_pres: publicacion.FECHA_PUB ? new Date(parseInt(publicacion.FECHA_PUB)) : "",
                lugar_pres: publicacion.LUGAR_PRES ? publicacion.LUGAR_PRES : "",
                modalidad: publicacion.MODALIDAD ? publicacion.MODALIDAD : "",
                organizador: publicacion.ORGANIZADOR ? publicacion.ORGANIZADOR : "",
                doc_digital: publicacion.DOC_DIGITAL ? publicacion.DOC_DIGITAL : "",
                tipo_doc: publicacion.TIPO_DOC ? publicacion.TIPO_DOC : "",
            });
        }
        redrawInputs(tipoPublicacion);
    };

    /*********************************************** */

    /*const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };*/
    //const header = renderHeader();
    const panelFooterTemplate = () => {
        const selectedItems = formik.values.co_autores;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="p-py-2 p-px-3">
                <b>{length}</b> item{length > 1 ? "s" : ""} seleccionados.
            </div>
        );
    };
    /*********************************************** */

    /************************************** */
    const chooseOptions = {
        label: "Seleccionar documento",
        icon: "pi pi-fw pi-upload",
    };

    const onUploadFile = (file) => {
        let response = JSON.parse(file.xhr.response);
        formik.setFieldValue("doc_digital", response.filename);
    };
    /************************************** */

    return (
        <div>
            <DialogAddEditorial setShowHide={setDialogAddEditorState} showHide={dialogAddEditorState} loadEditorials={loadEditorials}></DialogAddEditorial>
            <DialogAddIndexacion setShowHide={setDialogAddIndexadorState} showHide={dialogAddIndexadorState} loadIndexaciones={loadIndexaciones}></DialogAddIndexacion>
            <DialogAddAreaEspecialidad setShowHide={setDialogAddAreaState} showHide={dialogAddAreaState} loadAreasEspecialidad={loadAreasEspecialidad}></DialogAddAreaEspecialidad>
            <DialogAddAutor setShowHide={setDialogAddAutorState} showHide={dialogAddAutorState} loadAutores={loadAutores}></DialogAddAutor>
            <Dialog header="Agregar/Editar Publicaciones" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <h4>FORMULARIO DE SOLICITUD DE INCENTIVOS POR PUBLICACIÓN – RESOLUCIÓN RECTORAL No UPB-RR-022/2015</h4>
                    <div className="card">
                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="id_centro" className="col-12 md:col-2">
                                    Centro de Dependencia Autores UPB:
                                </label>
                                <div className="col-12 md:col-10">
                                    <Dropdown
                                        id="id_centro"
                                        name="id_centro"
                                        value={formik.values.id_centro}
                                        options={centrosInv}
                                        onChange={formik.handleChange}
                                        optionLabel="NOMBRE"
                                        optionValue="ID"
                                        filter
                                        showClear
                                        filterBy="NOMBRE"
                                        placeholder="Seleccionar un centro de investigacion"
                                        valueTemplate={(option, props) => {
                                            return selectedDropdownTemplate(option, props, "NOMBRE");
                                        }}
                                        itemTemplate={(option) => {
                                            return dropDownOptionTemplate(option, "NOMBRE");
                                        }}
                                    />
                                    {getFormErrorMessage("id_centro", formik)}
                                </div>
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="representanteRegistro" className="col-12 md:col-2">
                                Tipo de Publicación:
                            </label>
                            <div className="col-12 md:col-10">
                                <div className="formgroup-inline">
                                    {getFormErrorMessage("nombre_rep", formik)}
                                    <div className="field-checkbox">
                                        <RadioButton inputId="tipoPublicacion01" name="tipoPublicacion" value="1" onChange={changeTipoPublicacion} checked={formik.values.tipoPublicacion === "1"} />
                                        <label htmlFor="tipoPublicacion01">Artículo Revista</label>
                                    </div>
                                    <div className="field-checkbox">
                                        <RadioButton inputId="tipoPublicacion02" name="tipoPublicacion" value="2" onChange={changeTipoPublicacion} checked={formik.values.tipoPublicacion === "2"} />
                                        <label htmlFor="tipoPublicacion02">Artículo Conferencia</label>
                                    </div>
                                    <div className="field-checkbox">
                                        <RadioButton inputId="tipoPublicacion03" name="tipoPublicacion" value="3" onChange={changeTipoPublicacion} checked={formik.values.tipoPublicacion === "3"} />
                                        <label htmlFor="tipoPublicacion03">Libro</label>
                                    </div>
                                    <div className="field-checkbox">
                                        <RadioButton inputId="tipoPublicacion04" name="tipoPublicacion" value="4" onChange={changeTipoPublicacion} checked={formik.values.tipoPublicacion === "4"} />
                                        <label htmlFor="tipoPublicacion04">Capítulo de Libro</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="pub_titulo" className="col-12 md:col-2">
                                    {labelInputs.pub_titulo}
                                </label>
                                <div className="col-12 md:col-10">
                                    <InputText id="pub_titulo" name="pub_titulo" type="text" value={formik.values.pub_titulo} onChange={formik.handleChange} />
                                    {getFormErrorMessage("pub_titulo", formik)}
                                </div>
                            </div>
                        </div>

                        {showInputs.pub_descripcion && (
                            <div className="p-fluid">
                                <div className="field grid">
                                    <label htmlFor="pub_descripcion" className="col-12 md:col-2">
                                        {labelInputs.pub_descripcion}
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText id="pub_descripcion" name="pub_descripcion" type="text" value={formik.values.pub_descripcion} onChange={formik.handleChange} />
                                        {getFormErrorMessage("pub_descripcion", formik)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {showInputs.art_conferencia && (
                            <div className="p-fluid">
                                <div className="field grid">
                                    <label htmlFor="fecha_pres" className="col-12 md:col-2">
                                        {labelInputs.fecha_pres}
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <Calendar id="fecha_pres" name="fecha_pres" value={formik.values.fecha_pres} onChange={formik.handleChange} locale="es" dateFormat="dd/mm/yy" />
                                        {getFormErrorMessage("fecha_pres", formik)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {showInputs.art_conferencia && (
                            <div className="p-fluid">
                                <div className="field grid">
                                    <label htmlFor="lugar_pres" className="col-12 md:col-2">
                                        {labelInputs.lugar_pres}
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText id="lugar_pres" name="lugar_pres" type="text" value={formik.values.lugar_pres} onChange={formik.handleChange} />
                                        {getFormErrorMessage("lugar_pres", formik)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {showInputs.art_conferencia && (
                            <div className="field grid">
                                <label htmlFor="modalidad" className="col-12 md:col-2">
                                    {labelInputs.modalidad}
                                </label>
                                <div className="col-12 md:col-10">
                                    <div className="formgroup-inline">
                                        {getFormErrorMessage("modalidad", formik)}
                                        <div className="field-checkbox">
                                            <RadioButton inputId="modalidad01" name="modalidad" value="PRESENCIAL" onChange={formik.handleChange} checked={formik.values.modalidad === "PRESENCIAL"} />
                                            <label htmlFor="modalidad01">Presencial</label>
                                        </div>
                                        <div className="field-checkbox">
                                            <RadioButton inputId="modalidad02" name="modalidad" value="VIRTUAL" onChange={formik.handleChange} checked={formik.values.modalidad === "VIRTUAL"} />
                                            <label htmlFor="modalidad02">Virtual</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showInputs.art_conferencia && (
                            <div className="p-fluid">
                                <div className="field grid">
                                    <label htmlFor="organizador" className="col-12 md:col-2">
                                        {labelInputs.organizador}
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText id="organizador" name="organizador" type="text" value={formik.values.organizador} onChange={formik.handleChange} />
                                        {getFormErrorMessage("organizador", formik)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {showInputs.pub_editorial && (
                            <div className="p-fluid">
                                <div className="field grid">
                                    <label htmlFor="pub_editorial" className="col-12 md:col-2 pub_editorial">
                                        {labelInputs.pub_editorial}
                                    </label>
                                    <div className="col-12 md:col-9">
                                        <Dropdown
                                            id="pub_editorial"
                                            name="pub_editorial"
                                            value={formik.values.pub_editorial}
                                            options={editoriales}
                                            onChange={formik.handleChange}
                                            optionLabel="NOMBRE"
                                            optionValue="ID"
                                            filter
                                            showClear
                                            filterBy="NOMBRE"
                                            placeholder="Seleccionar una editorial"
                                            multiple
                                            valueTemplate={(option, props) => {
                                                return selectedDropdownTemplate(option, props, "NOMBRE");
                                            }}
                                            itemTemplate={(option) => {
                                                return dropDownOptionTemplate(option, "NOMBRE");
                                            }}
                                        />
                                        {getFormErrorMessage("pub_editorial", formik)}
                                    </div>
                                    <div className="col-12 md:col-1">
                                        <Button type="button" icon="pi pi-plus" className="p-button-rounded" onClick={() => setDialogAddEditorState(true)} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="card">
                        <h3>Información de Publicación</h3>

                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="pub_fecha" className="col-12 md:col-2 pub_fecha">
                                    {labelInputs.pub_fecha}
                                </label>
                                <div className="col-12 md:col-10">
                                    <Calendar id="pub_fecha" name="pub_fecha" value={formik.values.pub_fecha} onChange={formik.handleChange} locale="es" dateFormat="dd/mm/yy" />
                                    {getFormErrorMessage("pub_fecha", formik)}
                                </div>
                            </div>
                        </div>

                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="areasEspecialidad" className="col-12 md:col-2 areasEspecialidad">
                                    {labelInputs.areasEspecialidad}
                                </label>
                                <div className="col-12 md:col-9">
                                    <MultiSelect
                                        id="areasEspecialidad"
                                        name="areasEspecialidad"
                                        display="chip"
                                        value={formik.values.areasEspecialidad}
                                        options={areasEspecialidad}
                                        onChange={formik.handleChange}
                                        showClear
                                        optionLabel="NOMBRE"
                                        placeholder="Seleccione Areas de Especialidad"
                                        filter
                                        maxSelectedLabels={20}
                                        filterBy="NOMBRE"
                                        className="multiselect-custom"
                                        itemTemplate={(option) => {
                                            return dropDownOptionTemplate(option, "NOMBRE");
                                        }}
                                        selectedItemTemplate={(option, props) => {
                                            return selectedDropdownTemplate(option, props, "NOMBRE");
                                        }}
                                        panelFooterTemplate={panelFooterTemplate}
                                    />
                                    {getFormErrorMessage("areasEspecialidad", formik)}
                                </div>
                                <div className="col-12 md:col-1">
                                    <Button type="button" icon="pi pi-plus" className="p-button-rounded" onClick={() => setDialogAddAreaState(true)} />
                                </div>
                            </div>
                        </div>

                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="indexaciones" className="col-12 md:col-2 indexaciones">
                                    {labelInputs.indexaciones}
                                </label>
                                <div className="col-12 md:col-9">
                                    <MultiSelect
                                        id="indexaciones"
                                        name="indexaciones"
                                        display="chip"
                                        value={formik.values.indexaciones}
                                        options={indexaciones}
                                        onChange={formik.handleChange}
                                        showClear
                                        optionLabel="NOMBRE"
                                        placeholder="Seleccione Base de Datos"
                                        filter
                                        maxSelectedLabels={20}
                                        filterBy="NOMBRE"
                                        className="multiselect-custom"
                                        itemTemplate={(option) => {
                                            return dropDownOptionTemplate(option, "NOMBRE");
                                        }}
                                        selectedItemTemplate={(option, props) => {
                                            return selectedDropdownTemplate(option, props, "NOMBRE");
                                        }}
                                        panelFooterTemplate={panelFooterTemplate}
                                    />
                                    {getFormErrorMessage("indexaciones", formik)}
                                </div>
                                <div className="col-12 md:col-1">
                                    <Button type="button" icon="pi pi-plus" className="p-button-rounded" onClick={() => setDialogAddIndexadorState(true)} />
                                </div>
                            </div>
                        </div>

                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="pub_estadoPub" className="col-12 md:col-2 pub_estadoPub">
                                    {labelInputs.pub_estadoPub}
                                </label>
                                <div className="col-12 md:col-10">
                                    <SelectButton id="pub_estadoPub" name="pub_estadoPub" value={formik.values.pub_estadoPub} options={estadoPubOptions} onChange={formik.handleChange} optionLabel="name" />
                                    {getFormErrorMessage("pub_estadoPub", formik)}
                                </div>
                            </div>
                        </div>

                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="pub_link" className="col-12 md:col-2 pub_link">
                                    {labelInputs.pub_link}
                                </label>
                                <div className="col-12 md:col-10">
                                    <InputText id="pub_link" name="pub_link" value={formik.values.pub_link} placeholder="Enlace, donde se puede acceder o se menciona al documento" onChange={formik.handleChange} />
                                    {getFormErrorMessage("pub_link", formik)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <h3>Información de Autores</h3>
                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="id_autor" className="col-2 col-md-2 id_autor">
                                    {labelInputs.id_autor}
                                </label>
                                <div className="col-9 col-md-9">
                                    <Dropdown
                                        id="id_autor"
                                        name="id_autor"
                                        value={formik.values.id_autor}
                                        options={autores}
                                        onChange={formik.handleChange}
                                        optionLabel="NOMBRES"
                                        optionValue="ID"
                                        filter
                                        showClear
                                        filterBy="NOMBRES"
                                        placeholder="Seleccionar un autor"
                                        valueTemplate={(option, props) => {
                                            return selectedAutorTemplate(option, props);
                                        }}
                                        itemTemplate={(option) => {
                                            return optionAutorTemplate(option);
                                        }}
                                    />
                                    {getFormErrorMessage("id_autor", formik)}
                                </div>
                                <div className="col-1 col-md-1">
                                    <Button type="button" icon="pi pi-plus" className="p-button-rounded" onClick={() => setDialogAddAutorState(true)} />
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="co_autores" className="col-12 md:col-2 co_autores">
                                    {labelInputs.co_autores}
                                </label>
                                <div className="col-12 md:col-10">
                                    <MultiSelect
                                        id="co_autores"
                                        name="co_autores"
                                        display="chip"
                                        value={formik.values.co_autores}
                                        options={autores}
                                        onChange={formik.handleChange}
                                        showClear
                                        optionLabel="NOMBRES"
                                        filter
                                        maxSelectedLabels={20}
                                        filterBy="NOMBRES"
                                        panelFooterTemplate={panelFooterTemplate}
                                        itemTemplate={(option) => optionAutorTemplate(option)}
                                        selectedItemTemplate={(option) => {
                                            return selectedAutorTemplate(option, props);
                                        }}
                                        placeholder="Seleccione co-autores"
                                    />
                                    {getFormErrorMessage("co_autores", formik)}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="field grid">
                                <label htmlFor="link_scopus" className="col-12 md:col-2 link_scopus">
                                    {labelInputs.link_scopus}
                                </label>
                                <div className="col-12 md:col-10">
                                    <InputText id="link_scopus" name="link_scopus" value={formik.values.link_scopus} placeholder="" onChange={formik.handleChange} />
                                    {getFormErrorMessage("link_scopus", formik)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {showInputs.editores && (
                        <div className="card">
                            <h3>Información de Editores</h3>
                            <div className="p-fluid">
                                <div className="field grid">
                                    <label htmlFor="id_editor" className="col-2 col-md-2 id_editor">
                                        {labelInputs.id_editor}
                                    </label>
                                    <div className="col-9 col-md-9">
                                        <Dropdown
                                            id="id_editor"
                                            name="id_editor"
                                            value={formik.values.id_editor}
                                            options={autores}
                                            onChange={formik.handleChange}
                                            optionLabel="NOMBRES"
                                            optionValue="ID"
                                            filter
                                            showClear
                                            filterBy="NOMBRES"
                                            placeholder="Seleccionar un editor"
                                            valueTemplate={(option, props) => {
                                                return selectedAutorTemplate(option, props);
                                            }}
                                            itemTemplate={(option) => {
                                                return optionAutorTemplate(option);
                                            }}
                                        />
                                        {getFormErrorMessage("id_editor", formik)}
                                    </div>
                                    <div className="col-1 col-md-1">
                                        <Button type="button" icon="pi pi-plus" className="p-button-rounded" onClick={() => setDialogAddAutorState(true)} />
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="field grid">
                                    <label htmlFor="co_editores" className="col-12 md:col-2 co_editores">
                                        {labelInputs.co_editores}
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <MultiSelect
                                            id="co_editores"
                                            name="co_editores"
                                            display="chip"
                                            value={formik.values.co_editores}
                                            options={autores}
                                            onChange={formik.handleChange}
                                            showClear
                                            optionLabel="NOMBRES"
                                            filter
                                            maxSelectedLabels={20}
                                            filterBy="NOMBRES"
                                            panelFooterTemplate={panelFooterTemplate}
                                            itemTemplate={(option) => optionAutorTemplate(option)}
                                            selectedItemTemplate={(option) => {
                                                return selectedAutorTemplate(option, props);
                                            }}
                                            placeholder="Seleccione co-editores"
                                        />
                                        {getFormErrorMessage("co_editores", formik)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {showInputs.indicadores && (
                        <div>
                            <div className="card">
                                <h3>Factor de impacto</h3>
                                <div className="p-fluid">
                                    <div className="field grid">
                                        <label htmlFor="indicador_sjr" className="col-12 md:col-2 indicador_sjr">
                                            {labelInputs.indicador_sjr}
                                        </label>
                                        <div className="col-12 md:col-10">
                                            <InputNumber inputId="indicador_sjr" name="indicador_sjr" value={formik.values.indicador_sjr} onValueChange={formik.handleChange} mode="decimal" minFractionDigits={2} />
                                            {getFormErrorMessage("indicador_sjr", formik)}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid">
                                    <div className="field grid">
                                        <label htmlFor="indicador_jcr" className="col-12 md:col-2 indicador_jcr">
                                            {labelInputs.indicador_jcr}
                                        </label>
                                        <div className="col-12 md:col-10">
                                            <InputNumber inputId="indicador_jcr" name="indicador_jcr" value={formik.values.indicador_jcr} onValueChange={formik.handleChange} mode="decimal" minFractionDigits={2} />
                                            {getFormErrorMessage("indicador_jcr", formik)}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid">
                                    <div className="field grid">
                                        <label htmlFor="indicador_cs" className="col-12 md:col-2 indicador_cs">
                                            {labelInputs.indicador_cs}
                                        </label>
                                        <div className="col-12 md:col-10">
                                            <InputNumber inputId="indicador_cs" name="indicador_cs" value={formik.values.indicador_cs} onValueChange={formik.handleChange} mode="decimal" minFractionDigits={2} />
                                            {getFormErrorMessage("indicador_cs", formik)}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid">
                                    <div className="field grid">
                                        <label htmlFor="indicador_q" className="col-12 md:col-2 indicador_q">
                                            {labelInputs.indicador_q}
                                        </label>
                                        <div className="col-12 md:col-10">
                                            <InputNumber inputId="indicador_q" name="indicador_q" value={formik.values.indicador_q} onValueChange={formik.handleChange} mode="decimal" minFractionDigits={0} showButtons min={0} max={5} suffix=" Q" />
                                            {getFormErrorMessage("indicador_q", formik)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showInputs.indicadoresCS && (
                        <div>
                            <div className="card">
                                <h3>Para Artículos publicados en Conferencias en Computer Science</h3>
                                <div className="p-fluid">
                                    <div className="field grid">
                                        <label htmlFor="indicador_h5" className="col-12 md:col-2 indicador_h5">
                                            {labelInputs.indicador_h5}
                                        </label>
                                        <div className="col-12 md:col-10">
                                            <InputNumber inputId="indicador_h5" name="indicador_h5" value={formik.values.indicador_h5} onValueChange={formik.handleChange} mode="decimal" minFractionDigits={2} />
                                            {getFormErrorMessage("indicador_h5", formik)}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid">
                                    <div className="field grid">
                                        <label htmlFor="indicador_is" className="col-12 md:col-2 indicador_is">
                                            ${labelInputs.indicador_is}
                                        </label>
                                        <div className="col-12 md:col-10">
                                            <InputNumber inputId="indicador_is" name="indicador_is" value={formik.values.indicador_is} onValueChange={formik.handleChange} mode="decimal" minFractionDigits={2} />
                                            {getFormErrorMessage("indicador_is", formik)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="card">
                        <h3>Domumentos Anexos</h3>
                        <div className="field grid">
                            <label htmlFor="doc_digital" className="col-12 md:col-2">
                                {labelInputs.doc_digital}
                            </label>
                            <div className="col-12 md:col-10">
                                <sub>Maximo 10MB</sub>
                                <FileUpload
                                    id="doc_digital"
                                    mode="basic"
                                    name="doc_digital"
                                    url={process.env.REACT_APP_UPLOAD_URL}
                                    chooseOptions={chooseOptions}
                                    multiple={false}
                                    onUpload={onUploadFile}
                                    auto
                                    maxFileSize="10485760"
                                    invalidFileSizeMessageSummary="{0} Tamaño de archivo no válido"
                                    invalidFileSizeMessageDetail="El tamaño máximo de carga es {0}"
                                    accept=".pdf"
                                    className="inputfield w-full"
                                ></FileUpload>
                                <b>{formik.values.doc_digital}</b>
                                {getFormErrorMessage("doc_digital", formik)}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="tipo_doc" className="col-12 md:col-2">
                                {labelInputs.tipo_doc}
                            </label>
                            <div className="col-12 md:col-10">
                                <div className="formgroup-inline">
                                    <div className="field-checkbox">
                                        <RadioButton inputId="tipo_doc01" name="tipo_doc" value="COMPLETO" onChange={formik.handleChange} checked={formik.values.tipo_doc === "COMPLETO"} />
                                        <label htmlFor="tipo_doc01">{labelInputs.doc_completo}</label>
                                    </div>
                                    <div className="field-checkbox">
                                        <RadioButton inputId="tipo_doc02" name="tipo_doc" value="CARATULA" onChange={formik.handleChange} checked={formik.values.tipo_doc === "CARATULA"} />
                                        <label htmlFor="tipo_doc02">Pagina, autores con Afiliaciones</label>
                                    </div>
                                </div>
                                {getFormErrorMessage("tipo_doc", formik)}
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button type="button" label="Cancelar" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                        <Button type="submit" label="Guardar" icon="pi pi-save" />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
