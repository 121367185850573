import React, { useState, useEffect, useRef } from "react";
import { Tree } from "primereact/tree";
import { Toast } from "primereact/toast";
import { DocumentoService } from "../../../services/gestionCalidad/DocumentoService";
import "primeflex/primeflex.css";

export const TreeDirectory = (props) => {
    const [loading, setLoading] = useState(true);
    const [selectedNodeKey] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState("");
    const [nodes, setNodes] = useState(null);
    const [documentoServices] = useState(new DocumentoService());
    const toast = useRef(null);
    const [ items, setItems ] = useState('');
    
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const initialData = await documentoServices.getInitialTreeDocuments(true);
                //Funcion recursiva para agregar hijos a los hijos
                const addDynamicChildren = async (nodes, parentKey)=>{
                    const updateNodes = [];

                    for (let index = 0; index < nodes.length; index++) {
                        const node = nodes[index];
                        const nodeKey = parentKey ? `${parentKey}-${index}`:`${index}`;

                        if(node.children && node.children.length>0){
                            const children = await documentoServices.getTreeDocumentsByID(node.key, true);
                            node.children = await addDynamicChildren(children, nodeKey);
                        }
                        updateNodes.push({
                            ...node,
                            key: node.key,//nodeKey,
                            parent: parentKey ? parentKey : null,
                            checked: false
                        });
                    }
                    return updateNodes;
                };
                const updateData = await addDynamicChildren(initialData, null);
                const newNode =[
                    {
                        data: null,
                        icon: "pi pi-fw pi-folder-open",
                        key:"0",
                        label:"/",
                        parent:null,
                        position:0,
                        type:1,
                        children:updateData
                    }
                ];
                setNodes(newNode);
                setLoading(false);
            } catch (error) {
                console.log('Error al obtener los documentos:', error);
            }
        }
        fetchInitialData();
    }, [documentoServices]);
    
    const onExpand = async (event) => {
        const newString = event.node.label+'/';
        const updateNavigate = items+newString;
        setItems(updateNavigate);
    };

    const onSelect = (event) => {
        if (props.selectedDoc.id === event.node.key) {
            props.setDocument(-1);
            setSelectedLabel("");
            return;
        }
        if (event.node.type === 1) {
            setSelectedLabel(event.node.label);
            props.setDocument(event.node.key);
        }
    };

    const onUnselect = (event) => {
        props.setDocument(-1);
        toast.current.show({ severity: "info", summary: "Node Unselected", detail: event.node.label });
    };

    const nodeTemplate = (node, options) => {
        let label = <b>{node.label}</b>;
        if (node.type === 1) {
            label = <b>{node.label}</b>;
        }
        return <span className={options.className}>{label}</span>;
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="fluid">
                <b className="text-base" >Archivo/Directorio:</b>&nbsp;<span>{props.selectedDoc.name}</span>
                <br />
                <b className="text-base">Mover al directorio:</b>&nbsp;<span className="text-primary font-medium">{/*{items}*/}{selectedLabel && selectedLabel}</span>
            </div>
            <Tree value={nodes} dragdropScope="dragdropTree" selectionMode="single"
                nodeTemplate={nodeTemplate}
                selectionKeys={selectedNodeKey}
                onExpand={onExpand}
                onSelect={onSelect}
                onUnselect={onUnselect}
                loading={loading}
                />
        </>
    );
};
