import React from "react";
import { Tooltip } from "primereact/tooltip";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./MenubarTable.css";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";

export const MenubarSemester = (props) => {
    const selectedGestionTemplate = (option, props) => {
        if (option) {
            return (
                <div className="">
                    <div>{option.NOMBRE}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const gestionOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.NOMBRE}</div>
            </div>
        );
    };

    return (
        <>
            {props.certSemestral ? (
                <Button label="Certificado Semestral" icon="pi pi-download" className="p-button-primary mx-2" onClick={(e) => props.loadCertificate()} />
            ) : (
                <>
                    <Tooltip target=".custom-target-icon" />
                    <b className="custom-target-icon p-overlay-badge mx-2" data-pr-tooltip="Horas acumuladas en el semestre" data-pr-position="top" style={{ fontSize: "2rem", cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faLightbulb} style={{ color: "var(--yellow-600)" }} />
                        <Badge value={props.horasSemestre + "Hrs."} severity="info"></Badge>
                    </b>
                </>
            )}
            <Dropdown value={props.selectedGestion} options={props.gestiones} onChange={(e) => props.onGestionChange(e.value)} optionLabel="NOMBRE" filter filterBy="NOMBRE" placeholder="Seleccione Gestión" valueTemplate={selectedGestionTemplate} itemTemplate={gestionOptionTemplate} />
        </>
    );
};
