import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";
import { DocumentoService } from "../../../services/documentosBI/DocumentoService";
import { Toast } from "primereact/toast";
import { TreeDirectory } from "../tree/TreeDirectory";

export const DialogMove = (props) => {
    const toast = useRef(null);
    const [documentoService] = useState(new DocumentoService());

    const formik = useFormik({
        initialValues: {
            id_cod: -1,
        },
        validationSchema: Yup.object({
            id_cod: Yup.number().nullable(),
        }),
        onSubmit: async (data) => {
            if (props.showHide === 1 && data.id_cod !== -1) {
                await updateData(data);
            }

            onHide();
            props.loadDataView();
        },
    });

    const updateData = async (data) => {
        const response = await documentoService.changeParent(props.selectedDocumento.id, data.id_cod);
        if (response.ID) {
            toast.current.show({ severity: "success", summary: "Actualizado", detail: "Documento actualizado correctamente!!", life: 3000 });
        }
    };

    const onHide = () => {
        formik.resetForm();
        props.setShowHide(0);
    };

    const onShow = () => {
        //console.log(props.selectedDocumento);
        formik.resetForm();
    };

    const setDocument = (id_cod) => {
        formik.setFieldValue("id_cod", id_cod);
    };

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header="Mover Documento" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                <form onSubmit={formik.handleSubmit} className="fluid">
                    <div className="card">
                        <div className="fluid">
                            <TreeDirectory selectedDoc={props.selectedDocumento} setDocument={setDocument}></TreeDirectory>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button label="Cancelar" type="reset" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                        <Button type="submit" label="Guardar" icon="pi pi-save" />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
