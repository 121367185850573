import axios from "axios";
import publicacion from "../../constants/publicaciones/conv.publicacion";
import { getToken } from "../../helpers/token.helper";

export class PublicacionService {
    async getAll() {
        const res = await axios.post(
            publicacion.apiUrl,
            {
                query: `query{${publicacion.queries.getAll()}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                    
                },
            }
        );
        return res.data.data.publicaciones;
    }

    async getAllByFilter(data) {
        const res = await axios.post(
            publicacion.apiUrl,
            {
                query: `query{${publicacion.queries.getAllByFilter(data)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                    
                },
            }
        );
        return res.data.data.publicacionesByFilter;
    }

    async create(data) {
        let mutation = `mutation{${publicacion.queries.create(data)}}`;
        console.log(mutation);
        const res = await axios
            .post(
                publicacion.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            );
        return res.data.data.publicacion;
    }

    async update(id, data) {
        let mutation = `mutation{${publicacion.queries.update(id, data)}}`;
        const res = await axios
            .post(
                publicacion.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            );
        return res.data.data.publicacion;
    }

    async delete(id) {
        let mutation = `mutation{${publicacion.queries.delete(id)}}`;
        const res = await axios
            .post(
                publicacion.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            );
        return res.data.data.deletePublicacion;
    }
}
