import React from "react";
import { Navigate } from "react-router-dom";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "../../assets/layout/flags/flags.css";
import "../../assets/layout/layout.scss";
import "../../App.scss";
import WithLayout from "./WithLayout";
import WithoutLayout from "./WithoutLayout";
import { getLayout } from "../../helpers/session.helper";
import LayoutLoading from "../other/LayoutLoading";
import ErrorLoading from "../other/ErrorLoading";

const LayoutMain = (props) => {
    const getValidatedComponent = () => {
        if (props.haveAccess === null) {
            return <LayoutLoading />;
        }

        if (props.haveAccess === false) {
            return getLayout() !== "none" ? <Navigate to="/login" /> : <ErrorLoading />;
        }

        if (props.haveAccess === true) {
            return getLayout() !== "none" ? <WithLayout /> : <WithoutLayout />;
        }
    };
    return <>{getValidatedComponent()}</>;
};

export default LayoutMain;
