import React from "react";
import { Menubar } from 'primereact/menubar';

export const MenubarTable = (props) =>{

    //Creamos las opciones del menu
    const items = [
        {
            label: "Nuevo",
            icon: "pi pi-fw pi-plus",
            command: () => {
                props.dialogAddEdit(1);
            },
        }
    ];
   
    //Retornamos el menu
    return (
        <Menubar model={items} start={null} end={null}/>
    );
}
