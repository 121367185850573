import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { MenubarInvTable } from "../components/convenios/menubarTable/MenubarInvTable";
import { InvestigadorService } from "../services/convenios/InvestigadorService";
import { DialogAddEditInv } from "../components/convenios/dialogs/DialogAddEditInv";

export const Investigadores = (props) => {
    const [investigadorService] = useState(new InvestigadorService());
    const [investigadores, setInvestigadores] = useState([]);
    const [dialogAddEditState, setDialogAddEditState] = useState(0);
    const [selectedInvestigador, setSelectedInvestigador] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    //const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");

    useEffect(() => {
        investigadorService.getAll().then((data) => setInvestigadores(data));
    }, [investigadorService]);

    /************************************************ */
    const cols = [
        { field: "INSTITUCION.RAZON_SOCIAL", header: "Empresa" },
        { field: "FECHA_FIRMA", header: "Fecha firma" },
        { field: "GRADO_ACADEMICO", header: "Grado academico" },
        { field: "REPRESENTANTE.NOMBRE", header: "Representante" },
        { field: "quantity", header: "Cant. asignados/Plazas" },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));
    /************************************************ */

    const accept = () => {
        investigadorService.delete(selectedInvestigador.ID).then((data) => {
            toast.current.show({
                severity: "info",
                summary: "Confirmado",
                detail: "Has aceptado!!",
                life: 3000,
            });
            investigadorService.getAll().then((data) => setInvestigadores(data));
        });
    };

    const deleteConvenio = () => {
        confirmDialog({
            message: "¿Quieres borrar este registro?",
            header: "Eliminar confirmación",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
        });
    };

    const loadDataTable = () => {
        investigadorService.getAll().then((data) => {
            console.log(data);
            setInvestigadores(data);
        });
        setSelectedInvestigador(null);
    };

    //----------------- EXPORT FILES -----------------
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(investigadores);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "convenios");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        });
    };

    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, investigadores);
                doc.save("convenios.pdf");
            });
        });
    };

    const exportDataTable = (type = 1) => {
        switch (type) {
            case 1:
                exportPdf();
                break;
            case 2:
                exportExcel();
                break;
            case 3:
                exportCSV();
                break;
            default:
                break;
        }
    };
    //------------------------------------------------

    const onGlobalFilterChange1 = (e) => {
        console.log(e.target);
        const value = e.target.value;
        setGlobalFilterValue1(value);
    };

    const inputSearch = (
        <div>
            <span className="p-input-icon-left p-d-sm-none p-d-md-none p-d-lg-inline">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Buscar" />
            </span>
        </div>
    );

    const header = (
        <div className="table-header">
            <MenubarInvTable dialogAddEdit={setDialogAddEditState} dialogDelete={deleteConvenio} inputSearch={inputSearch} selectedConvenio={selectedInvestigador} setSelectedConvenio={setSelectedInvestigador} exportDataTable={exportDataTable}></MenubarInvTable>
        </div>
    );

    return (
        <div>
            <div className="card">
                <Toast ref={toast} />
                <DialogAddEditInv setShowHide={setDialogAddEditState} showHide={dialogAddEditState} loadDataTable={loadDataTable} selectedConvenio={selectedInvestigador}></DialogAddEditInv>
                <DataTable
                    filterLocale="es"
                    className="p-datatable-customers"
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    emptyMessage="No existe datos."
                    ref={dt}
                    header={header}
                    value={investigadores}
                    sortMode="multiple"
                    paginator
                    showGridlines
                    rows={10}
                    globalFilter={globalFilterValue1}
                    selectionMode="single"
                    metaKeySelection={false}
                    dataKey="ID"
                    selection={selectedInvestigador}
                    onSelectionChange={(e) => setSelectedInvestigador(e.value)}
                >
                    <Column field="NOMBRE" header="Nombre(s)" sortable></Column>
                    <Column field="APELLIDOS" header="Apellido(s)" sortable></Column>
                    <Column field="CARNET" header="Nro. Identificación" sortable></Column>
                    <Column field="INSTITUCION.RAZON_SOCIAL" header="Empresa" sortable></Column>
                    <Column field="CARGO" header="Cargo" sortable></Column>
                </DataTable>
            </div>
        </div>
    );
};
