import axios from "axios";
import representante from "../../constants/convenios/conv.representante";
import { getToken } from "../../helpers/token.helper";

export class RepresentanteService {
    getAll() {
        return axios
            .post(
                representante.apiUrl,
                {
                    query: `query{${representante.queries.getAll()}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.representantes;
            });
    }

    async create(data) {
        //console.log("RepresentanteService", data);
        let mutation = `mutation{${representante.queries.create(data)}}`;
        //console.log("RepresentanteService", mutation);
        let res = await axios.post(
            representante.apiUrl,
            {
                query: mutation,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.createRepresentante;
    }

    update(data) {
        console.log(data);
    }
}
