module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            convenios{
                ID
                CARNET
                FECHA_FIRMA
                FECHAINI
                FECHAFIN
                GRADO_ACADEMICO
                PLAZAS
                REPRESENTANTE {
                    ID
                    COD_INSTITUCION
                    NOMBRE
                    CARNET
                    CARGO
                }
                INSTITUCION {
                    COD_INSTITUCION
                    RAZON_SOCIAL
                }
                ESTUDIANTES{
                    COD_PERSONA
                }
            }`;
        },
        getAllByFilter: (data) => {
            console.log(data);
            let fecha_fir = "";
            if (data.fecha_fir) {
                fecha_fir = data.fecha_fir
                    .filter((element) => element !== null)
                    .map((element) => `"${element}"`)
                    .join(",");
                fecha_fir = `FECHA_FIRMA:[${fecha_fir}]`;
            }
            let fecha_ini = "";
            if (data.fecha_ini) {
                fecha_ini = data.fecha_ini
                    .filter((element) => element !== null)
                    .map((element) => `"${element}"`)
                    .join(",");
                fecha_ini = `FECHA_INI:[${fecha_ini}]`;
            }
            return `
            conveniosByFilter(filtro: {
                ${data.id_representante ? `ID_REP: ${data.id_representante}` : ``}
                ${data.cod_empresa ? `COD_INSTITUCION: ${data.cod_empresa}` : ``}
                ${data.grado ? `GRADO_ACADEMICO: ${data.grado}` : ``}
                ${fecha_fir}
                ${fecha_ini}
            }){
                ID
                CARNET
                FECHA_FIRMA
                FECHAINI
                FECHAFIN
                GRADO_ACADEMICO
                PLAZAS
                REPRESENTANTE {
                    ID
                    COD_INSTITUCION
                    NOMBRE
                    CARNET
                    CARGO
                }
                INSTITUCION {
                    COD_INSTITUCION
                    RAZON_SOCIAL
                }
                ESTUDIANTES{
                    COD_PERSONA
                }
            }`;
        },
        getOne: (id) => {
            return `
            convenio(id:${id}){
                RAZON_SOCIAL
                TELEFONO_INS
                DOMICILIO_INST
                REPRESENTANTES{
                    NOMBRE
                }
            }`;
        },

        create: (data) => {
            return `
            createConvenio(convenio:{
                ID_REP: ${data.id_representante}
                CARNET: "${data.doc_digital}"
                FECHA_FIRMA: "${data.fechaFirma.toJSON()}"
                FECHAINI: "${data.fechaIni.toJSON()}"
                FECHAFIN: "${data.fechaFin.toJSON()}"
                PLAZAS: ${data.cantPlazas}
                GRADO_ACADEMICO: ${data.grado}
            }){
                ID
            }`;
        },

        update: (id, data) => {
            return `
            updateConvenio(id:${id} convenio:{
                ID_REP: ${data.id_representante}
                CARNET: "${data.doc_digital}"
                FECHA_FIRMA: "${data.fechaFirma.toJSON()}"
                FECHAINI: "${data.fechaIni.toJSON()}"
                FECHAFIN: "${data.fechaFin.toJSON()}"
                PLAZAS: ${data.cantPlazas}
                GRADO_ACADEMICO: ${data.grado}
            }){
                ID
            }`;
        },

        delete: (id) => {
            return `
            deleteConvenio(id:${id}){
                ID
            }`;
        },
    },
};
