import React, { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { DialogToSubscribe } from "./dialogs/DialogToSubscribe";
import { CursoService } from "../../services/teachertraining/CursoService";
import { cantHorasBodyTemplate, fechaBodyTemplate, horaBodyTemplate, isFullCourse, isRegistrable, tipoBodyTemplate, tipoCurso } from "../../helpers/formacionDoc.helper.js";

export const Ofertas = (props) => {
    const [cursoService] = useState(new CursoService());
    const [cursos, setCursos] = useState([]);
    const [dialogToSubscribeState, setDialogToSubscribeState] = useState(0);
    const [selectedIdCurso, setSelectedIdCurso] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        cursoService.getCursos().then((data) => setCursos(data));
    }, [cursoService]);

    const loadAllData = () => {
        cursoService.getCursos().then((data) => setCursos(data));
        setSelectedIdCurso(null);
    };

    const loadToSubscribe = (curso_id) => {
        setDialogToSubscribeState(1);
        setSelectedIdCurso(curso_id);
    };

    const opcionesBodyTemplate = (rowData) => {
        const isReg = isRegistrable(rowData);
        const isFull = isFullCourse(rowData);
        let dtButton = <Button label="Información" icon="pi pi-search" className="p-button-secondary ml-2" onClick={() => loadToSubscribe(rowData.ID)} />;
        if (!isFull && isReg) {
            dtButton = <Button label="Suscribirse" icon="pi pi-search" className="p-button-success ml-2" onClick={() => loadToSubscribe(rowData.ID)} />;
        } else if (isFull) {
            dtButton = <Button label="Sin cupos" icon="pi pi-search" className="p-button-danger ml-2" onClick={() => loadToSubscribe(rowData.ID)} />;
        }
        return dtButton;
    };

    const headerTable = (
        <div className="flex flex-row-reverse">
            <Button icon="pi pi-refresh" tooltip="Actualizar tabla" onClick={() => loadAllData()} />
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <DialogToSubscribe setShowHide={setDialogToSubscribeState} loadAllData={loadAllData} tipoCurso={tipoCurso} showHide={dialogToSubscribeState} selectedIdData={selectedIdCurso}></DialogToSubscribe>
            <Fieldset legend="Cursos, talleres y conferencias">
                <DataTable value={cursos} responsiveLayout="scroll" header={headerTable}>
                    <Column header="Tipo" body={tipoBodyTemplate}></Column>
                    <Column field="NOMBRE" header="Titulo"></Column>
                    <Column header="Cant. Horas" body={cantHorasBodyTemplate}></Column>
                    <Column header="Fechas" body={fechaBodyTemplate}></Column>
                    <Column header="Horario" body={horaBodyTemplate}></Column>
                    <Column header="Opciones" body={opcionesBodyTemplate}></Column>
                </DataTable>
            </Fieldset>
        </div>
    );
};
