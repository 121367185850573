module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            editoriales{
                ID
                NOMBRE
            }`;
        },
        getOne: (id) => {
            return `
            editorial(id:${id}){
                ID
                NOMBRE
            }`;
        },

        create: (data) => {
            return `
            createEditorial(editorial:{
                NOMBRE: "${data.ed_nombre}"
            }){
                ID
            }`;
        },

        /*update: (id, data) => {
            return `
            updateConvenio(id:${id} convenio:{
                ID_REP: ${data.id_representante}
                CARNET: "${data.doc_digital}"
                FECHA_FIRMA: "${data.fechaFirma.toJSON()}"
                FECHAINI: "${data.fechaIni.toJSON()}"
                FECHAFIN: "${data.fechaFin.toJSON()}"
                PLAZAS: ${data.cantPlazas}
                GRADO_ACADEMICO:"${data.grado}"
            }){
                ID
            }`;
        },

        delete: (id) => {
            return `
            deleteConvenio(id:${id}){
                ID
            }`;
        },*/
    },
};
