const nameVarToken = "token";
const setToken = (newToken) => {
    return sessionStorage.setItem(nameVarToken, newToken);
};

const getToken = () => {
    return sessionStorage.getItem(nameVarToken);
};

const delToken = () => {
    return sessionStorage.removeItem(nameVarToken);
};
export { getToken, setToken, delToken };
