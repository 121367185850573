module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        lostObjectByID: (id) => {
            return `
            objPerdido(id:"${id}"){
                ID
                LUGAR
                PER_COD_PERSONA
                REPORTANTE
                LUGAR_PERDIDA
                LUGAR_ACTUAL
                DETALLE
                RUTA
                TIPO
                ESTADO
                FECHAREG
                FECHA                
            }`;
        },
        getAll: () => {
            return `
            allLostObjects{
                ID
                LUGAR
                PER_COD_PERSONA
                REPORTANTE
                LUGAR_PERDIDA
                LUGAR_ACTUAL
                DETALLE
                RUTA
                TIPO
                FECHA
                USUARIO
                FECHAREG
                ESTADO               
            }`;
        },
        getAllByID: () => {
            return `
            allLostObjectsByID{
                ID
                LUGAR
                PER_COD_PERSONA
                REPORTANTE
                LUGAR_PERDIDA
                LUGAR_ACTUAL
                DETALLE
                RUTA
                TIPO
                FECHA
                USUARIO
                FECHAREG
                ESTADO               
            }`;
        },
    },
    mutations: {
        create: (data) => {
            const value = 
            `createLostObject(
                data:{
                    LUGAR:"${data.LUGAR}",
                    TIPO:${data.TIPO},
                    REPORTANTE:"${data.REPORTANTE}",
                    LUGAR_PERDIDA: "${data.LUGAR_PERDIDA}",
                    LUGAR_ACTUAL: "${data.LUGAR_ACTUAL?data.LUGAR_ACTUAL:''}",
                    DETALLE: "${data.DETALLE}",
                    FECHA:"${data.FECHA}",
                    RUTA: "${data.RUTA}",
                })
                {
                    LUGAR
                    PER_COD_PERSONA
                    LUGAR_PERDIDA
                    LUGAR_ACTUAL
                    DETALLE
                    TIPO
                    FECHA
                    RUTA
                    FECHAREG
                    ESTADO
                }
            `;
            return value;
        },
        update: (id, data) => {
            return `
            updateLostObjects(id: "${id}" data:{
                ID:${data.ID},
                LUGAR:"${data.LUGAR}",
                TIPO:${data.TIPO},
                REPORTANTE:"${data.REPORTANTE}",
                LUGAR_PERDIDA: "${data.LUGAR_PERDIDA}",
                LUGAR_ACTUAL: "${data.LUGAR_ACTUAL?data.LUGAR_ACTUAL:''}",
                DETALLE: "${data.DETALLE}",
                FECHA:"${data.FECHA}",
                RUTA: "${data.RUTA}",
            }){
                ID
                LUGAR
                PER_COD_PERSONA
                LUGAR_PERDIDA
                LUGAR_ACTUAL
                DETALLE
                TIPO
                FECHA
                RUTA
                FECHAREG
                ESTADO
            }
            `;
        },
        delete: (id) => {
            return `
            deleteLostObject(id: "${id}"){
                LUGAR
                    PER_COD_PERSONA
                    LUGAR_PERDIDA
                    LUGAR_ACTUAL
                    DETALLE
                    TIPO
                    FECHA
                    RUTA
                    USUARIO
                    FECHAREG
                    ESTADO
            }
            `;
        },
    },
};
