import React, { useState, useEffect, useRef } from "react";
import { Tree } from "primereact/tree";
import { Toast } from "primereact/toast";
import { DocumentoService } from "../../../services/documentosBI/DocumentoService";
import "primeflex/primeflex.css";
import { documentDefaulValue } from "../../../helpers/gestionCalidad/documents.helper";

export const TreeDirectory = (props) => {
    const [loadingTree, setLoadingTree] = useState(false);
    const [selectedNodeKey] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState("");
    const [nodes, setNodes] = useState(null);
    const [documentoServices] = useState(new DocumentoService());
    const toast = useRef(null);

    useEffect(() => {
        documentoServices.getInitialTreeDocuments(true).then((data) => {
            let newData = [{ data: null, icon: "pi pi-fw pi-folder", key: "0", label: "/", parent: null, position: 0, type: 1, children: data }];
            setNodes(newData);
        });
    }, [documentoServices]);

    const onExpand = async (event) => {
        if (event.node.type === 1) {
            setLoadingTree(true);
            const documentos = await documentoServices.getTreeDocumentsByID(event.node.key, true);
            event.node.children = documentos.length > 0 ? documentos : [documentDefaulValue];
            setLoadingTree(false);
        }
    };

    const onCollapse = (event) => {
        event.node.children = [documentDefaulValue];
    };

    const onSelect = (event) => {
        if (props.selectedDoc.id === event.node.key) {
            props.setDocument(-1);
            setSelectedLabel("");
            return;
        }
        if (event.node.type === 1) {
            setSelectedLabel(event.node.label);
            props.setDocument(event.node.key);
        }
    };

    const onUnselect = (event) => {
        props.setDocument(-1);
        toast.current.show({ severity: "info", summary: "Node Unselected", detail: event.node.label });
    };

    const nodeTemplate = (node, options) => {
        let label = <b>{node.label}</b>;

        if (node.type === 1) {
            label = <b>{node.label}</b>;
        }

        return <span className={options.className}>{label}</span>;
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="fluid">
                <b>Archivo/Directorio:</b>&nbsp;{props.selectedDoc.name}
                <br />
                <b>Mover al directorio:</b>&nbsp;{selectedLabel && selectedLabel}
            </div>
            <Tree value={nodes} dragdropScope="dragdropTree" selectionMode="single" nodeTemplate={nodeTemplate} selectionKeys={selectedNodeKey} onExpand={onExpand} onCollapse={onCollapse} onSelect={onSelect} onUnselect={onUnselect} loading={loadingTree} />
        </>
    );
};
