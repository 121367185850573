import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { useFormik } from "formik";
import { Column } from "primereact/column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFileWord, faFilePowerpoint, faFileExcel, faFileCsv, faFilePdf, faFileImage, faFileVideo, faFileAudio, faFileArchive, faFile, faLink } from "@fortawesome/free-solid-svg-icons";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";
import { DocumentoService } from "../../../services/gestionCalidad/DocumentoService";
import { Toast } from "primereact/toast";

export const DialogFilter = (props) => {
    const toast = useRef(null);
    const [documentoService] = useState(new DocumentoService());
    const [documentos, setDocumentos] = useState([]);
    const [document, setDocument] = useState([]);

    const formik = useFormik({
        initialValues: { texto: ""},
        
        onSubmit: async (values) => {
            documentoService.getSearchDocumentsByText(values.texto).then((data) => {
                setDocumentos(data);
            });            
        },
    });

    const onHide = () => {
        setDocumentos([]);
        formik.values.texto = '';
        props.setShowHide(0);
    };

    const onShow = () => {
        documentoService.filter().then((data) => {
            setDocument(data.data);
        });
    };

    const mostrar = (product) => {
        props.setDocuments(product.ID_DOC); 
        props.loadDataView();
        setDocumentos([]);
        formik.values.texto = '';
        props.setShowHide(0);
    };

    const [iconsFile] = useState({
        dir: faFolder,
        doc: faFileWord,
        ppt: faFilePowerpoint,
        xls: faFileExcel,
        docx: faFileWord,
        pptx: faFilePowerpoint,
        xlsx: faFileExcel,
        csv: faFileCsv,
        pdf: faFilePdf,
        jpg: faFileImage,
        png: faFileImage,
        gif: faFileImage,
        mp4: faFileVideo,
        avi: faFileVideo,
        mkv: faFileVideo,
        mp3: faFileAudio,
        zip: faFileArchive,
        rar: faFileArchive,
        link: faLink,
        other: faFile,
    });

    const iconFileType = (data) => {
        let key = "other";
        if (data.TIPO === 1) {
            key = "dir";
        } else if (data.TIPO === 3) {
            key = "link";
        } else if (data.TIPO === 2 && iconsFile[data.EXTENSION]) {
            key = data.EXTENSION;
        }
        return iconsFile[key];
    };

    const tipoBodyTemplate = (rowData) => {
        return <FontAwesomeIcon icon={iconFileType(rowData)} style={{ fontSize: "2rem",}} />
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-reply" rounded outlined className="mr-2" onClick={() => mostrar(rowData)} />
            </React.Fragment>
        );
    };

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header="Buscar Documento" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "35vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="card">
                        <div className="field py-2">
                            <span className="p-float-label p-input-icon-right">
                                <InputText id="texto" name="texto" type="text" value={formik.values.texto} onChange={formik.handleChange} />
                                <label htmlFor="texto">Introduzca texto</label>
                                <Button label="Buscar" type="submit" icon="pi pi-search" className="p-button-text" />
                                <Button label="Cerrar" type="reset" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                            </span>
                        </div>
                        <div className="field py-2">
                            <DataTable
                                responsiveLayout="scroll"                               
                                value={documentos} rows="10" paginator="true"
                            >                                
                                <Column header="Tipo" body={tipoBodyTemplate}></Column>
                                <Column field="NOMBRE" header="Título" sortable></Column>
                                <Column field="CODIGO" header="Código" sortable></Column>
                                <Column body={actionBodyTemplate} exportable={false} style={{ fontSize: "2rem" }}></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div className="p-dialog-footer"></div>
                </form>
            </Dialog>
        </div>
    );
};
