import { Badge } from "primereact/badge";
import React from "react";
import { formatDate, setZeroHours, sumDate } from "./date.helper";

const isRegistrable = (curso) => {
    const weekBefore = sumDate(setZeroHours(new Date(parseInt(curso.FECHAINI))), -8);
    const dayBefore = sumDate(setZeroHours(new Date(parseInt(curso.FECHAINI))), -1);
    const today = setZeroHours(new Date());
    return today.getTime() > weekBefore.getTime() && today.getTime() <= dayBefore.getTime();
};

const isErasable = (curso) => {
    const today = setZeroHours(new Date());
    const dayBefore = sumDate(setZeroHours(new Date(parseInt(curso.FECHAINI))), -1);
    return today.getTime() <= dayBefore.getTime();
};

const isFullCourse = (curso) => {
    return curso.NUM_SUSCRITOS >= curso.CUPO;
};

const haveCertificate = (curso) => {
    const horaMin = Math.round((curso.NRO_HORAS * curso.MINIMO) / 100);
    return curso.ASISTENCIA >= horaMin;
};

const horaBodyTemplate = (rowData) => {
    return (
        <>
            {rowData.HORAINI} a {rowData.HORAFIN} hrs.
        </>
    );
};

const fechaBodyTemplate = (rowData) => {
    const fechaIni = new Date(parseInt(rowData.FECHAINI));
    const fechaFin = new Date(parseInt(rowData.FECHAFIN));
    return (
        <>
            {formatDate(fechaIni)} al {formatDate(fechaFin)}
        </>
    );
};

const tipoBodyTemplate = (rowData) => {
    return <Badge value={tipoCurso[rowData.TIPO]} severity="info"></Badge>;
};

const cantHorasBodyTemplate = (rowData) => {
    return <>{rowData.NRO_HORAS} hrs</>;
};

const asistenciaBodyTemplate = (rowData) => {
    const horasMinimas = (rowData.NRO_HORAS * rowData.MINIMO) / 100;
    let badge = "";

    if (rowData.ASISTENCIA === null) {
        badge = <Badge value="No asistió" severity="warning"></Badge>;
    } else if (horasMinimas <= rowData.ASISTENCIA) {
        badge = <Badge value="Asistió" severity="success"></Badge>;
    } else if (horasMinimas > rowData.ASISTENCIA) {
        badge = <Badge value="Insuficiente" severity="danger"></Badge>;
    }
    return (
        <>
            {rowData.ASISTENCIA !== null ? `${rowData.ASISTENCIA} Hrs.` : ""} {badge}
        </>
    );
};

const estadoBodyTemplate = (rowData) => {
    let badge = "";
    if (rowData.INSCRITO === 1 && isBetweenCurrentDates(rowData)) {
        badge = <Badge value="Inscrito (En curso)" severity="warning"></Badge>;
    } else if (rowData.INSCRITO === 1) {
        badge = <Badge value="Inscrito" severity="success"></Badge>;
    } else {
        badge = <Badge value="Pasado" severity="danger"></Badge>;
    }
    return <>{badge}</>;
};

const isBetweenCurrentDates = (curso) => {
    const dateIni = setZeroHours(new Date(parseInt(curso.FECHAINI)));
    const dateEnd = setZeroHours(new Date(parseInt(curso.FECHAFIN)));
    const today = setZeroHours(new Date());
    return today.getTime() > dateIni.getTime() && today.getTime() <= dateEnd.getTime();
};

const asistenciaMinBodyTemplate = (rowData) => {
    const horasMinimas = (rowData.NRO_HORAS * rowData.MINIMO) / 100;
    return <>{horasMinimas} hrs.</>;
};

const tipoCurso = {
    P: "Presencial",
    S: "Semi-Presencial",
    V: "Virtual",
};

export { isRegistrable, isErasable, horaBodyTemplate, fechaBodyTemplate, tipoBodyTemplate, cantHorasBodyTemplate, tipoCurso, asistenciaBodyTemplate, haveCertificate, asistenciaMinBodyTemplate, isFullCourse, estadoBodyTemplate };
