import certificateIssued from "../../constants/smartCertificate/const.certificateIssued";
import { httpGraphql } from "../../helpers/httpClient.helper";

export class CertificateIssuedService {
    async getFilenameCertificate(idCert) {
        const res = await httpGraphql.query(certificateIssued.apiUrl, certificateIssued.queries.getFilenameCertificate(idCert));
        let data = res.data.data.tmpDiploma;
        return data;
    }

    async getDiplomaSmartCertificate(idCert) {
        const res = await httpGraphql.query(certificateIssued.apiUrl, certificateIssued.queries.getDiplomaSmartCertificate(idCert));
        let data = res.data.data.getDiplomaSmartCertificate;
        return data;
    }

    async certifyBySmartCertificate(idCert, filename) {
        const res = await httpGraphql.mutation(certificateIssued.apiUrl, certificateIssued.mutations.certifyBySmartCertificate(idCert, filename));
        let data = res.data.data.certifyBySmartCertificate;
        return data;
    }

    async decertifyBySmartCertificate(idCert) {
        const res = await httpGraphql.mutation(certificateIssued.apiUrl, certificateIssued.mutations.decertifyBySmartCertificate(idCert));
        let data = res.data.data.decertifyBySmartCertificate;
        return data;
    }
}
