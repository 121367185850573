import React from "react";
import { Dropdown } from "primereact/dropdown";

export default function DropDowCities(props) {
    const cities = [
        { name: "COCHABAMBA", code: 'COCHABAMBA' },
        { name: "LA PAZ", code: 'LA PAZ' },
        { name: "SANTA CRUZ", code: 'SANTA CRUZ' },
    ];    
    return (
        <Dropdown 
            id={props.id}
            value={props.formik.values.LUGAR} 
            onChange={props.formik.handleChange} 
            options={cities} 
            optionLabel="name" 
            name={props.name}
            placeholder="--Seleccione--" 
            className="field col-12 md:col-12 xs:col-12 p-0" />
    );
}