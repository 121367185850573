import React, { useState, useEffect, useCallback } from "react";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/layout/flags/flags.css";
import "./assets/layout/layout.scss";
import "./App.scss";

import LayoutMain from "./layouts/main/LayoutMain";
import LayoutLogin from "./layouts/login/LayoutLogin";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import { LoginService } from "./services/LoginService";
import { delToken, setToken } from "./helpers/token.helper";
import { setLayout, setMainPathame } from "./helpers/session.helper";
import { decode, encode } from "./helpers/crypto.helper";

const App = () => {
    const [haveAccessSystem, setHaveAccessSystem] = useState(null);
    const [searchParams] = useSearchParams();
    const [loginService] = useState(new LoginService());
    const location = useLocation();

    //Seteando metodos datos de tipo Get
    // ly=layout
    // tk=token
    useEffect(() => {
        /*console.log("01");
        console.log(location.pathname);
        console.log(location.search);
        console.log("LY:", searchParams.get("ly"));
        console.log("TK:", searchParams.get("tk"));*/
        if (searchParams.get("ly") === "none" && searchParams.get("tk")) {
            document.documentElement.style.fontSize = "12px";
            setToken(searchParams.get("tk"));
            setLayout(searchParams.get("ly"));
            setMainPathame(location.pathname);
        }
    });

    const verifySystemAccess = useCallback(async () => {
        const data = await loginService.isloged();
        if (data !== null) {
            //const jsonData = encode(JSON.stringify(data.payload.expiryTime));
            const jsonData = encode(data.payload.expiryTime.toString());
            console.log("encode:", jsonData);
            console.log("decode:", JSON.parse(decode(jsonData)));
            setHaveAccessSystem(true);
        } else {
            setHaveAccessSystem(false);
            delToken();
        }
    }, [loginService]);

    useEffect(() => {
        verifySystemAccess();
    }, [verifySystemAccess]);

    return (
        <div className="App">
            <header className="App-header">
                <Routes>
                    <Route path="/login" element={<LayoutLogin haveAccess={haveAccessSystem} setHaveAccess={setHaveAccessSystem} />} />
                    <Route path="/*" element={<LayoutMain haveAccess={haveAccessSystem} />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </header>
        </div>
    );
};

export default App;
