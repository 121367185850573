import axios from "axios";
import { getToken } from "../../helpers/token.helper";
import investigador from "../../constants/convenios/conv.investigador";

export class InvestigadorService {
    async getAll() {
        const res = await axios.post(
            investigador.apiUrl,
            {
                query: `query{${investigador.queries.getAll()}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.investigadores;
    }

    create(data) {
        let mutation = `mutation{${investigador.queries.create(data)}}`;
        return axios
            .post(
                investigador.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.investigador;
            });
    }

    update(id, data) {
        let mutation = `mutation{${investigador.queries.update(id, data)}}`;
        return axios
            .post(
                investigador.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.updateInvestigador;
            });
    }

    delete(id) {
        let mutation = `mutation{${investigador.queries.delete(id)}}`;
        return axios
            .post(
                investigador.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.deleteInvestigador;
            });
    }
}
