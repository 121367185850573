import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { SelectButton } from "primereact/selectbutton";
import { Tooltip } from "primereact/tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie, faFlask, faUserGraduate } from "@fortawesome/free-solid-svg-icons";

import "./DialogStudents.css";
import "primeflex/primeflex.css";

import { EstudianteService } from "../../../services/convenios/EstudianteService";
import { DialogEditEstudiante } from "./DialogEditEstudiante";

export const DialogStudents = (props) => {
    const [valueTypePerson, setValueTypePerson] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [filteredStudents, setFilteredStudents] = useState(null);
    const [estudiantesConvenio, setEstudiantesConvenio] = useState([]);
    const [estudianteService] = useState(new EstudianteService());
    const [dialogEditEstudianteState, setDialogEditEstudianteState] = useState(false);
    const [selectedEditStudent, setSelectedEditStudent] = useState(null);

    const typePersonOptions = [
        { icon: faUserGraduate, value: "PREGRADO" },
        { icon: faUserTie, value: "POSGRADO" },
        { icon: faFlask, value: "INVESTIGADOR" },
    ];

    const setConvenioEstudiante = (item) => {
        let existList = estudiantesConvenio.find((estudiante) => {
            return estudiante.COD_PERSONA === item.COD_PERSONA;
        });
        if (!existList) {
            estudianteService.addEstudianteConvenio(props.selectedConvenio.ID, item, valueTypePerson).then((data) => {
                let _estudiantesConvenio = [...estudiantesConvenio];
                _estudiantesConvenio.push(data);
                setEstudiantesConvenio(_estudiantesConvenio);
                props.selectedConvenio.ESTUDIANTES = _estudiantesConvenio;
            });
        }
        setSelectedStudent(null);
    };

    const searchStudents = (event) => {
        estudianteService.getAll(valueTypePerson, event.query).then((data) => {
            setFilteredStudents(data);
        });
    };

    const itemStudentTemplate = (item) => {
        return (
            <div className="country-item">
                <div>
                    {item.NOMBRE} {item.AP_PATERNO} {item.AP_MATERNO}
                </div>
            </div>
        );
    };

    const onHide = () => {
        props.setShowHide(0);
    };

    const onShow = () => {
        setEstudiantesConvenio([]);
        setValueTypePerson("PREGRADO");
        if (props.selectedConvenio) {
            estudianteService.getEstudiantesConvenio(props.selectedConvenio.ID).then((data) => {
                setEstudiantesConvenio(data ? data : []);
            });
        }
    };

    const borrarEstudiante = (estudiante) => {
        estudianteService.delEstudianteConvenio(estudiante.ID).then((data) => {
            estudianteService.getEstudiantesConvenio(props.selectedConvenio.ID).then((data) => {
                props.selectedConvenio.ESTUDIANTES = data ? data : [];
                setEstudiantesConvenio(data ? data : []);
            });
        });
    };

    const setDialogEditEstudiante = (estudiante) => {
        setSelectedEditStudent(estudiante);
        setDialogEditEstudianteState(true);
    };

    const toCapitalize = (str) => {
        return str
            .toLowerCase()
            .split(" ")
            .map(function (word) {
                return word[0] ? word[0].toUpperCase() + word.substr(1) : "";
            })
            .join(" ");
    };

    const listEstConvenio = estudiantesConvenio.map((estudiante) => {
        const fechaIni = estudiante.FECHAINI ? new Date(parseInt(estudiante.FECHAINI)).toLocaleDateString("es-ES") : null;
        const fechaFin = estudiante.FECHAFIN ? new Date(parseInt(estudiante.FECHAFIN)).toLocaleDateString("es-ES") : null;
        return (
            <div key={estudiante.COD_PERSONA}>
                <div className="card">
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-6 sm:flex-nowrap">
                            <div className="text-2xl font-bold">{toCapitalize(`${estudiante.AP_PATERNO} ${estudiante.AP_MATERNO ? `${estudiante.AP_MATERNO} ` : ``}${estudiante.NOMBRE}`)}</div>
                            <div className="text-sm">{estudiante.TIPO !== estudiante.CARRERA ? `${estudiante.TIPO}: ${estudiante.CARRERA}` : estudiante.TIPO}</div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="grid">
                                <div className="col-12 md:col-6 font-bold">Fecha Inicio:</div>
                                <div className="col-12 md:col-6">{fechaIni}</div>
                                <div className="col-12 md:col-6 font-bold">Fecha Fin:</div>
                                <div className="col-12 md:col-6">{fechaFin}</div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <Button label="Fechas" icon="pi pi-pencil" className="p-button-sm p-button-rounded p-button-info" onClick={() => setDialogEditEstudiante(estudiante)} />
                            <Button label="Borrar" icon="pi pi-times" className="p-button-sm p-button-rounded p-button-danger" onClick={() => borrarEstudiante(estudiante)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    const typePersonTemplate = (option) => {
        return (
            <div className={`customClassName${option.value} text-lg `}>
                <Tooltip target={".customClassName" + option.value}> Buscar solo por adjudicatario ({option.value})</Tooltip>
                <FontAwesomeIcon icon={option.icon} style={{ color: "var(--blue-900)" }} />
            </div>
        );
    };

    return (
        <div>
            <DialogEditEstudiante setShowHide={setDialogEditEstudianteState} showHide={dialogEditEstudianteState} estudiante={selectedEditStudent} loadEstudiantes={onShow}></DialogEditEstudiante>
            <Dialog header="Adjudicatario - Convenio" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                <div className="p-fluid">
                    <div className="grid">
                        <div className="col-12 md:col-2">
                            <SelectButton value={valueTypePerson} options={typePersonOptions} onChange={(e) => setValueTypePerson(e.value ? e.value : valueTypePerson)} itemTemplate={typePersonTemplate} className="typePerson" />
                        </div>
                        <div className="col-12 md:col-10">
                            <span className="p-float-label">
                                <AutoComplete
                                    id="autocomplete"
                                    value={selectedStudent}
                                    suggestions={filteredStudents}
                                    completeMethod={searchStudents}
                                    field="ID"
                                    dropdown
                                    forceSelection
                                    itemTemplate={itemStudentTemplate}
                                    onChange={(e) => setSelectedStudent(e.value)}
                                    onSelect={(e) => setConvenioEstudiante(e.value)}
                                />
                                <label htmlFor="autocomplete">
                                    Buscar y agregar Adjudicatario <b>"{valueTypePerson}"</b>
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-grid">{listEstConvenio}</div>
            </Dialog>
        </div>
    );
};
