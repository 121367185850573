import CryptoJS from "crypto-js";

const encode = (text) => {
    let ciphertext = CryptoJS.AES.encrypt(text, process.env.REACT_APP_KEY);
    return ciphertext.toString();
};

const decode = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_KEY);
    let decodedText = bytes.toString(CryptoJS.enc.Utf8);
    return decodedText;
};

export { encode, decode };
