module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getInstituciones: () => {
            return `
            instituciones{
                COD_INSTITUCION
                RAZON_SOCIAL
                TELEFONO_INS
                FAX_INST
                EMAIL_INST
                URL_INST
                DOMICILIO_INST
            }`;
        },
        getInstitucion: (id) => {
            return `
            institucion(id:${id}){
                COD_INSTITUCION
                RAZON_SOCIAL
                TELEFONO_INS
                FAX_INST
                EMAIL_INST
                URL_INST
                DOMICILIO_INST
            }`;
        },
    },
};
