import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import { Toast } from "primereact/toast";
import { MenuService } from "../../services/menus/MenuService";
import { commandFunctions } from "../../helpers/menu/command.helper";
import { getIcon } from "../../helpers/menu/menu.helper";

const AppSubmenu = (props) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const onMenuItemClick = (event, item, index) => {
        //avoid processing disabled items
        if (item.DISABLED) {
            event.preventDefault();
            return true;
        }

        //execute command
        if (item.COMMAND && commandFunctions[item.COD]) {
            commandFunctions[item.COD]({ originalEvent: event, item: item });
            //item.command({ originalEvent: event, item: item });
        } else if (item.COMMAND) {
            console.log(item.COD, "Sin comando");
        }

        if (index === activeIndex) setActiveIndex(null);
        else setActiveIndex(index);

        if (props.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item,
            });
        }
    };

    const onKeyDown = (event) => {
        if (event.code === "Enter" || event.code === "Space") {
            event.preventDefault();
            event.target.click();
        }
    };

    const renderLinkContent = (item) => {
        let submenuIcon = item.ITEMS && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.BADGE && <Badge value={item.BADGE} />;
        let icon = getIcon(item.ICON);

        return (
            <React.Fragment>
                {icon}
                <span>{item.LABEL}</span>
                {submenuIcon}
                {badge}
                <Ripple />
            </React.Fragment>
        );
    };

    const renderLink = (item, i) => {
        let content = renderLinkContent(item);

        if (item.TO) {
            return (
                <NavLink aria-label={item.LABEL} onKeyDown={onKeyDown} role="menuitem" className="p-ripple" to={item.TO} onClick={(e) => onMenuItemClick(e, item, i)} target={item.TARGET}>
                    {content}
                </NavLink>
            );
        } else {
            return (
                <a tabIndex="0" aria-label={item.LABEL} onKeyDown={onKeyDown} role="menuitem" href={item.URL} className="p-ripple" onClick={(e) => onMenuItemClick(e, item, i)} target={item.TARGET}>
                    {content}
                </a>
            );
        }
    };

    return (
        <ul className={props.className} role="menu">
            {props.items &&
                props.items.map((item, i) => {
                    let active = activeIndex === i;
                    let styleClass = classNames(item.badgeStyleClass, { "layout-menuitem-category": props.root, "active-menuitem": active && !item.TO });

                    if (props.root) {
                        return (
                            <li className={styleClass} key={i} role="none">
                                {props.root === true && (
                                    <React.Fragment>
                                        <div className="layout-menuitem-root-text" aria-label={item.LABEL}>
                                            {item.LABEL}
                                        </div>
                                        <AppSubmenu items={item.ITEMS} onMenuItemClick={props.onMenuItemClick} />
                                    </React.Fragment>
                                )}
                            </li>
                        );
                    } else {
                        return (
                            <li className={styleClass} key={i} role="none">
                                {renderLink(item, i)}
                                <CSSTransition classNames="layout-submenu-wrapper" timeout={{ enter: 1000, exit: 450 }} in={active} unmountOnExit>
                                    <AppSubmenu items={item.ITEMS} onMenuItemClick={props.onMenuItemClick} />
                                </CSSTransition>
                            </li>
                        );
                    }
                })}
        </ul>
    );
};

export const AppMenu = (props) => {
    const [menuService] = useState(new MenuService());
    const [modelMenu, setModelMenu] = useState([]);
    const toast = useRef(null);

    const showToast = () => {
        toast.current.show({ severity: "success", summary: "Success Message", detail: "Message Content", life: 3000 });
    };

    useEffect(() => {
        menuService.maxLevelMenu().then((level) => {
            return menuService.menusSession(level).then((allMenu) => {
                return setModelMenu(allMenu);
            });
        });
    }, [menuService]);

    /*const modelMenu = [
        {
            label: "Sistema Gestion de Calidad",
            icon: "pi pi-fw pi-sitemap",
            items: [
                {
                    label: "Convenios",
                    icon: "pi pi-fw pi-sync",
                    items: [
                        { label: "Listado de Convenios", icon: "pi pi-fw pi-briefcase", to: "/convenios" },
                        { label: "Investigadores", icon: "pi pi-fw pi-search", to: "/investigadores" },
                    ],
                },
                {
                    label: "Publicaciones",
                    icon: "pi pi-fw pi-share-alt",
                    items: [
                        { label: "Listado de publicaciones", icon: "pi pi-fw pi-globe", to: "/publicaciones" },
                        { label: "Empresas", icon: "pi pi-fw pi-building", to: "/empresas" },
                    ],
                },
            ],
        },
        {
            label: "Docente Pregrado",
            items: [{ label: "Formacion Docente", icon: "pi pi-fw pi-desktop", to: "/formacionDocente" }],
        },
        {
            label: "Sistema Administrativo Financiero",
            items: [
                { label: "Contabilidad", icon: "pi pi-fw pi-mobile", to: "/button" },
                { label: "Facturación", icon: "pi pi-fw pi-circle-off", to: "/misc", badge: "Nuevo" },
                { label: "Ventas", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
                { label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-react" },
            ],
        },
        {
            label: "Sistema",
            items: [
                {
                    label: "Cerrar sesión",
                    icon: "pi pi-fw pi-sign-out",
                    command: () => {
                        closeSesion();
                    },
                },
            ],
        },
    ];*/
    return (
        <>
            <Toast ref={toast} />
            <div className="layout-menu-container">{modelMenu && <AppSubmenu items={modelMenu} className="layout-menu" onMenuItemClick={props.onMenuItemClick} root={true} role="menu" showToast={showToast} />}</div>
        </>
    );
};
