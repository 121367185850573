import axios from "axios";
import convenio from "../../constants/convenios/conv.convenio";
import { getToken } from "../../helpers/token.helper";

export class ConvenioService {
    async getAll() {
        const res = await axios.post(
            convenio.apiUrl,
            {
                query: `query{${convenio.queries.getAll()}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.convenios;
    }

    async getAllByFilter(data) {
        const res = await axios.post(
            convenio.apiUrl,
            {
                query: `query{${convenio.queries.getAllByFilter(data)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                    
                },
            }
        );
        return res.data.data.conveniosByFilter;
    }

    create(data) {
        let mutation = `mutation{${convenio.queries.create(data)}}`;
        return axios
            .post(
                convenio.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.convenio;
            });
    }

    update(id, data) {
        let mutation = `mutation{${convenio.queries.update(id, data)}}`;
        return axios
            .post(
                convenio.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.convenio;
            });
    }

    delete(id) {
        let mutation = `mutation{${convenio.queries.delete(id)}}`;
        return axios
            .post(
                convenio.apiUrl,
                {
                    query: mutation,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.deleteConvenio;
            });
    }
}
