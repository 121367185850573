import axios from "axios";
import { getToken } from "./token.helper";

const instanceAxios = axios.create({ baseURL: process.env.REACT_APP_GRAPHQL });
instanceAxios.interceptors.request.use(
    (request) => {
        request.mode = "no-cors";
        request.headers["Content-Type"] = "application/json";
        request.headers["access-token"] = getToken();
        //request.headers["IP"] = "192.168.1.1";
        return request;
    },
    (error) => {
        console.log(error);
        return error;
    }
);

instanceAxios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!error.response) {
            return Promise.reject({
                status: 999,
                timestamp: new Date(),
                message: "Se ha producido un error inesperado",
                error: "Error inesperado",
            });
        }
        return Promise.reject(error.response.data);
    }
);

const printErrorAxios = (error) => {
    if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log("Error", error.message);
    }
    console.log(error);
};

class HttpGraphql {
    async query(url, query) {
        let response = null;
        try {
            response = await instanceAxios.post(url, { query: `query{${query}}` });
        } catch (error) {
            printErrorAxios(error);
        }
        return response;
    }

    async mutation(url, mutation) {
        let response = null;
        try {
            response = await instanceAxios.post(url, { query: `mutation{${mutation}}` });
        } catch (error) {
            printErrorAxios(error);
        }
        return response;
    }
}
const httpGraphql = new HttpGraphql();

export { httpGraphql, instanceAxios };
