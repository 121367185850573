import React from "react";
import { getPersonName } from "../helpers/session.helper";
import { Image } from "primereact/image";

export const Dashboard = () => {
    return (
        <div className="grid">
            <div className="col-12 xl:col-12">
                <div className="card">
                    <h5>Bienvenido al Sistema</h5>
                    <p>
                        ¡Hola,{" "}
                        <b>
                            <i className="pi pi-user"></i>{" "}
                            {getPersonName()}!
                        </b>{" "}
                        Bienvenido al sistema. Estamos emocionados de tenerte aquí.
                    </p>
                    <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                        <Image src="assets/layout/images/bienvenido.png" alt="Descripción de la imagen" width="75%" />
                    </div>
                    <p>
                        Si tienes alguna pregunta o necesitas ayuda, no dudes en contactarnos a <b>UPB DTI</b>.
                    </p>
                    <p>¡Que tengas una gran experiencia utilizando nuestro sistema!</p>
                </div>
            </div>
        </div>
    );
};
