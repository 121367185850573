import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { MenubarSemester } from "./menubarTable/MenubarSemester.js";
import { DialogToInformation } from "./dialogs/DialogToInformation.js";
import { CursoService } from "../../services/teachertraining/CursoService";
import { asistenciaBodyTemplate, cantHorasBodyTemplate, fechaBodyTemplate, haveCertificate, horaBodyTemplate, isRegistrable, tipoBodyTemplate, tipoCurso } from "../../helpers/formacionDoc.helper.js";
import { ConfirmToUnsubscribe } from "./dialogs/ConfirmToUnsubscribe.js";

export const Suscritos = (props) => {
    const [cursoService] = useState(new CursoService());
    const [cursos, setCursos] = useState([]);
    const [dialogToSubscribeState, setDialogToSubscribeState] = useState(0);
    const [confirmToUnsubscribeState, setConfirmToUnsubscribeState] = useState(0);
    const [selectedIdCurso, setSelectedIdCurso] = useState(null);
    const [selectedGestion, setSelectedGestion] = useState(null);
    const [gestiones, setGestiones] = useState(null);
    const [certSemestral, setCertSemestral] = useState(false);
    const [horasSemestre, setHorasSemestre] = useState(0);
    const toast = useRef(null);
    const horasTotalSemestre = 20;

    useEffect(() => {
        cursoService.getGestiones().then((gestionesDB) => {
            setGestiones(gestionesDB);
            if (gestionesDB && gestionesDB[0]) {
                setSelectedGestion(gestionesDB[0]);
                cursoService.getSuscritos(gestionesDB[0].NOMBRE).then((data) => {
                    if (data && data.length > 0) {
                        const actHrsSemestre = data.map((value) => (haveCertificate(value) ? value.NRO_HORAS : 0)).reduce((previousValue, currentValue) => previousValue + currentValue);
                        setCertSemestral(actHrsSemestre >= horasTotalSemestre);
                        setHorasSemestre(actHrsSemestre);
                    }
                    setCursos(data);
                });
            }
        });
    }, [cursoService]);

    const loadAllDataTable = (gestionUpt = null) => {
        cursoService.getSuscritos(gestionUpt ? gestionUpt : selectedGestion.NOMBRE).then((data) => {
            if (data && data.length > 0) {
                const actHrsSemestre = data.map((value) => (haveCertificate(value) ? value.NRO_HORAS : 0)).reduce((previousValue, currentValue) => previousValue + currentValue);
                setCertSemestral(actHrsSemestre >= horasTotalSemestre);
                setHorasSemestre(actHrsSemestre);
            }
            setCursos(data);
        });
        setSelectedIdCurso(null);
    };

    const onGestionChange = (selGestion) => {
        setSelectedGestion(selGestion);
        loadAllDataTable(selGestion.NOMBRE);
    };

    const loadToSubscribe = (curso_id) => {
        setDialogToSubscribeState(1);
        setSelectedIdCurso(curso_id);
    };

    const loadToUnsubscribe = (curso_id) => {
        console.log(curso_id);
        console.log(confirmToUnsubscribeState);
        setConfirmToUnsubscribeState(1);
        setSelectedIdCurso(curso_id);
    };

    const loadCertificateCurso = async (curso_id) => {
        cursoService.getEncriptacionCurso(curso_id).then((data) => {
            if (data && data.ENCRIPTADO) {
                window.open(`${process.env.REACT_APP_CERTUPB}training/validate/${data.ENCRIPTADO}?w=${data.ID_EMISION}`, "_blank");
            } else {
                toast.current.show({ severity: "warn", summary: "En proceso", detail: "Certificado en proceso de creacion. disculpe las molestias" });
            }
        });
    };

    const loadCertificateGestion = async () => {
        cursoService.getEncriptacionGestion(selectedGestion.NOMBRE).then((data) => {
            if (data && data.ENCRIPTADO) {
                window.open(`${process.env.REACT_APP_CERTUPB}trainings/validate/${data.ENCRIPTADO}?w=${data.ID_EMISION}`, "_blank");
            } else {
                toast.current.show({ severity: "warn", summary: "En proceso", detail: "Certificado en proceso de creacion. disculpe las molestias" });
            }
        });
    };

    const opcionesBodyTemplate = (rowData) => {
        const canErased = isRegistrable(rowData);
        const canCertificate = haveCertificate(rowData);
        return (
            <>
                <Button label="Información" icon="pi pi-info-circle" className="p-button-secondary ml-2" onClick={() => loadToSubscribe(rowData.ID)} />
                {canErased && <Button label="Cancelar Suscripción" icon="pi pi-trash" className="p-button-danger ml-2" onClick={() => loadToUnsubscribe(rowData.ID)} />}
                {canCertificate && <Button label="Certificado" icon="pi pi-download" className="p-button-primary ml-2" onClick={() => loadCertificateCurso(rowData.ID)} />}
            </>
        );
    };

    const headerTable = (
        <div className="flex flex-row-reverse">
            <Button icon="pi pi-refresh" tooltip="Actualizar tabla" onClick={() => loadAllDataTable()} />
            <MenubarSemester onGestionChange={onGestionChange} setCursos={setCursos} gestiones={gestiones} selectedGestion={selectedGestion} certSemestral={certSemestral} horasSemestre={horasSemestre} loadCertificate={loadCertificateGestion}></MenubarSemester>
        </div>
    );

    return (
        <>
            <Toast ref={toast} />
            <DialogToInformation setShowHide={setDialogToSubscribeState} loadAllData={loadAllDataTable} tipoCurso={tipoCurso} showHide={dialogToSubscribeState} selectedIdData={selectedIdCurso}></DialogToInformation>
            <ConfirmToUnsubscribe setShowHide={setConfirmToUnsubscribeState} loadAllData={loadAllDataTable} showHide={confirmToUnsubscribeState} selectedIdData={selectedIdCurso}></ConfirmToUnsubscribe>
            <Fieldset legend="Capacitaciones realizadas">
                <DataTable value={cursos} responsiveLayout="scroll" header={headerTable}>
                    <Column header="Tipo" body={tipoBodyTemplate}></Column>
                    <Column header="Título" field="NOMBRE"></Column>
                    <Column header="Cant. Horas" body={cantHorasBodyTemplate}></Column>
                    <Column header="Asistencia" body={asistenciaBodyTemplate}></Column>
                    <Column header="Fecha" body={fechaBodyTemplate}></Column>
                    <Column header="Horario" body={horaBodyTemplate}></Column>
                    <Column header="Opciones" body={opcionesBodyTemplate}></Column>
                </DataTable>
            </Fieldset>
        </>
    );
};
