const { menuGraphql } = require("../../helpers/menu/menu.helper");

module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        maxLevelMenu: () => {
            return `maxLevelMenu`;
        },
        menusSession: (levels) => {
            let dataObj = menuGraphql(levels);
            return `menusSession{${dataObj}}`;
        },
    },
};
