import axios from "axios";
import { getToken } from "../../helpers/token.helper";
import indexacion from "../../constants/publicaciones/inv.indexacion";

export class IndexacionService {
    getAll() {
        return axios
            .post(
                indexacion.apiUrl,
                {
                    query: `query{${indexacion.queries.getAll()}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.indexaciones;
            });
    }

    getOne(id) {
        return axios
            .post(
                indexacion.apiUrl,
                {
                    query: `query{${indexacion.queries.getOne(id)}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.indexacion;
            });
    }

    insertIndexacion(data) {
        return axios
            .post(
                indexacion.apiUrl,
                {
                    query: `mutation{${indexacion.queries.create(data)}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.createIndexacion;
            });
    }
}
