module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        changesByIdDoc: (idDoc) => {
            return `
            cambioQMByDocId(docID:${idDoc}){
                ID
                ID_DOC
                DESCRIPCION
                NOMBRE
                DOCUMENTO
            }`;
        },
    },
    mutations: {},
};
