module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: (tipo, query) => {
            return `
            estudiantes(tipo:${tipo} query:"${query}"){
                COD_PERSONA
                NOMBRE
                AP_PATERNO
                AP_MATERNO
                CARRERA_ID
                CARRERA
                FECHAINI
                FECHAFIN
            }`;
        },
        getEstudiantesConvenio: (id) => {
            return `
            estudiantesConvenio(id:${id}){
                ID
                COD_PERSONA
                NOMBRE
                AP_PATERNO
                AP_MATERNO
                CARRERA_ID
                CARRERA
                FECHAINI
                FECHAFIN
                TIPO
            }`;
        },
        addEstudianteConvenio: (id, estudiante, typePerson) => {
            return `
            convenioAddEstudiante(idConvenio:${id} estudiante:{COD_PERSONA:${estudiante.COD_PERSONA} CARRERA_ID:${estudiante.CARRERA_ID} TIPO:${typePerson}}){
                ID
                COD_PERSONA
                NOMBRE
                AP_PATERNO
                AP_MATERNO
                CARRERA_ID
                CARRERA
                FECHAINI
                FECHAFIN
                TIPO
            }`;
        },
        delEstudianteConvenio: (idConvenioEstudiante) => {
            return `
            convenioDelEstudiante(idConvenioEstudiante: ${idConvenioEstudiante}){
                ID
                COD_PERSONA
                NOMBRE
                AP_PATERNO
                AP_MATERNO
                CARRERA_ID
                CARRERA
                FECHAINI
                FECHAFIN
                TIPO
            }`;
        },
        update: (id, data) => {
            return `
            updateEstudiante(id:${id} estudiante:{
                FECHAINI: "${data.fechaIni.toJSON()}"
                FECHAFIN: "${data.fechaFin.toJSON()}"
            }){
                ID
            }`;
        },
    },
};
