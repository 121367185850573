import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import "./ValidateDiploma.css";
import { CertificateIssuedService } from "../../services/smartCertificate/CertificateIssuedService";
import { PersonaService } from "../../services/generico/PersonaService";
import { CustomErrorComponent } from "custom-error";
import FileViewer from "react-file-viewer";
import LayoutLoading from "../../layouts/other/LayoutLoading";

export const ValidateDiploma = (props) => {
    const [searchParams] = useSearchParams();
    const [urlFiles, setUrlFiles] = useState(null);
    const [idCert, setIdCert] = useState(null);
    const [disabledButton, setDisabledButton] = useState(false);
    const [filename, setFilename] = useState(null);
    const [diplomaSmartCert, setDiplomaSmartCert] = useState(null);
    const [loading, setLoading] = useState(true);
    const [persona, setPersona] = useState(null);
    const [certificateIssuedService] = useState(new CertificateIssuedService());
    const [personaService] = useState(new PersonaService());

    useEffect(() => {
        const idCertParam = searchParams.get("idCert");
        personaService.getPersonaByIdCertEmit(idCertParam).then(async (persona) => {
            if (!persona) {
                setPersona(null);
                setLoading(false);
                return;
            }
            setPersona(persona);
            if (!isValidPerson(persona)) {
                setLoading(false);
                return;
            }
            let diplomaDB = await certificateIssuedService.getDiplomaSmartCertificate(idCertParam);
            if (diplomaDB !== null) {
                setUrlFiles([{ uri: diplomaDB.IMAGENURL, isCertified: true }]);
                return setDiplomaSmartCert(diplomaDB);
            }
            setDiplomaSmartCert(null);
            let filenameServer = await certificateIssuedService.getFilenameCertificate(idCertParam);
            console.log("filenameServer---------->:", filenameServer);
            if (!filenameServer) {
                setLoading(false);
                setIdCert(null);
                setFilename(null);
                return setUrlFiles(null);
            }
            setIdCert(idCertParam);
            setFilename(filenameServer);
            setUrlFiles([{ uri: `${process.env.REACT_APP_TMP_URL}/${filenameServer}`, isCertified: false }]);
        });
    }, [certificateIssuedService, searchParams, personaService]);

    /* const closeWindows = (event) => {
        let openedWindow = window.open("about:blank", "_self");
        openedWindow.close();

        console.log(window);
        //window.open("", "_self").close();
    }; */

    const isValidPerson = (persona) => {
        return persona.nombre && persona.apMaterno && persona.apPaterno && persona.emailAux;
    };

    const certifyDiploma = async (event) => {
        setDisabledButton(true);
        const diploma = await certificateIssuedService.certifyBySmartCertificate(idCert, filename);
        console.log(diploma);
        if (diploma != null) {
            setUrlFiles([{ uri: diploma.IMAGENURL, isCertified: true }]);
            return setDiplomaSmartCert(diploma);
        }
    };

    const downloadDiploma = async (event) => {
        window.open(urlFiles[0].uri, "_blank");
    };

    const getMessage = () => {
        let message = <Message className="w-9" severity="warn" text="Antes de realizar alguna acción revise detalladamente el diploma!!!" />;

        if (diplomaSmartCert != null) {
            message = <Message className="w-9" severity="success" text="Diploma Certificado en Blockchain!!!" />;
        }

        return <div className="my-1 flex align-items-center justify-content-center">{message}</div>;
    };

    const getValidationPersona = () => {
        if (persona == null) {
            return (
                <div className="py-1 flex align-items-center justify-content-center">
                    <Message className="w-9" severity="error" text="No existe una persona asignada a este certificado!!" />
                </div>
            );
        }
        return (
            <>
                <div className="py-1 flex align-items-center justify-content-center">
                    <b>Nombre:</b>&nbsp;{persona.nombre}&nbsp;{getIconValidation(persona.nombre)}&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Apellido(s):</b>&nbsp;{persona.apPaterno} {persona.apMaterno}&nbsp;{getIconValidation(persona.apPaterno || persona.apMaterno)}&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Correo:</b>&nbsp;{persona.emailAux}&nbsp;{getIconValidation(persona.emailAux)}
                </div>
                {!isValidPerson(persona) && (
                    <div className="py-1 flex align-items-center justify-content-center">
                        <Message className="w-9" severity="error" text="Problemas por falta de datos!!" />
                    </div>
                )}
                {!filename && !urlFiles && (
                    <div className="py-1 flex align-items-center justify-content-center">
                        <Message className="w-9" severity="error" text="No habilitado para certificar!!" />
                    </div>
                )}
            </>
        );
    };

    const getIconValidation = (isValid) => {
        return isValid ? (
            <i className="pi pi-check-circle text-green-500 text-lg" />
        ) : (
            <>
                <b className="text-orange-700 text-lg">No existe!!</b>&nbsp;
                <i className="pi pi-times-circle text-orange-700 text-lg" />
            </>
        );
    };

    const getFileDocument = () => {
        return (
            <div className="flex justify-content-center mx-5">
                <div className="w-9">
                    {urlFiles[0].isCertified ? <img className="w-full px-5" src={urlFiles[0].uri} alt="Certificado Smart certificate" /> : <FileViewer className="w-full px-5" fileType="pdf" filePath={urlFiles[0].uri} onError={onError} onGridSort={() => null} errorComponent={CustomErrorComponent} />}
                </div>
            </div>
        );
    };

    const onError = (e) => {
        console.log(e, "error in file-viewer");
    };

    return (
        <>
            {urlFiles ? (
                <>
                    {getValidationPersona()}
                    <div className="flex justify-content-center mx-5">
                        {diplomaSmartCert == null && <Button loading={disabledButton} label="Certificar" icon="pi pi-check" onClick={certifyDiploma} className="p-button-success" />}
                        &nbsp; &nbsp;
                        {<Button label="Descargar" icon="pi pi-cloud-download" onClick={downloadDiploma} className="p-button-info" />}
                        {/* {diplomaSmartCert != null && <Button label="Descertificar" icon="pi pi-trash" onClick={certifyDiploma} className="p-button-danger" />} */}
                    </div>
                    {getMessage()}
                    {getFileDocument()}
                </>
            ) : (
                <>
                    {loading && (
                        <div className="flex align-items-center justify-content-center mx-2">
                            <Message className="w-9" severity="warn" text="Espere un momento, cargando Diploma!!!  ...." />
                        </div>
                    )}
                    {getValidationPersona()}
                    {loading && <LayoutLoading />}
                </>
            )}
        </>
    );
};
