import axios from "axios";
import { getToken } from "../helpers/token.helper";

export class LoginService {
    async login(data) {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}login`,
                {
                    username: data.username,
                    password: data.password,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            );
            return res.data;
        } catch (error) {
            return null;
        }
    }
    async isloged() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}login`, {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            });
            return res.data;
        } catch (error) {
            return null;
        }
    }
}
