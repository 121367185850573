import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { DialogToInformation } from "./dialogs/DialogToInformation.js";
import { CursoService } from "../../services/teachertraining/CursoService";
import { cantHorasBodyTemplate, fechaBodyTemplate, horaBodyTemplate, estadoBodyTemplate, tipoBodyTemplate, tipoCurso } from "../../helpers/formacionDoc.helper.js";
import { ConfirmToUnsubscribe } from "./dialogs/ConfirmToUnsubscribe.js";
import { MenubarYear } from "./menubarTable/MenubarYear.js";

export const Pasados = (props) => {
    const [cursoService] = useState(new CursoService());
    const [cursos, setCursos] = useState([]);
    const [dialogToSubscribeState, setDialogToSubscribeState] = useState(0);
    const [confirmToUnsubscribeState, setConfirmToUnsubscribeState] = useState(0);
    const [selectedIdCurso, setSelectedIdCurso] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [years, setYears] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        cursoService.getYears().then((yearsDB) => {
            setYears(yearsDB);
            if (yearsDB && yearsDB[0]) {
                setSelectedYear(yearsDB[0]);
                cursoService.getPasados(yearsDB[0]).then((data) => {
                    setCursos(data);
                });
            }
        });
    }, [cursoService]);

    const loadAllDataTable = (yearUpt = null) => {
        cursoService.getPasados(yearUpt ? yearUpt : selectedYear).then((data) => {
            setCursos(data);
        });
        setSelectedIdCurso(null);
    };

    const onYearChange = (selYear) => {
        setSelectedYear(selYear);
        loadAllDataTable(selYear);
    };

    const headerTable = (
        <div className="flex flex-row-reverse">
            <Button icon="pi pi-refresh" tooltip="Actualizar tabla" onClick={() => loadAllDataTable()} />
            <MenubarYear selectedYear={selectedYear} years={years} onYearChange={onYearChange}></MenubarYear>
        </div>
    );

    return (
        <>
            <Toast ref={toast} />
            <DialogToInformation setShowHide={setDialogToSubscribeState} loadAllData={loadAllDataTable} tipoCurso={tipoCurso} showHide={dialogToSubscribeState} selectedIdData={selectedIdCurso}></DialogToInformation>
            <ConfirmToUnsubscribe setShowHide={setConfirmToUnsubscribeState} loadAllData={loadAllDataTable} showHide={confirmToUnsubscribeState} selectedIdData={selectedIdCurso}></ConfirmToUnsubscribe>
            <Fieldset legend="Capacitaciones realizadas">
                <DataTable value={cursos} responsiveLayout="scroll" header={headerTable}>
                    <Column header="Tipo" body={tipoBodyTemplate}></Column>
                    <Column header="Título" field="NOMBRE"></Column>
                    <Column header="Cant. Horas" body={cantHorasBodyTemplate}></Column>
                    <Column header="Fecha" body={fechaBodyTemplate}></Column>
                    <Column header="Horario" body={horaBodyTemplate}></Column>
                    <Column header="Estado" body={estadoBodyTemplate}></Column>
                </DataTable>
            </Fieldset>
        </>
    );
};
