module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getPersonaByCerEmit: (id) => {
            return `
            personaByCertificadoEmitido(idCerEmit:${id}){
                codPersona
                email
                emailAux
                nombre
                apPaterno
                apMaterno
                telefonos
            }`;
        },
    },
    mutations: {},
};
