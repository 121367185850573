module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            areasEspecialidad{
                ID
                NOMBRE
            }`;
        },

        getOne: (id) => {
            return `
            areaEspecialidad(id:${id}){
                ID
                NOMBRE
            }`;
        },

        create: (data) => {
            return `
            createAreaEspecialidad(areaEspecialidad:{
                NOMBRE: "${data.ed_nombre}"
            }){
                ID
            }`;
        },
    },
};
