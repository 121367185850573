import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";

import calConfig from "../../../constants/calendar.config";
import { EstudianteService } from "../../../services/convenios/EstudianteService";

export const DialogEditEstudiante = (props) => {
    const titleIni = "Editar fechas";
    const [titleDialog, setTitleDialog] = useState(titleIni);
    const [estudianteService] = useState(new EstudianteService());

    const formik = useFormik({
        initialValues: {
            fechaFin: "",
            fechaIni: "",
        },
        validationSchema: Yup.object({
            fechaFin: Yup.date().required("Campo requerido!!"),
            fechaIni: Yup.date().required("Campo requerido!!"),
        }),
        onSubmit: async (data) => {
            await estudianteService.update(props.estudiante.ID, data);
            onHide();
            props.loadEstudiantes();
        },
    });

    addLocale(calConfig.sp.name, calConfig.sp.config);

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const onHide = () => {
        props.setShowHide(false);
    };

    const onShow = () => {
        setTitleDialog(`${titleIni} (${props.estudiante.NOMBRE})`);
        formik.setFieldValue(
            "fechaFin",
            props.estudiante.FECHAFIN
                ? new Date(parseInt(props.estudiante.FECHAFIN))
                : null
        );
        formik.setFieldValue(
            "fechaIni",
            props.estudiante.FECHAINI
                ? new Date(parseInt(props.estudiante.FECHAINI))
                : null
        );
    };

    return (
        <div>
            <Dialog
                header={titleDialog}
                visible={props.showHide}
                style={{ width: "50vw" }}
                onHide={() => onHide()}
                onShow={() => onShow()}
            >
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="card">
                        <div className="p-fluid">
                            <div className="p-field p-grid">
                                <label
                                    htmlFor="fechaIni"
                                    className="p-col-12 p-md-2"
                                >
                                    Fecha Inicio:
                                </label>
                                <div className="p-col-12 p-md-10">
                                    <Calendar
                                        id="fechaIni"
                                        name="fechaIni"
                                        type="text"
                                        locale="es"
                                        autoFocus
                                        dateFormat="dd/mm/yy"
                                        className="p-inputtext-sm"
                                        value={formik.values.fechaIni}
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage("fechaIni")}
                                </div>
                            </div>
                            <div className="p-field p-grid">
                                <label
                                    htmlFor="fechaFin"
                                    className="p-col-12 p-md-2"
                                >
                                    Fecha Fin:
                                </label>
                                <div className="p-col-12 p-md-10">
                                    <Calendar
                                        id="fechaFin"
                                        name="fechaFin"
                                        type="text"
                                        value={formik.values.fechaFin}
                                        onChange={formik.handleChange}
                                        locale="es"
                                        autoFocus
                                        dateFormat="dd/mm/yy"
                                    />
                                    {getFormErrorMessage("fechaFin")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button
                            type="reset"
                            label="Cancelar"
                            icon="pi pi-times"
                            onClick={() => onHide()}
                            className="p-button-text"
                        />
                        <Button
                            type="submit"
                            label="Guardar"
                            icon="pi pi-save"
                            //onClick={() => props.setShowHide(false)}
                            autoFocus
                        />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
