import axios from "axios";
import { getToken } from "../../helpers/token.helper";
import autor from "../../constants/publicaciones/inv.autor";

export class AutorService {
    getAll() {
        return axios
            .post(
                autor.apiUrl,
                {
                    query: `query{${autor.queries.getAll()}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.autores;
            });
    }

    getOne(id) {
        return axios
            .post(
                autor.apiUrl,
                {
                    query: `query{${autor.queries.getOne(id)}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.autor;
            });
    }

    insert(data) {
        return axios
            .post(
                autor.apiUrl,
                {
                    query: `mutation{${autor.queries.create(data)}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.createAutor;
            });
    }
}
