module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            investigadores{
                ID
                NOMBRE
                APELLIDOS
                CARNET
                CARGO
                INSTITUCION {
                    COD_INSTITUCION
                    RAZON_SOCIAL
                }
            }`;
        },
        getOne: (id) => {
            return `
            investigador(id:${id}){
                ID
                NOMBRE
                APELLIDOS
                CARNET
                CARGO
                INSTITUCION {
                    COD_INSTITUCION
                    RAZON_SOCIAL
                }
            }`;
        },

        create: (data) => {
            return `
            createInvestigador(investigador:{
                NOMBRE: "${data.nombre_rep}"
                APELLIDOS: "${data.apellidos_rep}"
                CARNET: "${data.docId_rep}"
                CARGO: "${data.cargo_rep}"
                COD_INSTITUCION: ${data.cod_empresa ? data.cod_empresa : null}
            }){
                ID
            }`;
        },

        update: (id, data) => {
            return `
            updateInvestigador(id:${id} investigador:{
                NOMBRE: "${data.nombre_rep}"
                APELLIDOS: "${data.apellidos_rep}"
                CARNET: "${data.docId_rep}"
                CARGO: "${data.cargo_rep}"
                COD_INSTITUCION: ${data.cod_empresa ? data.cod_empresa : null}
            }){
                ID
            }`;
        },

        delete: (id) => {
            return `
            deleteInvestigador(id:${id}){
                ID
            }`;
        },
    },
};
