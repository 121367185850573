import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { addLocale } from "primereact/api";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCircle } from "@fortawesome/free-solid-svg-icons";

import "primeflex/primeflex.css";

import calConfig from "../../../constants/calendar.config";
import { ConvenioService } from "../../../services/convenios/ConvenioService";
import { InstitucionService } from "../../../services/convenios/InstitucionService";

export const FilterConvenios = (props) => {
    const [instituciones, setInstituciones] = useState([]);
    const [representantes, setRepresentantes] = useState([]);
    const [institucionService] = useState(new InstitucionService());
    const [convenioService] = useState(new ConvenioService());

    const op = useRef(null);

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values) => {
            let publicaciones = await convenioService.getAllByFilter(values);
            console.log(publicaciones);
            props.setConvenios(publicaciones);
        },
    });

    /****************************** */
    addLocale(calConfig.sp.name, calConfig.sp.config);

    const dtObjetosConvenios = [
        {
            label: "CONVENIOS ESPECÍFICOS",
            code: "CE",
            items: [
                { label: "Prácticas", value: "PRACTICA", data: "PREGRADO" },
                { label: "Pasantías", value: "PASANTIA", data: "PREGRADO" },
                {
                    label: "Intercambio pregrado",
                    value: "INTER_PREG",
                    data: "PREGRADO",
                },
                {
                    label: "Intercambio posgrado",
                    value: "INTER_POSG",
                    data: "POSGRADO",
                },
                {
                    label: "Proyectos de investigación",
                    value: "PROY_INVEST",
                    data: "INVETIGADOR",
                },
                {
                    label: "Convenios de programas de doble titulación-posgrado",
                    value: "TITUL_POSG",
                    data: "POSGRADO",
                },
                {
                    label: "Convenios de programas de doble titulación-Pregrado",
                    value: "TITUL_PREG",
                    data: "PREGRADO",
                },
                { label: "Otros", value: "OTHER", data: "" },
            ],
        },
        {
            label: "CONVENIOS MARCO",
            code: "CM",
            items: [
                {
                    label: "Programas de colaboración institucional",
                    value: "PROG_INST",
                    data: "INVETIGADOR",
                },
            ],
        },
    ];

    const groupedItemTemplate = (option) => {
        return (
            <div className="p-d-flex p-ai-center country-item">
                <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "2em", color: "var(--teal-500)" }} />
                &nbsp;
                <b>{option.label}</b>
            </div>
        );
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="country-item">
                &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={faCircle} style={{ fontSize: "0.8em", color: "var(--teal-500)" }} />
                &nbsp;
                <i>{option.label}</i>
            </div>
        );
    };

    const onShow = async () => {
        institucionService.getInstituciones().then((data) => {
            setInstituciones(data);
        });
    };

    const onInstitucionChange = (e) => {
        formik.handleChange(e);
        let representantes = getSelectedRespresentantes(e.value);
        setRepresentantes(representantes);
    };

    const getSelectedRespresentantes = (idInstitucion) => {
        let institucion = instituciones.find((institucion) => idInstitucion === institucion.COD_INSTITUCION);
        let representantes = institucion && institucion.REPRESENTANTES ? institucion.REPRESENTANTES : [];
        return representantes;
    };

    const selectedInstitucionTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.RAZON_SOCIAL}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const institucionOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.RAZON_SOCIAL}</div>
            </div>
        );
    };

    const selectedRepresentanteTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.NOMBRE}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const representanteOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.NOMBRE}</div>
            </div>
        );
    };
    /****************************** */
    return (
        <div style={{ display: "inline" }}>
            <Button type="button" icon="pi pi-filter-slash" label="Filtros" className="p-button-info p-button-outlined" onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" />
            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{ width: "450px" }} onShow={() => onShow()}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="card">
                        <div className="p-fluid">
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Dropdown
                                        id="cod_empresa"
                                        name="cod_empresa"
                                        value={formik.values.cod_empresa}
                                        options={instituciones}
                                        onChange={onInstitucionChange}
                                        optionLabel="RAZON_SOCIAL"
                                        optionValue="COD_INSTITUCION"
                                        filter
                                        showClear
                                        filterBy="RAZON_SOCIAL"
                                        placeholder="&nbsp;"
                                        valueTemplate={selectedInstitucionTemplate}
                                        itemTemplate={institucionOptionTemplate}
                                        autoFocus={true}
                                        className="inputfield w-full"
                                    />
                                    <label htmlFor="cod_empresa">Empresas</label>
                                </span>
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Dropdown
                                        id="id_representante"
                                        name="id_representante"
                                        value={formik.values.id_representante}
                                        options={representantes}
                                        onChange={formik.handleChange}
                                        optionLabel="NOMBRE"
                                        optionValue="ID"
                                        filter
                                        showClear
                                        filterBy="NOMBRE"
                                        placeholder="&nbsp;"
                                        valueTemplate={selectedRepresentanteTemplate}
                                        itemTemplate={representanteOptionTemplate}
                                        className="inputfield w-full"
                                    />
                                    <label htmlFor="id_representante">Representante</label>
                                </span>
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Dropdown
                                        id="grado"
                                        name="grado"
                                        value={formik.values.grado}
                                        options={dtObjetosConvenios}
                                        onChange={formik.handleChange}
                                        optionLabel="label"
                                        optionGroupLabel="label"
                                        optionGroupChildren="items"
                                        optionGroupTemplate={groupedItemTemplate}
                                        itemTemplate={countryOptionTemplate}
                                        className="inputfield w-full"
                                        placeholder="&nbsp;"
                                        showClear
                                    />
                                    <label htmlFor="id_autor">Objeto Del Convenio</label>
                                </span>
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Calendar id="fecha_ini" name="fecha_ini" value={formik.values.fecha_ini} onChange={formik.handleChange} locale="es" dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput />
                                    <label htmlFor="fecha_ini">Fecha Comienzo</label>
                                </span>
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Calendar id="fecha_fir" name="fecha_fir" value={formik.values.fecha_fir} onChange={formik.handleChange} locale="es" dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput />
                                    <label htmlFor="fecha_fir">Fecha de firma</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button type="submit" label="Filtrar" icon="pi pi-filter-slash" />
                    </div>
                </form>
            </OverlayPanel>
        </div>
    );
};
