module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            autores{
                ID
                NOMBRES
                INSTITUCION{
                    COD_INSTITUCION
                    RAZON_SOCIAL
                }
            }`;
        },

        getOne: (id) => {
            return `
            autor(id:${id}){
                ID
                NOMBRES
                INSTITUCION{
                    COD_INSTITUCION
                    RAZON_SOCIAL
                }
            }`;
        },

        create: (data) => {
            let nombreConpleto = `${data.ed_nombre} ${data.ed_patApellido}${
                data.ed_matApellido.trim() !== ""
                    ? " " + data.ed_matApellido
                    : ""
            }`;
            console.log(data.institucion)
            return `
            createAutor(autor:{
                NOMBRES: "${nombreConpleto}"
                COD_INSTITUCION: ${data.institucion.COD_INSTITUCION}
            }){
                ID
            }`;
        },
    },
};
