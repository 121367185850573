import "./DialogAddEdit.css";
import "primeflex/primeflex.css";
//import React, { useState, useEffect } from "react";
import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { DocumentView } from "../documentView/documentView";
import { CambiosQMService } from "../../../services/gestionCalidad/CambiosQMService";

const filesTypes = {
    mp3: true,
    mp4: true,
    webm: true,
    csv: true,
    docx: true,
    xlsx: true,
    pdf: true,
    png: true,
    jpeg: true,
    gif: true,
};
export const DialogView = (props) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [cambios, setCambios] = useState([]);
    const [document, setDocument] = useState({});
    const [cambioService] = useState(new CambiosQMService());

    const onHide = () => {
        props.setShowHide(0);
        setDocument({});
    };

    const onShow = async () => {
        const cambios = await cambioService.changesByIdDoc(props.selectedDocumento.id);
        setCambios(cambios);
        setDocument(props.selectedDocumento);
        setPageNumber(1);
    };

    const canSeeDocument = () => {
        if (document.type === 2 && filesTypes[document.extension]) {
            return <DocumentView documentName={document.link} typeDocument={document.extension} pageNumber={pageNumber}></DocumentView>;
        } else if (document.type === 3) {
            // eslint-disable-next-line jsx-a11y/iframe-has-title
            return <iframe title="Ingles2.0_roles" width="1140" height="541.25" frameBorder="0" allowFullScreen="true" src={document.iframe}></iframe>;
        } else {
            return (
                <div className="flex align-items-center justify-content-center">
                    documentos soportados para visualizar:&nbsp;<b>{Object.keys(filesTypes).join(", ")}</b>
                </div>
            );
        }
    };

    const isDocument = () => {
        return document.type === 2;
    };

    const getButtonLog = (cambio) => {
        let buttom = null;
        if (document.type === 2 && cambio.DOCUMENTO) {
            buttom = <Button className="p-button-rounded p-button-secondary p-button-sm" aria-label={cambio.DOCUMENTO} icon="pi pi-download" onClick={() => window.open(`${process.env.REACT_APP_UPLOAD_URL}/qualityManagement/${cambio.DOCUMENTO}`, "_black")} />;
        } else if (document.type === 3) {
            buttom = <Button className="p-button-rounded p-button-secondary p-button-sm" aria-label={cambio.DOCUMENTO} icon="pi pi-link" onClick={() => window.open(`${document.iframe}`, "_black")} />;
        }
        return buttom;
    };

    return (
        <div>
            <Dialog header="Visor de Documento" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                <TabView>
                    <TabPanel header="Información">
                        <div className="fluid">
                            <div className="field grid">
                                <div className="col-12 text-center my-1">
                                    {document.code ? <b>[{document.code}] </b> : ""}
                                    {document.name ? document.name : ""}
                                </div>
                                {isDocument() && (
                                    <div className="col-12 text-center">
                                        <Button label="Descargar" type="button" icon="pi pi-cloud-download" className="p-button-text" onClick={() => props.downloadDocument(document ? document : "")} />
                                    </div>
                                )}
                            </div>
                            <div className="field grid">
                                <div className="col-12 text-justify" dangerouslySetInnerHTML={{ __html: document.description ? document.description : "" }} />
                            </div>
                            <div className="flex justify-content-center flex-wrap">{canSeeDocument()}</div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Historial de versiones">
                        <div className="grid" style={{ borderBottom: "solid 1px gray" }}>
                            <div className="col-12 md:col-4 lg:col-1 sm:flex-nowrap font-bold text-center">Version</div>
                            <div className="col-12 md:col-4 lg:col-10 text-justify font-bold text-center">Descripcion</div>
                            <div className="col-12 md:col-4 lg:col-1 font-bold text-center">Documento</div>
                        </div>
                        {cambios.map((cambio) => (
                            <div key={"CAMBIO" + cambio.ID} className="grid" style={{ borderBottom: "solid 1px gray" }}>
                                <div className="col-12 md:col-4 lg:col-1 sm:flex-nowrap">{cambio.NOMBRE}</div>
                                <div className="col-12 md:col-4 lg:col-10 text-justify" dangerouslySetInnerHTML={{ __html: cambio.DESCRIPCION }} />
                                <div className="col-12 md:col-4 lg:col-1 text-center">{getButtonLog(cambio, document)}</div>
                            </div>
                        ))}
                    </TabPanel>
                </TabView>
                <div className="p-dialog-footer">
                    <Button label="Cancelar" type="reset" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                </div>
            </Dialog>
        </div>
    );
};
