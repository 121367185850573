import React from "react";

import "./MenubarTable.css";
import { Dropdown } from "primereact/dropdown";

export const MenubarYear = (props) => {
    const selectedYearTemplate = (option, props) => {
        if (option) {
            return (
                <div className="">
                    <div>{option}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const yearOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option}</div>
            </div>
        );
    };

    return (
        <>
            <Dropdown className="mx-2" value={props.selectedYear} options={props.years} onChange={(e) => props.onYearChange(e.value)} filter placeholder="Seleccione Año" valueTemplate={selectedYearTemplate} itemTemplate={yearOptionTemplate} />
        </>
    );
};
