module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            convenios{
                ID
                CARNET
                FECHA_FIRMA
                GRADO_ACADEMICO
                PLAZAS
                REPRESENTANTE{
                    ID
                    NOMBRE
                    CARNET
                    CARGO
                    COD_INSTITUCION
                }
            }`;
        },
        getOne: (id) => {
            return `
            convenio(id:${id}){
                RAZON_SOCIAL
                TELEFONO_INS
                DOMICILIO_INST
                REPRESENTANTES{
                    NOMBRE
                }
            }`;
        },

        create: (data) => {
            return `
            createRepresentante(representante:{
                COD_INSTITUCION: "${data.cod_empresa}"
                NOMBRE: "${data.nombre_rep}"
                CARNET: "${data.docId_rep}"
                CARGO: "${data.cargo_rep}"
            }){
                ID
            }`;
        },
    },
};
