import React from "react";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";

const LayoutLoading = (props) => {
    return (
        <div className="pt-8">
            <div className="flex justify-content-center flex-wrap mt-8">
                <div className="z-1 md:z-0 relative flex align-items-center justify-content-center p-4">
                    <i className="pi pi-spin pi-spinner text-primary mt-8 font-bold" style={{ fontSize: "4em" }}></i>
                </div>
            </div>
        </div>
    );
};

export default LayoutLoading;
