import graduationProcess from "../../constants/graduationProcess/const.graduationProcess";
import { httpGraphql } from "../../helpers/httpClient.helper";

class GraduationProcessService{
    async getAll(){
        const res = await httpGraphql.query(graduationProcess.apiUrl, graduationProcess.queries.getAll() );
        return res.data.data.allGetGraduates;
    }
}

export {GraduationProcessService}