import React, { useState, useEffect } from "react";
import "primeflex/primeflex.css";
import { DataViewFile } from "./dataView/DataViewFile";
import { TreeDocuments } from "./tree/TreeDocuments";
import { BreadCrumbFile } from "./breadcrumb/BreadCrumbFile";
import { DocumentoService } from "../../services/documentosBI/DocumentoService";
import { Splitter, SplitterPanel } from "primereact/splitter";
import "./DocumentosList.css";
//import { BlockUI } from "primereact/blockui";
//import { DialogTermsConditions } from "./dialogs/DialogTermsConditions";

export const DocumentosList = (props) => {
    const direction = {
        UP: -1,
        DOWN: 1,
    };
    const [documentsDataView, setDocumentsDataView] = useState(null);
    const [documentoServices] = useState(new DocumentoService());
    const [currentDirectory, setCurrentDirectory] = useState(null);
    const [itemsBreadCrumb, setItemsBreadCrumb] = useState([]);

    const [expandedKeys, setExpandedKeys] = useState({});
    const [nodes, setNodes] = useState(null);

    const [blockedPanel] = useState(false);
    //const [blockedPanel, setBlockedPanel] = useState(false);
    /*const blockPanel = () => {
        setBlockedPanel(true);
    };

    const unblockPanel = () => {
        setBlockedPanel(false);
    };*/

    useEffect(() => {
        documentoServices.getInitialDocumentsView().then((data) => setDocumentsDataView(data));
        documentoServices.getInitialTreeDocuments().then((data) => setNodes(data));
    }, [documentoServices]);

    const setDocuments = async (id) => {
        let documents = null;
        if (id) {
            let currentDir = await documentoServices.getOneDocumentViewById(id);
            setCurrentDirectory(currentDir);
            loadItemsBreadCrumb(id);
            documents = await documentoServices.getDocumentsViewById(id);
        } else {
            setCurrentDirectory(null);
            loadItemsBreadCrumb(null);
            documents = await documentoServices.getInitialDocumentsView();
        }
        setDocumentsDataView(documents);
        resetNodesTree();
    };

    const comandDefault = (event) => {
        setDocuments(event.item.key);
    };

    const loadItemsBreadCrumb = async (id) => {
        if (id) {
            let data = await documentoServices.getTreeDocumentByID(id);
            data.command = comandDefault;
            const newItems = [];
            newItems.unshift(data);
            while (data.parent) {
                data = await documentoServices.getTreeDocumentByID(data.parent);
                data.command = comandDefault;
                newItems.unshift(data);
            }
            setItemsBreadCrumb(newItems);
        } else {
            setItemsBreadCrumb([]);
        }
    };

    const movePosition = async (id, direction) => {
        const items = documentsDataView;

        const position = items.findIndex((i) => i.id === id);
        if (position < 0) {
            throw new Error("Given item not found.");
        } else if ((direction === direction.UP && position === 0) || (direction === direction.DOWN && position === items.length - 1)) {
            return; // control para que no se puede mover fuera de la matriz
        }

        const item = items[position]; // guardar el elemento para más tarde
        const newItems = items.filter((i) => i.id !== id); // eliminar elemento de la matriz
        newItems.splice(position + direction, 0, item);

        await documentoServices.updateIndex(newItems);
        await setDocuments(currentDirectory && currentDirectory.id > 0 ? currentDirectory.id : null);
    };

    const deleteDocument = async (document) => {
        await documentoServices.delete(document.id);
        resetNodesTree();
    };

    const resetNodesTree = () => {
        documentoServices.getInitialTreeDocuments().then((data) => setNodes(data));
        setExpandedKeys({});
    };

    return (
        <>
            {/* <DialogTermsConditions blockPanel={blockPanel} unblockPanel={unblockPanel}></DialogTermsConditions>
            <BlockUI blocked={blockedPanel}> */}
                <div className="grid" style={{ display: blockedPanel ? "none" : "block" }}>
                    <div className="col-12">
                        <BreadCrumbFile items={itemsBreadCrumb} />
                    </div>
                    <Splitter className="col-12">
                        <SplitterPanel size={20}>
                            <TreeDocuments setDocuments={setDocuments} expandedKeys={expandedKeys} setExpandedKeys={setExpandedKeys} nodes={nodes} setNodes={setNodes} />
                        </SplitterPanel>
                        <SplitterPanel size={80}>
                            <DataViewFile documents={documentsDataView} setDocuments={setDocuments} movePosition={movePosition} direction={direction} currentDirectory={currentDirectory} deleteDocument={deleteDocument} />
                        </SplitterPanel>
                    </Splitter>
                </div>
            {/* </BlockUI> */}
        </>
    );
};
