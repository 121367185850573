import lostObjects from "../../constants/lostObjects/const.lostObjects";
import { httpGraphql } from "../../helpers/httpClient.helper";

export class LostObjectsService {
    async create(datas) {
        datas.LUGAR = datas.LUGAR.name!=='' ? datas.LUGAR.name : 'COCHABAMBA';
        const res = await httpGraphql.mutation(lostObjects.apiUrl, lostObjects.mutations.create(datas));
        return res.data.data.createLostObject;
    }
    async getAll() { 
        const res = await httpGraphql.query(lostObjects.apiUrl, lostObjects.queries.getAll());
        return res.data.data.allLostObjects;
    }
    async getAllByID() { 
        const res = await httpGraphql.query(lostObjects.apiUrl, lostObjects.queries.getAllByID());
        return res.data.data.allLostObjectsByID;
    }
    async delete(id) {
        const res = await httpGraphql.mutation(lostObjects.apiUrl, lostObjects.mutations.delete(id));
        let response = res.data.data.deleteLostObject;
        return response;
    }
    async update(id, data) {
        if( data.RUTA==='' ){delete data.RUTA;}
        data.LUGAR = data.LUGAR.name!=='' ? data.LUGAR.name : 'COCHABAMBA';
        const res = await httpGraphql.mutation(lostObjects.apiUrl, lostObjects.mutations.update(id, data));
        let response = res.data.data.updateLostObjects;
        return response;
    }
}