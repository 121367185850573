import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "../../components/Dashboard";
import { EmptyPage } from "../../pages/EmptyPage";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "../../assets/layout/flags/flags.css";
import "../../assets/layout/layout.scss";
import "../../App.scss";
import { Convenios } from "../../pages/Convenios";
import { Investigadores } from "../../pages/Investigadores";
import { Publicaciones } from "../../pages/Publicaciones";
import { Empresas } from "../../pages/Empresas";
import { FormacionDocente } from "../../pages/FormacionDocente";
import { GestionCalidad } from "../../pages/GestionCalidad";
import { DocumentosBI } from "../../pages/DocumentosBI";
import { SmartCertificate } from "../../pages/SmartCertificate";
import { LostObject } from "../../pages/LostObject";
import { GraduationProcess } from "../../pages/GraduationProcess";

const WithoutLayout = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/convenios" element={<Convenios />} />
                <Route path="/investigadores" element={<Investigadores />} />
                <Route path="/publicaciones" element={<Publicaciones />} />
                <Route path="/empresas" element={<Empresas />} />
                <Route path="/formacionDocente" element={<FormacionDocente />} />
                <Route path="/qualityManagement" element={<GestionCalidad />} />
                <Route path="/documentsBI" element={<DocumentosBI />} />
                <Route path="/smartCertificate" element={<SmartCertificate />} />
                <Route path="/empty" element={<EmptyPage />} />
                <Route path="/lostObject" element={<LostObject />} />
                <Route path="/graduationProcess" element={<GraduationProcess />} />
            </Routes>
        </React.Fragment>
    );
};

export default WithoutLayout;
