import documentos from "../../constants/documentosBI/const.cambios";
import { httpGraphql } from "../../helpers/httpClient.helper";

export class CambiosBIService {
    async changesByIdDoc(idDoc) {
        const res = await httpGraphql.query(documentos.apiUrl, documentos.queries.changesByIdDoc(idDoc));
        let data = res.data.data.cambioBIByDocId;
        return data;
    }
}
