import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoginService } from "../../services/LoginService";
import { useNavigate } from "react-router-dom";
import { delToken, setToken } from "../../helpers/token.helper";
import { getMainPathame, setExpiration, setPerson } from "../../helpers/session.helper";

const FormLogin = (props) => {
    let navigate = useNavigate();
    const toast = useRef(null);
    const showToastError = () => {
        toast.current.show({ severity: "error", summary: "Mensaje de Error", detail: "Verifique sus datos de acceso!!", life: 3000 });
    };
    const showToastLogged = () => {
        toast.current.show({ severity: "success", summary: "Correcto!!", detail: "Bienvenido!!", life: 3000 });
    };
    const [scale] = useState(12);
    const [loginService] = useState(new LoginService());
    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Usuario es requerido!!"),
            password: Yup.string().required("Contraseña requerida!!"),
        }),
        validate: (data) => {
            //console.log(data);
        },
        onSubmit: async (data) => {
            const login = await loginService.login(data);
            delToken();
            if (login && login.token) {
                showToastLogged();
                setToken(login.token);
                setPerson(login.persona);
                setExpiration(parseInt(login.payload.exp) * 1000);
                props.setHaveAccess(true);
                navigate(getMainPathame(), { replace: true });
            } else {
                formik.resetForm();
                showToastError();
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        document.documentElement.style.fontSize = scale + "px";
    }, [scale]);

    return (
        <div className="pt-8">
            <Toast ref={toast} />
            <div className="flex justify-content-center flex-wrap mt-8">
                <div className="flex align-items-center justify-content-center h-25rem mt-8">
                    <Card className="w-12 p-2">
                        <div className="grid mb-3">
                            <img className="col-12 sm:col-12 md:col-12 lg:col-3" alt="Logo" src="assets/layout/images/logo-upbnet.png" />
                            <div className="col-12 sm:col-12 md:col-12 lg:col-9 text-center">
                                <div className="my-0 font-bold text-2xl">Universidad Privada de Bolivia</div>
                                <div className="my-0 text-lg">Iniciar sesión</div>
                            </div>
                        </div>
                        <form className="p-fluid" onSubmit={formik.handleSubmit}>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <i className="pi pi-user" />
                                    <InputText id="username" name="username" value={formik.values.username} autoComplete="off" onChange={formik.handleChange} />
                                    <label htmlFor="username">Usuario*</label>
                                </span>
                                {getFormErrorMessage("username")}
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label">
                                    <Password id="password" name="password" value={formik.values.password} toggleMask feedback={false} autoComplete="off" onChange={formik.handleChange} />
                                    <label htmlFor="password">Contraseña*</label>
                                </span>
                                {getFormErrorMessage("password")}
                            </div>
                            <Button icon="pi pi-sign-in" iconPos="right" type="submit" label="Ingresar" />
                        </form>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default FormLogin;
