import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { closeSesion } from "../../helpers/system.helper";
import { getPerson } from "../../helpers/session.helper";
import { Chip } from "primereact/chip";
/* import { SelectButton } from "primereact/selectbutton"; */
import { Tooltip } from "primereact/tooltip";

export const AppTopbar = (props) => {
    /* const [valueModulo, setValueModulo] = useState(null); */
    /* const modulos = [
        {
            COD: "1",
            LABEL: "Sistema Gestion de Calidad",
            ICON: "pi pi-briefcase",
            TO: "",
            BADGE: "",
            URL: "",
            COMMAND: "",
            DISABLED: "",
            ITEMS: "",
            TARGET: "",
        },
        {
            COD: "2",
            LABEL: "Docente Pregrado",
            ICON: "pi pi-chart-pie",
            TO: "",
            BADGE: "",
            URL: "",
            COMMAND: "",
            DISABLED: "",
            ITEMS: "",
            TARGET: "",
        },
        {
            COD: "3",
            LABEL: "Sistema Administrativo Financiero",
            ICON: "pi pi-building",
            TO: "",
            BADGE: "",
            URL: "",
            COMMAND: "",
            DISABLED: "",
            ITEMS: "",
            TARGET: "",
        },
        {
            COD: "4",
            LABEL: "Sistema",
            ICON: "pi pi-cog",
            TO: "",
            BADGE: "",
            URL: "",
            COMMAND: "",
            DISABLED: "",
            ITEMS: "",
            TARGET: "",
        },
    ]; */
    /* const itemTemplate = (option) => {
        console.log(option);
        return (
            <div className="labelForModule" data-pr-tooltip={option.LABEL} data-pr-position="bottom">
                <i className={`${option.ICON} text-5xl`} />
            </div>
        );
    }; */
    return (
        <div className="layout-topbar">
            <Tooltip target=".labelForModule" mouseTrack mouseTrackLeft={10} />
            <Link to="/" className="layout-topbar-logo">
                <img src={"assets/layout/images/logo-upbnet.png"} alt="logo" />
                {/*
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo"/>
                */}
                <span></span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            {/* <SelectButton optionValue="COD" value={valueModulo} options={modulos} onChange={(e) => setValueModulo(e.value)} itemTemplate={itemTemplate} multiple /> */}

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li>
                    <div className="inline-flex align-items-center h-3rem">
                        <Chip className="capitalize" label={getPerson()} icon="pi pi-user" />
                    </div>
                </li>
                {/* <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-calendar" />
                        <span>Events</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-cog" />
                        <span>Settings</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-user" />
                        <span>Perfil</span>
                    </button>
                </li>
                */}
                <li>
                    <button className="p-link layout-topbar-button" onClick={closeSesion}>
                        <i className="pi pi-fw pi-sign-out" />
                        <span>Salir</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
