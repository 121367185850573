//import { useNavigate } from "react-router-dom";
import { getLayout } from "./session.helper";
import { delToken } from "./token.helper";

const closeSesion = () => {
    delToken();
    window.location = window.location.protocol + "//" + window.location.host + "/login";
};

const isAppWeb = () => {
    return getLayout() === "none";
};

const base_url = () => {
    const getUrl = window.location;
    return getUrl.protocol + "//" + getUrl.host;
};

export { closeSesion, isAppWeb, base_url };
