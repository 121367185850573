import React,{useState} from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { ListLostObjects } from './ListLostObjects';

export const TabLostObjects = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showOptions, setShowOptions] = useState(0);

    const handleChangeTab = (event) => {
        const tabIndex = event.index
        setShowOptions( tabIndex );
        //Asignamos el indice
        setActiveIndex(tabIndex);        
      };
    
    return (
        <div className="tabview-demo">
            <div className="card">
                <h5>Objetos Perdidos</h5>
                <TabView activeIndex={activeIndex} onTabChange={handleChangeTab} >
                    <TabPanel header="Lista de Objetos Perdidos" >
                        <ListLostObjects showOptions={showOptions} />
                    </TabPanel>
                    <TabPanel header="Mis Objetos Perdidos" >
                        <ListLostObjects showOptions={showOptions} />
                    </TabPanel>
                    <TabPanel header="Objetos Devueltos" >
                        <ListLostObjects showOptions={showOptions} />
                    </TabPanel>
                </TabView>
            </div>

        </div>
    );
}