import React, { useRef } from "react";
import  { Toast } from "primereact/toast";
import { TabLostObjects } from "../components/lostObjects/menubarTable/TabLostObject";

export const LostObject = () => {
    const toast = useRef(null);
    
    return (
        <div className="card">
            <Toast ref={toast}></Toast>
            <TabLostObjects />
        </div>
    )
};
