module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getFilenameCertificate: (idCert) => {
            return ` tmpDiploma(idEmit:"${idCert}") `;
        },
        getDiplomaSmartCertificate: (idCert) => {
            return `
            getDiplomaSmartCertificate(idEmit:"${idCert}")
            {
                ID
                BLOCKCHAIN_DIPL
                CEMIT_CEMID
                SMART_PROGID
                REQUESTID
                DESCARGAURL
                IMAGENURL
                PAGINAURL
                RECIBOURL
                FECHAREG
            } `;
        },
    },
    mutations: {
        certifyBySmartCertificate: (idCert, filename) => {
            return `
            certifyBySmartCertificate(idEmit:"${idCert}", filename:"${filename}")
            {
                ID
                BLOCKCHAIN_DIPL
                CEMIT_CEMID
                SMART_PROGID
                REQUESTID
                DESCARGAURL
                IMAGENURL
                PAGINAURL
                RECIBOURL
                FECHAREG
            } `;
        },
        decertifyBySmartCertificate: (idCert) => {
            return ` decertifyBySmartCertificate(idEmit:"${idCert}") `;
        },
    },
};
