import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";

import calConfig from "../../../constants/calendar.config";
import { IndexacionService } from "../../../services/publicaciones/IndexacionService";

export const DialogAddIndexacion = (props) => {
    const [indexacionService] = useState(new IndexacionService());

    const formik = useFormik({
        initialValues: {
            ed_nombre: "",
        },
        validationSchema: Yup.object({
            ed_nombre: Yup.string().required("Escriba un nombre!!"),
        }),
        onSubmit: async (data) => {
            await indexacionService.insertIndexacion(data);
            props.setShowHide(false);
            props.loadIndexaciones();
        },
    });

    addLocale(calConfig.sp.name, calConfig.sp.config);

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <div>
            <Dialog
                header="Agregar Indexacion"
                visible={props.showHide}
                style={{ width: "50vw" }}
                onHide={() => props.setShowHide(false)}
                onShow={() => props.setShowHide(true)}
            >
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="card">
                        <div className="p-fluid">
                            <div className="p-field p-grid">
                                <label
                                    htmlFor="ed_nombre"
                                    className="p-col-12 p-md-2"
                                >
                                    Nombre Indexacion:
                                </label>
                                <div className="p-col-12 p-md-10">
                                    <InputText
                                        id="ed_nombre"
                                        name="ed_nombre"
                                        type="text"
                                        value={formik.values.ed_nombre}
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage("ed_nombre")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button
                            type="reset"
                            label="Cancelar"
                            icon="pi pi-times"
                            onClick={() => props.setShowHide(false)}
                            className="p-button-text"
                        />
                        <Button
                            type="submit"
                            label="Guardar"
                            icon="pi pi-save"
                            onClick={() => props.setShowHide(false)}
                            autoFocus
                        />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
