import axios from "axios";
import { getToken } from "../../helpers/token.helper";
import curso from "../../constants/menu/menu";

export class MenuService {
    NUM_LEVELS = 5;
    async maxLevelMenu() {
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `query{${curso.queries.maxLevelMenu()}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.maxLevelMenu;
    }
    async menusSession(levels) {
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `query{${curso.queries.menusSession(levels)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.menusSession;
    }
}
