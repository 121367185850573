import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { MenubarTable } from "../components/convenios/menubarTable/MenubarTable";
import { DialogAddEdit } from "../components/convenios/dialogs/DialogAddEdit";
import { DialogStudents } from "../components/convenios/dialogs/DialogStudents";
import { ConvenioService } from "../services/convenios/ConvenioService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../helpers/date.helper";
import { FilterConvenios } from "../components/convenios/filters/FilterConvenios.js";

export const Convenios = (props) => {
    const [convenioService] = useState(new ConvenioService());
    const [convenios, setConvenios] = useState([]);
    const [dialogAddEditState, setDialogAddEditState] = useState(0);
    const [dialogStudentsState, setDialogStudentsState] = useState(0);
    const [selectedConvenio, setSelectedConvenio] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const tipoConvenio = {
        PRACTICA: "Prácticas",
        PASANTIA: "Pasantías",
        INTER_PREG: "Intercambio pregrado",
        INTER_POSG: "Intercambio posgrado",
        PROY_INVEST: "Proyectos de investigación",
        TITUL_POSG: "Convenios de programas de doble titulación-posgrado",
        TITUL_PREG: "Convenios de programas de doble titulación-Pregrado",
        OTHER: "Otros",
        PROG_INST: "Programas de colaboración institucional",
    };

    useEffect(() => {
        convenioService.getAll().then((data) => setConvenios(data));
    }, [convenioService]);

    const accept = () => {
        convenioService.delete(selectedConvenio.ID).then((data) => {
            toast.current.show({
                severity: "info",
                summary: "Confirmado",
                detail: "Has aceptado!!",
                life: 3000,
            });
            convenioService.getAll().then((data) => setConvenios(data));
        });
    };

    const deleteConvenio = () => {
        confirmDialog({
            message: "¿Quieres borrar este registro?",
            header: "Eliminar confirmación",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
        });
    };

    const loadDataTable = () => {
        convenioService.getAll().then((data) => {
            console.log(data);
            setConvenios(data);
        });
        setSelectedConvenio(null);
    };

    const lnkDocumentTemplate = (rowData) => {
        return (
            <React.Fragment>
                <a rel="noopener noreferrer" href={`${process.env.REACT_APP_UPLOAD_URL}/convenios/${rowData.CARNET}`} target="_blank">
                    <FontAwesomeIcon icon={faFileDownload} style={{ fontSize: "2em", color: "var(--teal-500)" }} />
                </a>
            </React.Fragment>
        );
    };

    const asignadosTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.ESTUDIANTES && rowData.ESTUDIANTES.length ? rowData.ESTUDIANTES.length : 0}/{rowData.PLAZAS}
            </React.Fragment>
        );
    };

    const tipoConvenioTemplate = (rowData) => {
        return <React.Fragment>{tipoConvenio[rowData.GRADO_ACADEMICO]}</React.Fragment>;
    };

    const dateBodyTemplate = (rowData) => {
        let fFirma = new Date(parseInt(rowData.FECHA_FIRMA));
        return formatDate(fFirma);
    };

    //----------------- EXPORT FILES -----------------
    const exportCSV = (selectionOnly) => {
        console.log(selectionOnly);
        dt.current.exportCSV({ selectionOnly });
    };

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            let conveniosXlS = convenios.map((rowData) => {
                const newRow = {
                    "Nombre de empresa": rowData.INSTITUCION.RAZON_SOCIAL,
                    "Número de plazas": rowData.PLAZAS,
                    "Número de Colaboradores": rowData.ESTUDIANTES.length,
                    "Fecha firma": new Date(parseInt(rowData.FECHA_FIRMA)),
                    "Enlace al convenio": `${process.env.REACT_APP_UPLOAD_URL}/convenios/${rowData.CARNET}`,
                };
                return newRow;
            });
            const worksheet = xlsx.utils.json_to_sheet(conveniosXlS);
            /* INI - Creacion del hipervinculo */
            for (let i = 1; i <= convenios.length; i++) {
                worksheet[`E${i + 1}`].l = {
                    Target: worksheet[`E${i + 1}`].v,
                    Tooltip: "Enlace al documento",
                };
            }
            /* FIN - Creacion del hipervinculo */
            const workbook = {
                Sheets: {
                    Convenios: worksheet,
                },
                SheetNames: ["Convenios"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "convenios");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        });
    };

    const exportPdf = () => {
        let rows = convenios.map((rowData) => {
            const newRow = {
                INSTITUCION: rowData.INSTITUCION.RAZON_SOCIAL,
                FECHA_FIRMA: formatDate(new Date(parseInt(rowData.FECHA_FIRMA))),
                CANT_PLAZAS: rowData.PLAZAS,
                CANT_COLABS: rowData.ESTUDIANTES.length,
                LINK: `${process.env.REACT_APP_UPLOAD_URL}/convenios/${rowData.CARNET}`,
            };
            return newRow;
        });
        const cols = [
            { field: "INSTITUCION", header: "Nombre de empresa" },
            { field: "FECHA_FIRMA", header: "Fecha firma" },
            { field: "CANT_PLAZAS", header: "Número de plazas" },
            { field: "CANT_COLABS", header: "Número de Colaboradores" },
            { field: "LINK", header: "Enlace al convenio" },
        ];

        const exportColumns = cols.map((col) => ({
            title: col.header,
            dataKey: col.field,
        }));
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.text("Lista de Convenios", 20, 30);
                doc.autoTable({
                    theme: "grid",
                    columns: exportColumns,
                    columnStyles: {
                        0: { cellWidth: 66.7793333333 },
                        1: { cellWidth: 25 },
                        2: { cellWidth: 25 },
                        3: { cellWidth: 25 },
                        4: { cellWidth: 40 },
                    },
                    body: rows,
                    margin: { top: 35 },
                    didDrawCell: function (data) {
                        if (data.column.dataKey === "LINK" && data.cell.section === "body") {
                            var textPos = data.cell;
                            const objLnk = {
                                url: data.cell.raw,
                            };
                            doc.textWithLink("documento digital", textPos.x + 2, textPos.y + 5, objLnk);
                        }
                    },
                    willDrawCell: function (data) {
                        if (data.column.dataKey === "LINK" && data.cell.section === "body") {
                            data.cell.text = [""];
                        }
                    },
                });
                doc.save("convenios.pdf");
            });
        });
    };

    const exportDataTable = (type = 1) => {
        switch (type) {
            case 1:
                exportPdf();
                break;
            case 2:
                exportExcel();
                break;
            case 3:
                exportCSV();
                break;
            default:
                break;
        }
    };
    //------------------------------------------------
    const inputSearch = (
        <div>
            <FilterConvenios setConvenios={setConvenios} />
        </div>
    );
    return (
        <div>
            <div className="card">
                <Toast ref={toast} />
                <DialogAddEdit setShowHide={setDialogAddEditState} showHide={dialogAddEditState} loadDataTable={loadDataTable} selectedConvenio={selectedConvenio}></DialogAddEdit>
                <DialogStudents setShowHide={setDialogStudentsState} showHide={dialogStudentsState} loadDataTable={loadDataTable} selectedConvenio={selectedConvenio}></DialogStudents>
                <MenubarTable dialogAddEdit={setDialogAddEditState} dialogStudents={setDialogStudentsState} dialogDelete={deleteConvenio} inputSearch={inputSearch} selectedConvenio={selectedConvenio} setSelectedConvenio={setSelectedConvenio} exportDataTable={exportDataTable}></MenubarTable>
                <DataTable
                    className="p-datatable-customers"
                    responsiveLayout="scroll"
                    emptyMessage="No customers found."
                    ref={dt}
                    value={convenios}
                    sortField="FECHA_FIRMA"
                    sortOrder={-1}
                    paginator
                    showGridlines
                    rows={10}
                    selectionMode="single"
                    metaKeySelection={false}
                    dataKey="ID"
                    selection={selectedConvenio}
                    onSelectionChange={(e) => setSelectedConvenio(e.value)}
                >
                    <Column field="FECHA_FIRMA" header="Fecha firma" body={dateBodyTemplate} sortable></Column>
                    <Column field="INSTITUCION.RAZON_SOCIAL" header="Empresa" sortable></Column>
                    <Column field="GRADO_ACADEMICO" body={tipoConvenioTemplate} header="Tipo Convenio" sortable></Column>
                    <Column field="REPRESENTANTE.NOMBRE" header="Representante" sortable style={{ minWidth: "12rem" }}></Column>
                    <Column field="ESTUDIANTES.length" header="Cant. Asignados/Plazas" sortable body={asignadosTemplate}></Column>
                    <Column field="CARNET" header="Documento" sortable body={lnkDocumentTemplate}></Column>
                </DataTable>
            </div>
        </div>
    );
};
