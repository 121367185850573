import { capitalize } from "./text.helper";

const nameVarLayout = "ly";
const nameVarMainPathame = "/";
const nameVarPerson = "person";
const nameVarExp = "expiration";

const setLayout = (newLayout) => {
    return sessionStorage.setItem(nameVarLayout, newLayout);
};

const getLayout = () => {
    return sessionStorage.getItem(nameVarLayout);
};

const delLayout = () => {
    return sessionStorage.removeItem(nameVarLayout);
};

const setMainPathame = (newMainPathame) => {
    return sessionStorage.setItem(nameVarMainPathame, newMainPathame);
};

const getMainPathame = () => {
    const mainPathame = sessionStorage.getItem(nameVarMainPathame);
    return mainPathame ? mainPathame : nameVarMainPathame;
};

const delMainPathame = () => {
    return sessionStorage.removeItem(nameVarMainPathame);
};

const setExpiration = (expiration) => {
    return sessionStorage.setItem(nameVarExp, Number(expiration));
};

const getExpiration = () => {
    const Expiration = sessionStorage.getItem(nameVarExp);
    return Expiration ? Expiration : 0;
};

const delExpiration = () => {
    return sessionStorage.removeItem(nameVarExp);
};

const setPerson = (newPerson) => {
    return sessionStorage.setItem(nameVarPerson, JSON.stringify(newPerson));
};

const getPerson = () => {
    const personStr = sessionStorage.getItem(nameVarPerson);
    const person = personStr ? JSON.parse(personStr) : null;
    return person && capitalize(`${person.NOMBRE} ${person.AP_PATERNO} ${person.AP_MATERNO != null ? person.AP_MATERNO : ""}`);
};

const getPersonName = () => {
    const personStr = sessionStorage.getItem(nameVarPerson);
    const person = personStr ? JSON.parse(personStr) : null;
    return person && capitalize(`${person.NOMBRE}`);
};

const delPerson = () => {
    return sessionStorage.removeItem(nameVarPerson);
};
export { getLayout, setLayout, delLayout, setMainPathame, getMainPathame, delMainPathame, getPerson, getPersonName, setPerson, delPerson, setExpiration, getExpiration, delExpiration };
