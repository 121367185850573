import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { MenubarTable } from "../components/empresas/menubarTable/MenubarTable.js";
import { DialogAddEdit } from "../components/empresas/dialogs/DialogAddEdit";
import { InstitucionService } from "../services/empresas/InstitucionService";

export const Empresas = (props) => {
    const [institucionService] = useState(new InstitucionService());
    const [instituciones, setInstituciones] = useState([]);
    const [dialogAddEditState, setDialogAddEditState] = useState(0);
    const [selectedInstitucion, setSelectedInstitucion] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    //const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");

    useEffect(() => {
        institucionService.getInstituciones().then((data) => setInstituciones(data));
    }, [institucionService]);

    /************************************************ */
    const cols = [
        { field: "INSTITUCION.RAZON_SOCIAL", header: "Empresa" },
        { field: "FECHA_FIRMA", header: "Fecha firma" },
        { field: "GRADO_ACADEMICO", header: "Grado academico" },
        { field: "REPRESENTANTE.NOMBRE", header: "Representante" },
        { field: "quantity", header: "Cant. asignados/Plazas" },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));
    /************************************************ */

    const accept = () => {
        institucionService.delete(selectedInstitucion.COD_INSTITUCION).then((data) => {
            toast.current.show({
                severity: "info",
                summary: "Confirmado",
                detail: "Has aceptado!!",
                life: 3000,
            });
            institucionService.getAll().then((data) => setInstituciones(data));
        });
    };

    const deleteConvenio = () => {
        confirmDialog({
            message: "¿Quieres borrar este registro?",
            header: "Eliminar confirmación",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
        });
    };

    const loadDataTable = () => {
        institucionService.getAll().then((data) => {
            console.log(data);
            setInstituciones(data);
        });
        setSelectedInstitucion(null);
    };

    //----------------- EXPORT FILES -----------------
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(instituciones);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "convenios");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        });
    };

    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, instituciones);
                doc.save("convenios.pdf");
            });
        });
    };

    const exportDataTable = (type = 1) => {
        switch (type) {
            case 1:
                exportPdf();
                break;
            case 2:
                exportExcel();
                break;
            case 3:
                exportCSV();
                break;
            default:
                break;
        }
    };
    //------------------------------------------------

    const onGlobalFilterChange1 = (e) => {
        console.log(e.target);
        const value = e.target.value;
        setGlobalFilterValue1(value);
    };

    const inputSearch = (
        <div>
            <span className="p-input-icon-left p-d-sm-none p-d-md-none p-d-lg-inline">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Buscar" />
            </span>
        </div>
    );

    return (
        <div>
            <div className="card">
                <Toast ref={toast} />
                <DialogAddEdit setShowHide={setDialogAddEditState} showHide={dialogAddEditState} loadDataTable={loadDataTable} selectedConvenio={selectedInstitucion}></DialogAddEdit>
                <MenubarTable dialogAddEdit={setDialogAddEditState} dialogDelete={deleteConvenio} inputSearch={inputSearch} selectedConvenio={selectedInstitucion} setSelectedConvenio={setSelectedInstitucion} exportDataTable={exportDataTable}></MenubarTable>
                <DataTable
                    filterLocale="es"
                    className="p-datatable-customers"
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    emptyMessage="No existe datos."
                    ref={dt}
                    value={instituciones}
                    sortField="RAZON_SOCIAL"
                    sortOrder={1}
                    paginator
                    showGridlines
                    rows={10}
                    globalFilter={globalFilterValue1}
                    selectionMode="single"
                    metaKeySelection={false}
                    dataKey="COD_INSTITUCION"
                    selection={selectedInstitucion}
                    onSelectionChange={(e) => setSelectedInstitucion(e.value)}
                >
                    <Column field="RAZON_SOCIAL" header="Razon Social" sortable></Column>
                    <Column field="TELEFONO_INS" header="Telefono" sortable></Column>
                    <Column field="FAX_INST" header="Fax" sortable></Column>
                    <Column field="EMAIL_INST" header="Mail" sortable></Column>
                    <Column field="URL_INST" header="Pagina" sortable></Column>
                    <Column field="DOMICILIO_INST" header="Domicilio" sortable></Column>
                </DataTable>
            </div>
        </div>
    );
};
