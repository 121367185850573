import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./DialogAll.css";
import "primeflex/primeflex.css";

import { CursoService } from "../../../services/teachertraining/CursoService";
import { formatDate } from "../../../helpers/date.helper";
//import { isRegistrable } from "../../../helpers/formacionDoc.helper";
import { useFormik } from "formik";
import { Badge } from "primereact/badge";

export const DialogToInformation = (props) => {
    const [cursoService] = useState(new CursoService());
    const [curso, setCurso] = useState(null);

    const formik = useFormik({
        initialValues: {
            idCurso: 0,
        },
        //validationSchema: Yup.object({}),
        onSubmit: async (data) => {
            data.idCurso = props.selectedIdData;
            console.log(data);
            await cursoService.desuscribirse(data);

            formik.resetForm();
            onHide();
            props.loadAllData();
        },
    });

    const onHide = () => {
        formik.resetForm();
        setCurso(null);
        props.setShowHide(0);
    };

    const onShow = async () => {
        const actCurso = await cursoService.getCurso(props.selectedIdData);
        setCurso(actCurso);
    };

    return (
        <div>
            <Dialog header="Información" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                {curso && (
                    <div className="p-fluid">
                        <div className="card">
                            <div>
                                <div className="grid">
                                    <div className="col-12">
                                        <h1>
                                            <FontAwesomeIcon icon={faChalkboardTeacher} />
                                            &nbsp;{curso.NOMBRE} [{curso.NRO_HORAS} hrs]
                                            <Chip label={props.tipoCurso[curso.TIPO]} icon="pi pi-check " className="mr-2 mb-2 custom-chip" />
                                        </h1>
                                        <div className="text-lg text-primary col-12 md:col-2">Objetivo</div>
                                        <p className="parrafo col-12 md:col-10">{curso.OBJETIVO}</p>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Capacitadores</div>
                                    <div className="col-12 md:col-10">
                                        <ul className="my-0">
                                            {curso.CAPACITADORES.map((item) => {
                                                return (
                                                    <li>
                                                        {item.NOMBRE} {item.AP_PATERNO} {item.AP_MATERNO}
                                                        &nbsp;<Badge value={item.TIPO === "INTERNO" ? "UPB" : item.TIPO} severity="success"></Badge>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Fecha(s)</div>
                                    <div className="col-12 md:col-10">
                                        del del {formatDate(new Date(parseInt(curso.FECHAINI)))} al {formatDate(new Date(parseInt(curso.FECHAFIN)))}
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Horarios</div>
                                    <div className="col-12 md:col-10">
                                        de {curso.HORAINI} a {curso.HORAFIN} hrs
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Cupo</div>
                                    <div className="col-12 md:col-10">{curso.CUPO} Pers.</div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Cupo disponible</div>
                                    <div className="col-12 md:col-10">{curso.CUPO - curso.NUM_SUSCRITOS} Pers.</div>
                                </div>
                                <h5>Datos Zoom</h5>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">URL</div>
                                    <div className="col-12 md:col-10">
                                        <a rel="noopener noreferrer" href={curso.URLZOOM} target="_blank">{curso.URLZOOM}</a>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Id</div>
                                    <div className="col-12 md:col-10">{curso.IDZOOM}</div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Contraseña</div>
                                    <div className="col-12 md:col-10">{curso.PWDZOOM}</div>
                                </div>
                                {curso.AULA && (
                                    <div>
                                        <h5>Lugar para la Capacitación</h5>
                                        <div className="grid">
                                            <div className="text-lg text-primary col-12 md:col-2">Aula</div>
                                            <div className="col-12 md:col-10">{curso.AULA.NOMBRE}</div>
                                        </div>
                                        <div className="grid">
                                            <div className="text-lg text-primary col-12 md:col-2">Ubicación</div>
                                            <div className="col-12 md:col-10">{curso.AULA.UBICACION}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-dialog-footer">
                            <Button label="Cerrar" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                            {/* isRegistrable(curso) && (
                                <form onSubmit={formik.handleSubmit} className="inline">
                                    <input name="idCurso" type="hidden" />
                                    <Button type="submit" label="Cancelar Suscripción" className="p-button-danger ml-2" icon="pi pi-save" />
                                </form>
                            ) */}
                        </div>
                    </div>
                )}
            </Dialog>
        </div>
    );
};
