import axios from "axios";
import curso from "../../constants/teachertraining/curso";
import { httpGraphql } from "../../helpers/httpClient.helper";
import { getToken } from "../../helpers/token.helper";

export class CursoService {
    async getCursos() {
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `query{${curso.queries.getCursos()}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.cursos_ofertas;
    }

    async getSuscritos(gestion) {
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `query{${curso.queries.getSuscritos(gestion)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.cursos_suscritos;
    }

    async getPasados(year) {
        const res = await httpGraphql.query(curso.apiUrl, curso.queries.getPasados(year));
        return res.data.data.cursos_pasados;
    }

    async getCurso(id) {
        //console.log(data);
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `query{${curso.queries.getCurso(id)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.curso;
    }

    async suscribirse(data) {
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `mutation{${curso.queries.suscribirse(data)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.suscribirseCurso;
    }

    async desuscribirse(data) {
        console.log(data);
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `mutation{${curso.queries.desuscribirse(data)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.desuscribirseCurso;
    }

    async getGestiones() {
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `query{${curso.queries.getGestiones()}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.cursoGestiones;
    }

    async getYears() {
        const res = await httpGraphql.query(curso.apiUrl, curso.queries.getYears());
        return res.data.data.cursoYears;
    }

    async getEncriptacionCurso(idCurso) {
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `query{${curso.queries.getEncriptacionCurso(idCurso)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );

        return res.data.data.encriptCertCurso;
    }

    async getEncriptacionGestion(gestion) {
        const res = await axios.post(
            curso.apiUrl,
            {
                query: `query{${curso.queries.getEncriptacionGestion(gestion)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );

        return res.data.data.encriptCertGestion;
    }
}
