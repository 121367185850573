import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";
import { DocumentoService } from "../../../services/gestionCalidad/DocumentoService";
import { Toast } from "primereact/toast";

export const DialogAddEdit = (props) => {
    const toast = useRef(null);
    const [documentoService] = useState(new DocumentoService());

    const formik = useFormik({
        initialValues: {
            radioTipoDoc: 1,
            doc_digital: "",
            //enlace: "",
            codigo: "",
            nombre_doc: "",
            version: "",
            descripcion_doc: "",
            is_newVersion: false,
        },
        validationSchema: Yup.object({
            codigo: Yup.string().nullable(),
            nombre_doc: Yup.string().required("Escriba un nombre!!"),
            descripcion_doc: Yup.string().nullable(),
            doc_digital: Yup.string()
                .when("radioTipoDoc", {
                    is: 2,
                    then: Yup.string().required("Campo requerido!!").nullable(),
                })
                .nullable(),
            /* enlace: Yup.string()
                .when("radioTipoDoc", {
                    is: 3,
                    then: Yup.string().url().required("Campo requerido!!").nullable(),
                })
                .nullable(), */
            version: Yup.string().nullable(),
        }),
        onSubmit: async (data) => {
            if (props.showHide === 1) {
                //Create
                await createData(data);
            } else if (props.showHide === 2) {
                //Update
                await updateData(data);
            }

            onHide();
            props.loadDataView();
        },
    });

    const createData = async (data) => {
        data.ID_DOC = props.selectedDocumento ? props.selectedDocumento.id : null;
        const response = await documentoService.create(data);
        if (response.ID) {
            toast.current.show({ severity: "success", summary: "Creado", detail: "Documento creado correctamente!!", life: 3000 });
        }
    };

    const updateData = async (data) => {
        const response = await documentoService.update(props.selectedDocumento.id, data);
        if (response.ID) {
            toast.current.show({ severity: "success", summary: "Actualizado", detail: "Documento actualizado correctamente!!", life: 3000 });
        }
    };

    const onHide = () => {
        formik.resetForm();
        props.setShowHide(0);
    };

    const onShow = () => {
        formik.resetForm();
        let documento = props.selectedDocumento;
        if (!(documento && props.showHide === 2)) {
            formik.setFieldValue("is_newVersion", true);
            return;
        }
        formik.setValues(
            {
                radioTipoDoc: documento.type,
                codigo: documento.code ? documento.code : "",
                nombre_doc: documento.name,
                version: documento.version,
                is_newVersion: false,
                descripcion_doc: documento.description,
                doc_digital: documento.link,
                //enlace: documento.iframe,
            },
            false
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const chooseOptions = {
        label: "Seleccionar documento",
        icon: "pi pi-fw pi-upload",
    };

    const onUploadFile = (file) => {
        let response = JSON.parse(file.xhr.response);
        formik.setFieldValue("doc_digital", response.filename);
    };

    const setEditorData = (e) => {
        formik.setFieldValue("descripcion_doc", e.htmlValue);
    };

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <select defaultValue={"normal"} className="ql-size">
                    <option value="small" aria-label="pequeño"></option>
                    <option value="normal"></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                </select>
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-list" value="ordered" aria-label="Ordered"></button>
                <button className="ql-list" value="bullet" aria-label="Ordered"></button>
                <button className="ql-script" value="sub"></button>
                <button className="ql-script" value="super"></button>
                <button className="ql-align" aria-label="Ordered"></button>
                <button className="ql-align" value="center" aria-label="Ordered"></button>
                <button className="ql-align" value="right" aria-label="Ordered"></button>
                <button className="ql-align" value="justify" aria-label="Ordered"></button>
            </span>
        );
    };

    const header = renderHeader();

    return (
        <div>
            <Toast ref={toast} />
            <Dialog
                header={props.showHide === 2 ? "Editar Documento" : `Agregar Documento/Directorio dentro de '${props.selectedDocumento ? props.selectedDocumento.name : ""}'`}
                closeOnEscape={false}
                visible={props.showHide > 0}
                style={{ width: "50vw" }}
                onHide={() => onHide()}
                onShow={() => onShow()}
            >
                <form onSubmit={formik.handleSubmit} className="fluid">
                    <div className="card">
                        <div className="fluid">
                            {props.showHide === 1 && (
                                <div className="field grid">
                                    <label htmlFor="representanteRegistro" className="col-12 md:col-2">
                                        Tipo
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <div className="formgroup-inline">
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="radioTipoDoc01" name="radioTipoDoc" value={1} onChange={formik.handleChange} checked={formik.values.radioTipoDoc === 1} />
                                                <label htmlFor="radioTipoDoc01">Directorio</label>
                                            </div>
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="radioTipoDoc02" name="radioTipoDoc" value={2} onChange={formik.handleChange} checked={formik.values.radioTipoDoc === 2} />
                                                <label htmlFor="radioTipoDoc02">Documento</label>
                                            </div>
                                            {/* <div className="field-radiobutton">
                                                <RadioButton inputId="radioTipoDoc03" name="radioTipoDoc" value={3} onChange={formik.handleChange} checked={formik.values.radioTipoDoc === 3} />
                                                <label htmlFor="radioTipoDoc03">Enlace</label>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {formik.values.radioTipoDoc === 2 && (
                                <div className="field grid">
                                    <label htmlFor="doc_digital" className="col-12 md:col-2">
                                        Documento
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <sub>Maximo 3MB</sub>
                                        <FileUpload
                                            id="doc_digital"
                                            mode="basic"
                                            name="doc_digital"
                                            url={process.env.REACT_APP_UPLOAD_URL}
                                            chooseOptions={chooseOptions}
                                            multiple={false}
                                            onUpload={onUploadFile}
                                            auto
                                            maxFileSize="3145728"
                                            invalidFileSizeMessageSummary="{0} Tamaño de archivo no válido"
                                            invalidFileSizeMessageDetail="El tamaño máximo de carga es {0}"
                                            accept=".mp3,.mp4,.webm,.csv,.doc,.docx,.xlsx,.xls,.pdf,image/*"
                                            className="inputfield w-full"
                                        ></FileUpload>
                                        <b>{formik.values.doc_digital}</b>
                                        {getFormErrorMessage("doc_digital")}
                                    </div>
                                </div>
                            )}
                            <div className="field grid">
                                <label htmlFor="codigo" className="col-12 md:col-2">
                                    Codigo
                                </label>
                                <div className="col-12 md:col-10">
                                    <InputText id="codigo" name="codigo" value={formik.values.codigo} onChange={formik.handleChange} className="inputfield w-full" />
                                    {getFormErrorMessage("codigo")}
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="nombre_doc" className="col-12 md:col-2">
                                    Nombre
                                </label>
                                <div className="col-12 md:col-10">
                                    <InputText id="nombre_doc" name="nombre_doc" value={formik.values.nombre_doc} onChange={formik.handleChange} className="inputfield w-full" />
                                    {getFormErrorMessage("nombre_doc")}
                                </div>
                            </div>
                            {(formik.values.radioTipoDoc === 2 || formik.values.radioTipoDoc === 3) && props.showHide === 2 && (
                                <div className="field grid">
                                    <label htmlFor="is_newVersion" className="col-12 md:col-2">
                                        ¿Es nueva version?
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputSwitch id="is_newVersion" name="is_newVersion" checked={formik.values.is_newVersion} onChange={formik.handleChange} />
                                        {getFormErrorMessage("is_newVersion")}
                                    </div>
                                </div>
                            )}
                            {formik.values.radioTipoDoc === 3 && formik.values.is_newVersion && (
                                <div className="field grid">
                                    <label htmlFor="enlace" className="col-12 md:col-2">
                                        Enlace
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText id="enlace" name="enlace" value={formik.values.enlace} onChange={formik.handleChange} className="inputfield w-full" />
                                        {getFormErrorMessage("enlace")}
                                    </div>
                                </div>
                            )}
                            {(formik.values.radioTipoDoc === 2 || formik.values.radioTipoDoc === 3) && formik.values.is_newVersion && (
                                <div className="field grid">
                                    <label htmlFor="version" className="col-12 md:col-2">
                                        Version:
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText id="version" name="version" value={formik.values.version} onChange={formik.handleChange} className="inputfield w-full" />
                                        {getFormErrorMessage("version")}
                                    </div>
                                </div>
                            )}
                            {(formik.values.radioTipoDoc === 2 || formik.values.radioTipoDoc === 3) && formik.values.is_newVersion && (
                                <div className="field grid">
                                    <label htmlFor="descripcion_doc" className="col-12 md:col-2">
                                        Descripcion
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <Editor id="descripcion_doc" name="descripcion_doc" headerTemplate={header} style={{ height: "320px" }} value={formik.values.descripcion_doc} onTextChange={setEditorData} />
                                        {getFormErrorMessage("descripcion_doc")}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button label="Cancelar" type="reset" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                        <Button type="submit" label="Guardar" icon="pi pi-save" />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
