import React, { useState, useRef } from "react";
import { Tree } from "primereact/tree";
import { Toast } from "primereact/toast";
import { DocumentoService } from "../../../services/documentosBI/DocumentoService";
import "primeflex/primeflex.css";
import { documentDefaulValue, openFile } from "../../../helpers/gestionCalidad/documents.helper";

export const TreeDocuments = (props) => {
    const [loadingTree, setLoadingTree] = useState(false);
    const [selectedNodeKey] = useState(null);
    const [documentoServices] = useState(new DocumentoService());
    const toast = useRef(null);

    const onExpand = async (event) => {
        if (event.node.type === 1) {
            setLoadingTree(true);
            const documentos = await documentoServices.getTreeDocumentsByID(event.node.key);
            event.node.children = documentos.length > 0 ? documentos : [documentDefaulValue];
            setLoadingTree(false);
        }
    };

    const onCollapse = (event) => {
        event.node.children = [documentDefaulValue];
    };

    const onSelect = (event) => {
        if (event.node.type === 1) {
            props.setDocuments(event.node.key);
        } else if (event.node.hasOwnProperty("link") && event.node.link) {
            openFile(event.node, "documentsBI");
        }
    };

    const onUnselect = (event) => {
        props.setDocuments(-1);
        toast.current.show({ severity: "info", summary: "Node Unselected", detail: event.node.label });
    };

    const nodeTemplate = (node, options) => {
        let label = <b>{node.label}</b>;

        if (node.type === 1) {
            label = <b>{node.label}</b>;
        }

        return <span className={options.className}>{label}</span>;
    };

    return (
        <>
            <Toast ref={toast} />
            <Tree value={props.nodes} expandedKeys={props.expandedKeys} onToggle={(e) => props.setExpandedKeys(e.value)} selectionMode="single" nodeTemplate={nodeTemplate} selectionKeys={selectedNodeKey} onExpand={onExpand} onCollapse={onCollapse} onSelect={onSelect} onUnselect={onUnselect} loading={loadingTree} />
        </>
    );
};
