import { Tooltip } from "primereact/tooltip";
import React from "react";

export const Permanentes = (props) => {
    return (
        <>
            <Tooltip target=".tooltipPerm" />
            <ul className="text-xl">
                <li className="mt-2">
                    <b className="tooltipPerm bg-blue-200" data-pr-tooltip="Debe estar logueado en UPBVirtual" data-pr-position="top">
                        <a href="https://www.upbvirtual.net/upbvirtual/course/view.php?id=12911" target="_blank" rel="noopener noreferrer">
                            Sílabo UPB 2020 v
                        </a>
                    </b>
                </li>
                <li className="mt-2">
                    <b className="tooltipPerm bg-green-200" data-pr-tooltip="Debe estar logueado en UPBVirtual" data-pr-position="top">
                        <a href="https://www.upbvirtual.net/upbvirtual/moodleupb/nanocursos.php" target="_blank" rel="noopener noreferrer">
                            Cursos en formato de aprendizaje corto
                        </a>
                    </b>
                </li>
            </ul>
        </>
    );
};
