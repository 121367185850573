module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        initialDocuments: (onlyDirectories = false) => {
            return `
            initialDocumentsQM(onlyDir:${onlyDirectories ? "true" : "false"}){
                ID
                ID_DOC
                INDICE
                CODIGO
                NOMBRE
                ENLACE
                DESCRIPCION
                VERSION
                RUTA
                TAMANIO
                EXTENSION
                TIPO
                FECHAREG
            }`;
        },
        documentsByID: (id, onlyDirectories = false) => {
            return `
            documentsQMByID(id:${id} onlyDir:${onlyDirectories ? "true" : "false"}){
                ID
                ID_DOC
                INDICE
                CODIGO
                NOMBRE
                ENLACE
                DESCRIPCION
                VERSION
                RUTA
                TAMANIO
                EXTENSION
                TIPO
                FECHAREG
            }`;
        },
        documentByID: (id) => {
            return `
            documentQM(id:"${id}"){
                ID
                ID_DOC
                INDICE
                CODIGO
                NOMBRE
                ENLACE
                DESCRIPCION
                VERSION
                RUTA
                TAMANIO
                EXTENSION
                TIPO
                FECHAREG
            }`;
        },
        searchDocuments: (texto) => {
            return `
            searchDocuments(texto:"${texto}"){
                ID
                ID_DOC
                INDICE
                CODIGO
                NOMBRE
                ENLACE
                DESCRIPCION
                VERSION
                RUTA
                TAMANIO
                EXTENSION
                TIPO  
                FECHAREG              
            }`;
        },
    },
    mutations: {
        create: (data) => {
            const regex = new RegExp('"', "g");
            data.descripcion_doc = data.descripcion_doc ? data.descripcion_doc.replace(regex, "'") : "";
            return `
            createDocumentQM(documentQM:{
                ${data.ID_DOC ? `ID_DOC: ${data.ID_DOC}` : ""}
                CODIGO: "${data.codigo}"
                NOMBRE: "${data.nombre_doc}"
                ${data.doc_digital ? `RUTA: "${data.doc_digital}"` : ""}
                DESCRIPCION: "${data.descripcion_doc}"
                TIPO: ${data.radioTipoDoc}
                ${data.version ? `VERSION: "${data.version}"` : ""}
            }){
                ID
                ID_DOC
                INDICE
                CODIGO
                NOMBRE
                ENLACE
                DESCRIPCION
                VERSION
                RUTA
                TAMANIO
                EXTENSION
                TIPO
                FECHAREG
            }
            `;
        },
        update: (id, data) => {
            const regex = new RegExp('"', "g");
            data.descripcion_doc = data.descripcion_doc ? data.descripcion_doc.replace(regex, "'") : "";
            return `
            updateDocumentQM(id: "${id}" documentQM:{
                ${data.ID_DOC ? `ID_DOC: ${data.ID_DOC}` : ""}
                CODIGO: "${data.codigo}"
                NOMBRE: "${data.nombre_doc}"
                ${data.doc_digital ? `RUTA: "${data.doc_digital}"` : ""}
                ${data.is_newVersion ? `DESCRIPCION: "${data.descripcion_doc}"` : ""}
                TIPO: ${data.radioTipoDoc}
                ${data.is_newVersion ? `VERSION: "${data.version}"` : ""}
            }){
                ID
                ID_DOC
                INDICE
                CODIGO
                NOMBRE
                ENLACE
                DESCRIPCION
                VERSION
                RUTA
                TAMANIO
                EXTENSION
                TIPO
                FECHAREG
            }
            `;
        },
        changeParent: (id, id_doc) => {
            return `
            updateDocumentQM(id: "${id}" documentQM:{
                ID_DOC: ${id_doc}
                INDICE: 0
            }){
                ID
                ID_DOC
                INDICE
                CODIGO
                NOMBRE
                ENLACE
                DESCRIPCION
                VERSION
                RUTA
                TAMANIO
                EXTENSION
                TIPO
                FECHAREG
            }
            `;
        },
        updateIndex: (data) => {
            const newData = data
                .map((element, index) => {
                    return `{ ID: ${element.id}, INDICE: ${index + 1} }`;
                })
                .join(",");
            return `setIndexDocumentQM(documentsQM:[${newData}])`;
        },
        delete: (id) => {
            return `
            deleteDocumentQM(id: "${id}"){
                ID
                ID_DOC
                INDICE
                CODIGO
                NOMBRE
                ENLACE
                DESCRIPCION
                VERSION
                RUTA
                TAMANIO
                EXTENSION
                TIPO
                FECHAREG
            }
            `;
        },
    },
};
