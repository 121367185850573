import axios from "axios";
import { getToken } from "../../helpers/token.helper";
import institucion from "../../constants/publicaciones/inv.centroInv";

export class CentrosInvService {
    getAll() {
        return axios
            .post(
                institucion.apiUrl,
                {
                    query: `query{${institucion.queries.getAll()}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.centros;
            });
    }

    /*insertInstitucion(data) {
        //console.log(data);
        return axios
            .post(
                institucion.apiUrl,
                {
                    query: `mutation{${institucion.queries.getInstituciones()}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.instituciones;
            });
    }

    updateInstitucion(data) {
        console.log(data);
    }*/
}
