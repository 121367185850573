import React from "react";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";

const LayoutLoading = (props) => {
    return (
        <div className="pt-8">
            <div className="mx-8 px-8 card">
                <div className="flex flex-column card-container green-container">
                    <div className="flex align-items-center justify-content-center bg-yellow-500 font-bold text-white border-round mx-2">
                        <i className="pi pi-exclamation-triangle" />
                        &nbsp;Lamentablemente no pudimos conectarnos con el servidor.!!!
                    </div>
                    <div className="flex mx-5">Para recuperar la conexión intente una de las siguientes opciones:</div>
                    <div className="flex mx-5">
                        <dl>
                            <dt className="font-bold">Opción 1:</dt>
                            <dd>Recargue la página (presionando la tecla F5)</dd>
                            <dt className="font-bold">Opción 2:</dt>
                            <dd>dentro de este sistema. cierre sesión y vuelva a ingresar con sus credenciales</dd>
                        </dl>
                    </div>
                    <div className="flex mx-5 font-bold">
                        <i className="pi pi-exclamation-triangle" />
                        &nbsp;En caso de persistir con el problema comuníquese con el administrador
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutLoading;
