import React, { useState } from "react";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { IconFile } from "../icons/IconFile";
import "./DataViewFile.css";
import { DialogAddEdit } from "../dialogs/DialogAddEdit";
import { DialogView } from "../dialogs/DialogView";
import { isAppWeb } from "../../../helpers/system.helper";
import { openFile } from "../../../helpers/gestionCalidad/documents.helper";
import { DialogMove } from "../dialogs/DialogMove";
import { DialogFilter } from "../dialogs/DialogFilter";

export const DataViewFile = (props) => {
    const [layout, setLayout] = useState("list");
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);

    const [dialogAddEditState, setDialogAddEditState] = useState(0);
    const [dialogMove, setDialogMove] = useState(0);
    const [dialogViewState, setDialogViewState] = useState(0);
    const [dialogFilterState, setDialogFilterState] = useState(0);
    const [selectedDocumento, setSelectedDocumento] = useState(null);

    const [loadCurrentDir, setLoadCurrentDir] = useState(false);

    const sortOptions = [
        { label: "Web final", value: "position" },
        { label: "Tamaño alto a bajo", value: "!size" },
        { label: "Tamaño bajo a alto", value: "size" },
        { label: "Nombre a-z", value: "name" },
        { label: "Nombre z-a", value: "!name" },
        { label: "Modificacion a-z", value: "dateUpdate" },
        { label: "Modificacion z-a", value: "dateUpdate!" },
    ];

    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf("!") === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    };

    const addDocument = (document, isLoadCurrentDir) => {
        setLoadCurrentDir(isLoadCurrentDir);
        setSelectedDocumento(document);
        setDialogAddEditState(1);
    };

    /* const filterDocument = (document) => {
        setLoadCurrentDir(false);
        setSelectedDocumento(document);
        setDialogFilterState(1);
    }; */

    const editDocument = (document) => {
        setLoadCurrentDir(false);
        setSelectedDocumento(document);
        setDialogAddEditState(2);
    };

    const moveDocument = (document) => {
        setLoadCurrentDir(false);
        setSelectedDocumento(document);
        setDialogMove(1);
    };

    const delCofirm = (document) => {
        setLoadCurrentDir(false);
        setSelectedDocumento(document);
        confirmDialog({
            message: `Este seguro de eliminar el documento? ${document.name}`,
            header: "Eliminar",
            icon: "pi pi-exclamation-triangle",
            accept: () => delDocument(document),
        });
    };

    const delDocument = async (document) => {
        await props.deleteDocument(document);
        loadDataView();
    };

    const downloadDocument = (document) => {
        if (document.type === 1) {
            props.setDocuments(document.id);
        } else if (document.type === 2) {
            openFile(document, "documentsBI");
        }
    };

    const openDocument = (document) => {
        setSelectedDocumento(document);
        setDialogViewState(1);
    };

    const movePosition = (id, direction) => {
        if (canMoveIndex()) {
            props.movePosition(id, direction);
        }
    };

    const canMoveIndex = () => {
        return sortKey === "position" || sortKey == null;
    };

    const renderListItem = (data) => {
        const nameType = data.type === 1 ? "[Directorio]" : data.size;

        return (
            <div className="col-12 pt-2 grid dataViewItem">
                <div className="col-12 md:col-6 lg:col-2" onDoubleClick={() => downloadDocument(data)}>
                    <IconFile
                        data={data}
                        className="mr-5"
                        style={{
                            fontSize: "4em",
                        }}
                    />
                </div>
                <div className="col-12 md:col-6 lg:col-5" onDoubleClick={() => downloadDocument(data)}>
                    <div className="font-bold text-lg">{data.name}</div>
                    <div className="text-sm">
                        {data.code && (
                            <>
                                <b>Código: </b>
                                {data.code}
                            </>
                        )}
                    </div>
                    <div className="text-sm">
                        {data.version && (
                            <>
                                <b>Version: </b>
                                {data.version}
                            </>
                        )}
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-2" onDoubleClick={() => downloadDocument(data)}>
                    <span className="product-price inline-block">{nameType}</span>
                    <div className="text-sm">
                        {data.dateUpdate && (
                            <>
                                <b>modificación: </b>
                                {data.dateUpdate}
                            </>
                        )}
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="flex align-items-end justify-content-end">{getButton(data)}</div>
                </div>
            </div>
        );
    };

    const renderGridItem = (data) => {
        const nameType = data.type === 1 ? "[Directorio]" : data.size;
        return (
            <div className="sm:col-12 md:col-4 lg:col-3">
                <div className="dataViewItem m-2 p-5 border-1 border-solid surface-border card">
                    <div className="flex align-items-center justify-content-between">{getButton(data)}</div>
                    <div className="text-center" onDoubleClick={() => downloadDocument(data)}>
                        <IconFile data={data} className="mx-5" style={{ fontSize: "10em" }} />
                        <div className="font-bold text-2xl">{data.name}</div>
                        <div className="ml-3">
                            {data.code && (
                                <>
                                    <b>Codigo:</b>
                                    {data.code}
                                </>
                            )}
                        </div>
                        <div className="ml-3">
                            {data.version && (
                                <>
                                    <b>Version: </b>
                                    {data.version}
                                </>
                            )}
                        </div>
                        <span className="font-semibold text-2xl">{nameType}</span>
                    </div>
                </div>
            </div>
        );
    };

    const getButton = (data) => {
        const openView = data.type === 1 ? "pi pi-folder-open" : "pi pi-cloud-download";
        return !isAppWeb() ? (
            <>
                {data.type === 1 && <Button icon="pi pi-plus" className="inline-block p-button-rounded p-button-success" aria-label="Nuevo.." onClick={(e) => addDocument(data, false)} />}
                <Button icon="pi pi-pencil" className="inline-block p-button-rounded p-button-info" aria-label="Editar.." onClick={(e) => editDocument(data)} />
                <Button icon="pi pi-external-link" className="inline-block p-button-rounded p-button-info" aria-label="Mover.." onClick={(e) => moveDocument(data)} />
                <Button icon="pi pi-trash" className="inline-block p-button-rounded p-button-danger" aria-label="Borrar.." onClick={(e) => delCofirm(data)} />
                {!(data.type === 1) && <Button icon="pi pi-search" className="inline-block p-button-rounded p-button-help" aria-label="Ver.." onClick={(e) => openDocument(data)} />}
                <Button icon={openView} className="inline-block p-button-rounded p-button-help" aria-label="Descargar.." onClick={(e) => downloadDocument(data)} />
                {canMoveIndex() && <Button icon="pi pi-arrow-up" className="inline-block p-button-rounded p-button-help" aria-label="Mover arriba" onClick={(e) => movePosition(data.id, props.direction.UP)} />}
                {canMoveIndex() && <Button icon="pi pi-arrow-down" className="inline-block p-button-rounded p-button-help" aria-label="Mover abajo" onClick={(e) => movePosition(data.id, props.direction.DOWN)} />}
            </>
        ) : (
            <>
                {!(data.type === 1) && <Button icon="pi pi-search" className="inline-block p-button-rounded p-button-help" aria-label="Ver.." onClick={(e) => openDocument(data)} />}
                {!(data.type === 3) && <Button icon={openView} className="inline-block p-button-rounded p-button-help" aria-label="Descargar.." onClick={(e) => downloadDocument(data)} />}
            </>
        );
    };

    const itemTemplate = (product, layout) => {
        if (!product) return;
        if (layout === "list") return renderListItem(product);
        else if (layout === "grid") return renderGridItem(product);
    };

    const renderHeader = () => {
        const parentId = props.currentDirectory && props.currentDirectory.parent ? props.currentDirectory.parent : null;
        const currentId = props.currentDirectory && props.currentDirectory.id ? props.currentDirectory.id : null;
        return (
            <div className="grid nogutter">
                <Dropdown className="col-3 text-left" options={sortOptions} value={sortKey} optionLabel="label" placeholder="Ordenado por ..." onChange={onSortChange} />
                <div className="col-6 flex justify-content-center">
                    {props.currentDirectory && <Button icon="pi pi-chevron-up" className="mx-1 p-button-rounded p-button-info p-button-outlined" aria-label="Atras" onClick={() => props.setDocuments(parentId)} />}
                    <Button icon="pi pi-home" className="mx-1 p-button-rounded p-button-info p-button-outlined" aria-label="Ruta principal" onClick={() => props.setDocuments(null)} />
                    <Button icon="pi pi-refresh" className="mx-1 p-button-rounded p-button-info p-button-outlined" aria-label="Ruta principal" onClick={() => props.setDocuments(currentId)} />
                    {!isAppWeb() && <Button icon="pi pi-plus" className="mx-1 p-button-rounded p-button-success p-button-outlined" aria-label="Nuevo.." onClick={(e) => addDocument(props.currentDirectory, true)} />}
                    {/* <Button icon="pi pi-filter" className="mx-1 p-button-rounded p-button-info p-button-outlined" aria-label="Nuevo.." onClick={(e) => filterDocument(props.currentDirectory)} /> */}
                </div>
                <DataViewLayoutOptions className="col-3 text-right" style={{ display: "inline-block" }} layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        );
    };

    const loadDataView = () => {
        if (loadCurrentDir) {
            props.setDocuments(props.currentDirectory ? props.currentDirectory.id : null);
        } else {
            props.setDocuments(selectedDocumento ? selectedDocumento.parent : null);
        }
    };

    const header = renderHeader();
    return (
        <>
            <DialogAddEdit setShowHide={setDialogAddEditState} showHide={dialogAddEditState} loadDataView={loadDataView} selectedDocumento={selectedDocumento}></DialogAddEdit>
            <DialogMove setShowHide={setDialogMove} showHide={dialogMove} loadDataView={loadDataView} selectedDocumento={selectedDocumento}></DialogMove>
            <DialogView setShowHide={setDialogViewState} showHide={dialogViewState} loadDataView={loadDataView} selectedDocumento={selectedDocumento} downloadDocument={downloadDocument}></DialogView>
            <DialogFilter setShowHide={setDialogFilterState} showHide={dialogFilterState} loadDataView={loadDataView} selectedDocumento={selectedDocumento}></DialogFilter>
            <div className="card">
                <DataView value={props.documents} layout={layout} header={header} itemTemplate={itemTemplate} rows={9} sortOrder={sortOrder} sortField={sortField} emptyMessage="Sin Documentos!!" />
            </div>
        </>
    );
};
