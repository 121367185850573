import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";

import { MenubarTable } from "../components/publicaciones/menubarTable/MenubarTable";
import { DialogAddEdit } from "../components/publicaciones/dialogs/DialogAddEdit";
import { PublicacionService } from "../services/publicaciones/PublicacionService";
import { FilterPublicaciones } from "../components/publicaciones/filters/FilterPublicaciones";

export const Publicaciones = (props) => {
    const [publicacionService] = useState(new PublicacionService());
    const [publicaciones, setPublicaciones] = useState([]);
    const [dialogAddEditState, setDialogAddEditState] = useState(0);
    const [selectedPublicacion, setSelectedPublicacion] = useState(null);
    const dt = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        publicacionService.getAll().then((data) => setPublicaciones(data));
    }, [publicacionService]);

    const accept = () => {
        publicacionService.delete(selectedPublicacion.ID).then((data) => {
            toast.current.show({
                severity: "info",
                summary: "Confirmado",
                detail: "Has aceptado!!",
                life: 3000,
            });
            publicacionService.getAll().then((data) => setPublicaciones(data));
        });
    };

    const deletePublicacion = () => {
        confirmDialog({
            message: "¿Quieres borrar este registro?",
            header: "Eliminar confirmación",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
        });
    };

    const loadDataTable = () => {
        publicacionService.getAll().then((data) => {
            console.log(data);
            setPublicaciones(data);
        });
        setSelectedPublicacion(null);
    };

    const lnkDocumentTemplate = (rowData) => {
        return (
            <React.Fragment>
                <a rel="noopener noreferrer" href={`${rowData.ENLACE}`} target="_blank">
                    <FontAwesomeIcon icon={faGlobeAmericas} style={{ fontSize: "2em", color: "var(--teal-500)" }} />
                </a>
            </React.Fragment>
        );
    };

    const autorTemplate = (rowData) => {
        return <React.Fragment>{rowData.AUTORES && rowData.AUTORES[0] ? rowData.AUTORES[0].NOMBRES : "Sin Autor"}</React.Fragment>;
    };

    const dateFormat = (rowData) => {
        if (rowData.FECHA_PUB) {
            let fFirma = new Date(parseInt(rowData.FECHA_PUB));
            return <React.Fragment>{fFirma.toLocaleDateString()}</React.Fragment>;
        }
        return <React.Fragment></React.Fragment>;
    };

    //----------------- EXPORT FILES -----------------
    const exportCSV = (selectionOnly) => {
        console.log(selectionOnly);
        dt.current.exportCSV({ selectionOnly });
    };

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            console.log(publicaciones);
            let conveniosXlS = publicaciones.map((rowData) => {
                const newRow = {
                    Título: rowData.TITULO,
                    "Profesor Autor de la Escuela": rowData.AUTORES.map((autor) => autor.NOMBRES).join(",\n"),
                    "Nombre Journal": rowData.EDITORIAL.NOMBRE ? rowData.EDITORIAL.NOMBRE : "",
                    "Factor de impacto": "",
                    "Área de especialidad": rowData.AREAS_ESPECIALIDAD.map((autor) => autor.NOMBRE).join(",\n"),
                    Año: new Date(parseInt(rowData.FECHA_PUB)).getFullYear(),
                    Publicado: rowData.ESTADO_PUB === 1 ? "X" : "",
                    Forthcoming: rowData.ESTADO_PUB === 0 ? "X" : "",
                    "Link para verificación": rowData.ENLACE,
                };
                return newRow;
            });
            const worksheet = xlsx.utils.json_to_sheet(conveniosXlS);
            /* INI - Creacion del hipervinculo */
            for (let i = 1; i <= publicaciones.length; i++) {
                worksheet[`I${i + 1}`].l = {
                    Target: worksheet[`I${i + 1}`].v,
                    Tooltip: "Enlace para verificación",
                };
            }
            /* FIN - Creacion del hipervinculo */
            const workbook = {
                Sheets: {
                    Convenios: worksheet,
                },
                SheetNames: ["Convenios"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "convenios");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        });
    };

    const exportPdf = () => {
        let rows = publicaciones.map((rowData) => {
            const newRow = {
                TITULO: rowData.TITULO,
                AUTORES: rowData.AUTORES.map((autor) => autor.NOMBRES).join(",\n"),
                EDITORIAL: rowData.EDITORIAL.NOMBRE ? rowData.EDITORIAL.NOMBRE : "",
                METRICAS: "",
                AREAS_ESPECIALIDAD: rowData.AREAS_ESPECIALIDAD.map((autor) => autor.NOMBRE).join(",\n"),
                FECHA_PUB: new Date(parseInt(rowData.FECHA_PUB)).getFullYear(),
                PUBLICADO: rowData.ESTADO_PUB === 1 ? "X" : "",
                FORTHCOMING: rowData.ESTADO_PUB === 0 ? "X" : "",
                ENLACE: rowData.ENLACE,
            };
            return newRow;
        });
        const cols = [
            { field: "TITULO", header: "Título" },
            { field: "AUTORES", header: "Profesor Autor de la Escuela" },
            { field: "EDITORIAL", header: "Nombre Journal" },
            { field: "METRICAS", header: "Factor de impacto" },
            { field: "AREAS_ESPECIALIDAD", header: "Área de especialidad" },
            { field: "FECHA_PUB", header: "Año" },
            { field: "PUBLICADO", header: "Publicado" },
            { field: "FORTHCOMING", header: "Forthcoming" },
            { field: "ENLACE", header: "Link para verificación" },
        ];

        const exportColumns = cols.map((col) => ({
            title: col.header,
            dataKey: col.field,
        }));
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.text("Lista de Publicaciones", 20, 30);
                doc.autoTable({
                    //theme: "grid",
                    columns: exportColumns,
                    columnStyles: {
                        0: { cellWidth: 30.7793333333 },
                        1: { cellWidth: 20 },
                        2: { cellWidth: 20 },
                        3: { cellWidth: 20 },
                        4: { cellWidth: 20 },
                        5: { cellWidth: 18 },
                        6: { cellWidth: 18 },
                        7: { cellWidth: 18 },
                        8: { cellWidth: 17 },
                    },
                    body: rows,
                    margin: { top: 35 },
                    didDrawCell: function (data) {
                        if (data.column.dataKey === "ENLACE" && data.cell.section === "body") {
                            var textPos = data.cell;
                            const objLnk = {
                                url: data.cell.raw,
                            };
                            doc.textWithLink("link", textPos.x + 2, textPos.y + 5, objLnk);
                        }
                    },
                    willDrawCell: function (data) {
                        if (data.column.dataKey === "ENLACE" && data.cell.section === "body") {
                            data.cell.text = [""];
                        }
                    },
                });
                doc.save("publicaciones.pdf");
            });
        });
    };

    const exportDataTable = (type = 1) => {
        switch (type) {
            case 1:
                exportPdf();
                break;
            case 2:
                exportExcel();
                break;
            case 3:
                exportCSV();
                break;
            default:
                break;
        }
    };
    //------------------------------------------------

    const inputSearch = (
        <div>
            <FilterPublicaciones setPublicaciones={setPublicaciones} />
        </div>
    );

    return (
        <div>
            <div className="card">
                <Toast ref={toast} />
                <DialogAddEdit setShowHide={setDialogAddEditState} showHide={dialogAddEditState} loadDataTable={loadDataTable} selectedPublicacion={selectedPublicacion}></DialogAddEdit>
                <MenubarTable dialogAddEdit={setDialogAddEditState} dialogDelete={deletePublicacion} inputSearch={inputSearch} selectedPublicacion={selectedPublicacion} setSelectedPublicacion={setSelectedPublicacion} exportDataTable={exportDataTable}></MenubarTable>
                <DataTable value={publicaciones} sortMode="multiple" ref={dt} paginator rows={10} selectionMode="single" metaKeySelection={false} dataKey="ID" selection={selectedPublicacion} onSelectionChange={(e) => setSelectedPublicacion(e.value)}>
                    <Column field="TIPO_PUBLICACION.NOMBRE" header="Tipo de Publicación" sortable></Column>
                    <Column field="TITULO" header="Titulo Publicación" sortable></Column>
                    <Column field="CENTRO_INVESTIGACION.NOMBRE" header="Centro de Dependencia" sortable></Column>
                    <Column header="Fecha de Publicación" body={dateFormat} sortable></Column>
                    <Column header="Autor" sortable body={autorTemplate}></Column>
                    <Column header="Enlace" sortable body={lnkDocumentTemplate}></Column>
                </DataTable>
            </div>
        </div>
    );
};
