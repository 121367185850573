import axios from "axios";
import institucion from "../../constants/convenios/conv.institucion";
import { getToken } from "../../helpers/token.helper";

export class InstitucionService {
    async getInstituciones() {
        const res = await axios
            .post(
                institucion.apiUrl,
                {
                    query: `query{${institucion.queries.getInstituciones()}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            );
        return res.data.data.instituciones;
    }

    insertInstitucion(data) {
        //console.log(data);
        return axios
            .post(
                institucion.apiUrl,
                {
                    query: `mutation{${institucion.queries.getInstituciones()}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.instituciones;
            });
    }

    updateInstitucion(data) {
        console.log(data);
    }
}
