module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getCursos: () => {
            return `
            cursos_ofertas{
                ID
                NOMBRE
                NRO_HORAS
                FECHAINI
                FECHAFIN
                HORAINI
                HORAFIN
                URLZOOM
                IDZOOM
                PWDZOOM
                HSTZOOM
                TIPO
                CUPO
                OBJETIVO
                MINIMO
                ESTADO
                USUARIO
                FECHAREG
                GES_COD_GES
                AUL_COD_AULA
                NUM_SUSCRITOS
                AULA{
                    COD_AULA
                    NOMBRE
                    UBICACION
                }
            }`;
        },
        getSuscritos: (gestion) => {
            return `
            cursos_suscritos(gestion:"${gestion}"){
                ID
                NOMBRE
                NRO_HORAS
                FECHAINI
                FECHAFIN
                HORAINI
                HORAFIN
                URLZOOM
                IDZOOM
                PWDZOOM
                HSTZOOM
                TIPO
                CUPO
                OBJETIVO
                MINIMO
                ESTADO
                USUARIO
                FECHAREG
                GES_COD_GES
                AUL_COD_AULA
                NUM_SUSCRITOS
                ASISTENCIA
            }`;
        },
        getPasados: (year) => {
            return `
            cursos_pasados(year:${year}){
                ID
                NOMBRE
                NRO_HORAS
                FECHAINI
                FECHAFIN
                HORAINI
                HORAFIN
                URLZOOM
                IDZOOM
                PWDZOOM
                HSTZOOM
                TIPO
                CUPO
                OBJETIVO
                MINIMO
                ESTADO
                USUARIO
                FECHAREG
                GES_COD_GES
                AUL_COD_AULA
                NUM_SUSCRITOS
                INSCRITO
            }`;
        },
        getCurso: (id) => {
            return `
            curso(id:${id}){
                ID
                NOMBRE
                NRO_HORAS
                FECHAINI
                FECHAFIN
                HORAINI
                HORAFIN
                URLZOOM
                IDZOOM
                PWDZOOM
                HSTZOOM
                TIPO
                CUPO
                OBJETIVO
                MINIMO
                ESTADO
                USUARIO
                FECHAREG
                GES_COD_GES
                AUL_COD_AULA
                NUM_SUSCRITOS
                AULA{
                    COD_AULA
                    NOMBRE
                    UBICACION
                }
                CAPACITADORES{
                    ID
                    NOMBRE
                    AP_PATERNO
                    AP_MATERNO
                    CELULAR
                    TIPO
                }
            }`;
        },

        getGestiones: () => {
            return `
            cursoGestiones{
                CODIGO
                NOMBRE
            }`;
        },

        getYears: () => {
            return `
            cursoYears`;
        },

        getEncriptacionCurso: (idCurso) => {
            return `
            encriptCertCurso(id:${idCurso}){
                ENCRIPTADO
                ID_EMISION
            }`;
        },

        getEncriptacionGestion: (gestion) => {
            return `
            encriptCertGestion(gestion:"${gestion}"){
                ENCRIPTADO
                ID_EMISION
            }`;
        },

        suscribirse: (data) => {
            return `
            suscribirseCurso(id:${data.idCurso}){
                ID
                NOMBRE
                NRO_HORAS
                FECHAINI
                FECHAFIN
                HORAINI
                HORAFIN
                URLZOOM
                IDZOOM
                PWDZOOM
                HSTZOOM
                TIPO
                CUPO
                OBJETIVO
                MINIMO
                ESTADO
                USUARIO
                FECHAREG
                GES_COD_GES
                AUL_COD_AULA
                AULA{
                    COD_AULA
                    NOMBRE
                    UBICACION
                }
            }`;
        },
        desuscribirse: (data) => {
            return `
            desuscribirseCurso(id:${data.idCurso}){
                ID
                NOMBRE
                NRO_HORAS
                FECHAINI
                FECHAFIN
                HORAINI
                HORAFIN
                URLZOOM
                IDZOOM
                PWDZOOM
                HSTZOOM
                TIPO
                CUPO
                OBJETIVO
                MINIMO
                ESTADO
                USUARIO
                FECHAREG
                GES_COD_GES
                AUL_COD_AULA
                AULA{
                    COD_AULA
                    NOMBRE
                    UBICACION
                }
            }`;
        },
    },
};
