import axios from "axios";
import estudiante from "../../constants/convenios/conv.estudiante";
import { getToken } from "../../helpers/token.helper";

export class EstudianteService {
    async getAll(tipo, query) {
        const res = await axios.post(
            estudiante.apiUrl,
            {
                query: `query{${estudiante.queries.getAll(tipo, query)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.estudiantes;
    }
    async getEstudiantesConvenio(id) {
        const res = await axios.post(
            estudiante.apiUrl,
            {
                query: `query{${estudiante.queries.getEstudiantesConvenio(id)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.estudiantesConvenio;
    }

    async addEstudianteConvenio(id, estudianteInput, typePerson) {
        const res = await axios.post(
            estudiante.apiUrl,
            {
                query: `mutation {${estudiante.queries.addEstudianteConvenio(id, estudianteInput, typePerson)}}`,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.convenioAddEstudiante;
    }

    delEstudianteConvenio(idConvenioEstudiante) {
        return axios
            .post(
                estudiante.apiUrl,
                {
                    query: `mutation {${estudiante.queries.delEstudianteConvenio(idConvenioEstudiante)}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.convenioDelEstudiante;
            });
    }

    async update(id, data) {
        let mutation = `mutation{${estudiante.queries.update(id, data)}}`;
        const res = await axios.post(
            estudiante.apiUrl,
            {
                query: mutation,
            },
            {
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": getToken(),
                },
            }
        );
        return res.data.data.updateEstudiante;
    }
}
