import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { faCalendar, faCalendarCheck, faHistory, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Ofertas } from "../components/formacionDocente/Ofertas";
import { Suscritos } from "../components/formacionDocente/Suscritos";
import { Pasados } from "../components/formacionDocente/Pasados";
import { Permanentes } from "../components/formacionDocente/Permanentes";

export const FormacionDocente = (props) => {
    const tabHeaderITemplate = (options, icon) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FontAwesomeIcon icon={icon} />
                &nbsp;
                {options.titleElement}
            </button>
        );
    };

    return (
        <div>
            <div className="card">
                <TabView className="tabview-custom" scrollable>
                    <TabPanel header="Próximos eventos" headerTemplate={(options) => tabHeaderITemplate(options, faCalendar)}>
                        <Ofertas></Ofertas>
                    </TabPanel>
                    <TabPanel header="Mis actividades" headerTemplate={(options) => tabHeaderITemplate(options, faCalendarCheck)} headerClassName="flex align-items-center">
                        <Suscritos></Suscritos>
                    </TabPanel>
                    <TabPanel header="Eventos pasados" headerTemplate={(options) => tabHeaderITemplate(options, faHistory)} headerClassName="flex align-items-center">
                        <Pasados></Pasados>
                    </TabPanel>
                    <TabPanel header="Cursos permanentes" headerTemplate={(options) => tabHeaderITemplate(options, faSyncAlt)} headerClassName="flex align-items-center">
                        <Permanentes></Permanentes>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    );
};
