import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GraduationProcessService } from "../../services/graduationProcess/GraduationProcessService";
  
const TableDefensePrograming = ()=>{

    const[graduationProcess] = useState( new GraduationProcessService() );
    const[graduationList, setGraduationList] = useState( new GraduationProcessService() );    
    useEffect( () => {
        graduationProcess.getAll()
        .then( data => setGraduationList(data) )
    },[] );

    return(
        <div>
            <div className="card">
                <h5>PROGRAMACIÓN DE DEFNESAS</h5>
                <DataTable value={graduationList} 
                        tableStyle={{ maxWidth: '70rem' }}
                        stripedRows 
                        emptyMessage='No se encontraron datos para mostrar.'
                        >
                    <Column field="NOM_CARRERA" header="Carrera"></Column>
                    <Column field="FULL_NAME" header="Estudiante"></Column>
                    <Column field="FECHA_TIT" header="Fecha Tit."></Column>
                    <Column field="HORA_DEFENSA" header="Hora"></Column>
                    <Column field="NOMBRE_AU" header="Aula"></Column>
                </DataTable>
            </div>
        </div>
    );
};

export {TableDefensePrograming};