import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";

import "primeflex/primeflex.css";

import calConfig from "../../../constants/calendar.config";
import { CentrosInvService } from "../../../services/publicaciones/CentrosInvService";
import { AutorService } from "../../../services/publicaciones/AutorService";
import { PublicacionService } from "../../../services/publicaciones/PublicacionService";

export const FilterPublicaciones = (props) => {
    const [centrosInv, setCentrosInv] = useState([]);
    const [autores, setAutores] = useState([]);
    const op = useRef(null);
    const [centrosInvService] = useState(new CentrosInvService());
    const [autorService] = useState(new AutorService());
    const [publicacionService] = useState(new PublicacionService());

    const dtTipoPublicacion = [
        { name: "Artículo Revista", code: "1" },
        { name: "Artículo Conferencia", code: "2" },
        { name: "Libro", code: "3" },
        { name: "Capítulo de Libro", code: "4" },
    ];

    const formik = useFormik({
        initialValues: { pub_titulo: "" },
        onSubmit: async (values) => {
            let publicaciones = await publicacionService.getAllByFilter(values);
            console.log(publicaciones);
            props.setPublicaciones(publicaciones);
        },
    });

    addLocale(calConfig.sp.name, calConfig.sp.config);

    /****************************** */
    const selectedDropdownTemplate = (option, props, valueKey) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option[valueKey]}</div>
                </div>
            );
        }

        if (props) {
            return <span>{props.placeholder}</span>;
        }

        return "";
    };

    const dropDownOptionTemplate = (option, valueKey) => {
        return (
            <div className="country-item">
                <div>{option[valueKey]}</div>
            </div>
        );
    };

    const loadCentrosInv = () => {
        centrosInvService.getAll().then((data) => {
            setCentrosInv(data);
        });
    };

    const loadAutores = () => {
        autorService.getAll().then((data) => {
            setAutores(data);
        });
    };

    const onShow = async () => {
        loadCentrosInv();
        loadAutores();
    };

    const selectedAutorTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>
                        {option.NOMBRES}&nbsp; ({option.INSTITUCION.RAZON_SOCIAL})
                    </div>
                </div>
            );
        }

        if (props) {
            return <span>{props.placeholder}</span>;
        }

        return "";
    };

    const optionAutorTemplate = (option) => {
        return (
            <div className="country-item">
                <div>
                    {option.NOMBRES}&nbsp;({option.INSTITUCION.RAZON_SOCIAL})
                </div>
            </div>
        );
    };
    /****************************** */

    return (
        <div style={{ display: "inline" }}>
            <Button type="button" icon="pi pi-filter-slash" label="Filtros" className="p-button-info p-button-outlined" onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" />
            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{ width: "450px" }} onShow={() => onShow()}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="card">
                        <div className="p-fluid">
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Dropdown id="tipoPublicacion" showClear name="tipoPublicacion" value={formik.values.tipoPublicacion} options={dtTipoPublicacion} onChange={formik.handleChange} optionLabel="name" optionValue="code" placeholder="&nbsp;" />
                                    <label htmlFor="tipoPublicacion">Tipo publicaciones</label>
                                </span>
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Dropdown
                                        id="id_centro"
                                        name="id_centro"
                                        value={formik.values.id_centro}
                                        autoComplete="off"
                                        options={centrosInv}
                                        onChange={formik.handleChange}
                                        optionLabel="NOMBRE"
                                        optionValue="ID"
                                        filter
                                        showClear
                                        filterBy="NOMBRE"
                                        placeholder="&nbsp;"
                                        valueTemplate={(option, props) => {
                                            return selectedDropdownTemplate(option, props, "NOMBRE");
                                        }}
                                        itemTemplate={(option) => {
                                            return dropDownOptionTemplate(option, "NOMBRE");
                                        }}
                                    />
                                    <label htmlFor="id_centro">Centro de investigacion</label>
                                </span>
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <InputText id="pub_titulo" name="pub_titulo" value={formik.values.pub_titulo} autoComplete="off" onChange={formik.handleChange} />
                                    <label htmlFor="pub_titulo">Título</label>
                                </span>
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Dropdown
                                        id="id_autor"
                                        name="id_autor"
                                        value={formik.values.id_autor}
                                        options={autores}
                                        onChange={formik.handleChange}
                                        optionLabel="NOMBRES"
                                        optionValue="ID"
                                        filter
                                        showClear
                                        filterBy="NOMBRES"
                                        placeholder="&nbsp;"
                                        valueTemplate={(option, props) => {
                                            return selectedAutorTemplate(option, props);
                                        }}
                                        itemTemplate={(option) => {
                                            return optionAutorTemplate(option);
                                        }}
                                    />
                                    <label htmlFor="id_autor">Autor</label>
                                </span>
                            </div>
                            <div className="field py-2">
                                <span className="p-float-label p-input-icon-right">
                                    <Calendar id="pub_fecha" name="pub_fecha" value={formik.values.pub_fecha} onChange={formik.handleChange} locale="es" dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput />
                                    <label htmlFor="pub_fecha">Fecha de publicación</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button type="submit" label="Filtrar" icon="pi pi-filter-slash" />
                    </div>
                </form>
            </OverlayPanel>
        </div>
    );
};
