import React, {useState, useCallback, useRef, useEffect} from "react";
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { confirmDialog } from "primereact/confirmdialog";
import { LostObjectsService } from "../../../services/lostObjects/LostObjectsService";
import { LostObjectDetail } from '../../../components/lostObjects/dialogs/LostObjectDetail';
import { DialogAddEdit } from '../../../components/lostObjects/dialogs/DialogAddEdit';
import { MenubarTable } from '../../../components/lostObjects/menubarTable/MenubarTable';

import  { Toast } from "primereact/toast";
import { formatDate } from "../../../helpers/date.helper";



export const ListLostObjects = ( props ) => {

    const toast = useRef(null);
    const [lostObjectsService] = useState(new LostObjectsService());    
    const [listLostObject, setListLostObject] = useState([]);
    const isVisible = props.showOptions;
    
    const fetchDataLostObject = useCallback(() => {
        if(isVisible===1){//Los objetos de un usuario
            lostObjectsService.getAllByID()
            .then( data => setListLostObject(data) )
            .catch( error => console.log( error ) );
        }else{
            if(isVisible===2){//Si selecciona el tab de entregados
                lostObjectsService.getAll()
                .then( data => setListLostObject(data.filter(elemento=>elemento.ESTADO===3)) )
                .catch( error => console.log( error ) );
            }else{//Todos los objetos registrados
                lostObjectsService.getAll()
                .then( data => setListLostObject( data.filter(elemento=>elemento.ESTADO===1)) )
                .catch( error => console.log( error ) );
            }
        }
    }, [isVisible,lostObjectsService] );

    useEffect( ()=>{fetchDataLostObject();} , [fetchDataLostObject]);
    
    const [dialogAddEditState, setDialogAddEditState] = useState(0);
    const [lostObjectSelected, setLostObjectSelected] = useState('');
    const [dialogLostObjectDetail, setDialogLostObjectDetail] = useState(0);
    
    

    const searchLostObject = (id) => {
        return listLostObject.find(lostObjectSelected=>lostObjectSelected.ID===id);
    };

    const updateTable = useCallback(() => {
        fetchDataLostObject();
    }, [fetchDataLostObject] );

    //Actualizar Datos del Objeto perdido
    const updateLostObject = (event) => {
        try {
            const id = event.target.id;
            const detailLostObjectSelected = searchLostObject(id);
            setLostObjectSelected(detailLostObjectSelected);
            setDialogAddEditState(2);
        } catch (error) {
            throw error.message;
        }
        
    }
    //Eliminamos el objeto perdido    
    const delCofirm = (event) => {
        confirmDialog({
            message: `Esta seguro de eliminar el artículo?`,
            header: "Eliminar",
            icon: "pi pi-exclamation-triangle",
            accept: () => deleteLostObject(event.target.id),
            acceptLabel:"Si",
            rejectLabel:"No",
        });
    };
    //Eliminar Objeto perdido
    const deleteLostObject = async ( id ) => {
        await lostObjectsService.delete(id) 
        ? toast.current.show({ severity: 'success', summary: 'Correcto', detail: 'El atículo fue eliminado!' }) 
        : toast.current.show({ severity: 'danger', summary: 'Error', detail: 'El atículo no fue eliminado!' });
        updateTable();
    };


    const itemTemplate = (listLostObject) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={`${process.env.REACT_APP_UPLOAD_URL+'/lostObjects'}/${listLostObject.RUTA}`} alt={listLostObject.DETALLE} />
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4 ml-3">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div className="text-2xl font-bold text-900">{ listLostObject.TIPO===1 ? 'Se encontró' : 'Se busca' }</div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <span className="ml-1">{listLostObject.DETALLE}</span>
                                </span>
                            </div>
                            <div className="text-1xl">
                                <span className="flex align-items-center gap-2">
                                    <span className="ml-1">{listLostObject.LUGAR}</span>
                                </span>
                            </div>
                            <div className="text-1xl">
                                <span className="flex align-items-center gap-2">
                                    <span className="ml-1"><b>Fecha:</b> { formatDate( new Date(listLostObject.FECHA) ) }</span>
                                </span>
                            </div>
                            <div className="flex align-items-center gap-3">
                                <Tag className="p-2" value={listLostObject.ESTADO===2 ?'Reclamado': listLostObject.ESTADO===3?'Entregado' : listLostObject.ESTADO===4?'Archivado':'Registrado' } ></Tag>
                            </div>
                        </div>
                    </div>
                    { isVisible===1 &&
                    <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2" >
                                                   
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning" id={listLostObject.ID} onClick={updateLostObject} ></Button>                        
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" id={listLostObject.ID} onClick={delCofirm} ></Button>        
                    </div>
                    }
                </div>
                <LostObjectDetail showHide={dialogLostObjectDetail} setShowHide={setDialogLostObjectDetail} lostObjectSelected={lostObjectSelected} />
            </div>
        );
    };

    const header = () => {
        return (
            <div>
                <DialogAddEdit setShowHide={setDialogAddEditState} updateTable={updateTable} showHide={dialogAddEditState} lostObjectSelected={lostObjectSelected} ></DialogAddEdit>
                <MenubarTable dialogAddEdit={setDialogAddEditState} ></MenubarTable>
            </div>            
        )
    };    

    return(
        <div className="card">
            <Toast ref={toast}></Toast>
            <DataView value={listLostObject} emptyMessage="No hay registros" itemTemplate={itemTemplate} header={ isVisible===1 && header() } paginator rows={5} />
        </div>
    );
}
