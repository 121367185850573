import "../dialogs/DialogAddEdit.css";
import "primeflex/primeflex.css";
import React from "react";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
/*import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
*/

export const DocumentView = (props) => {
    /*const [numPages, setNumPages] = useState(props.pageNumber);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }*/

    const onError = (e) => {
        console.log(e, "error in file-viewer");
    };

    return (
        <>
            <div className="col-12 text-center">
            </div>
            <div className="col-12">
                <FileViewer fileType={props.typeDocument} filePath={`${process.env.REACT_APP_UPLOAD_URL}/documentsBI/${props.documentName}`} onError={onError} onGridSort={() => null} errorComponent={CustomErrorComponent} />
            </div>
        </>
    );
};
