import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";
import { DocumentoService } from "../../../services/documentosBI/DocumentoService";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { IconFile } from "../icons/IconFile";
import { isAppWeb } from "../../../helpers/system.helper";
import { FileUpload } from "primereact/fileupload";

export const DialogFilter = (props) => {
    const toast = useRef(null);
    const [documentoService] = useState(new DocumentoService());

    const [documents, setDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [filteredDocuments, setFilteredDocuments] = useState(null);
    const [selectedPreviewDoc, setSelectedPreviewDoc] = useState(null);

    const searchCountry = (event) => {
        setTimeout(() => {
            let _filteredDocuments;
            if (!event.query.trim().length) {
                _filteredDocuments = [...documents];
            } else {
                _filteredDocuments = documents.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredDocuments(_filteredDocuments);
        }, 250);
    };

    const itemTemplate = (item) => {
        return (
            <div className="country-item">
                <div>{item.code}</div>
                <div>{item.name}</div>
            </div>
        );
    };

    const defaultEvent = (document) => {
        console.log(document);
    };
    //----------------------------------------------------

    const onSelect = (event) => {
        console.log(event.value);
        setSelectedPreviewDoc(event.value);
    };

    const onHide = () => {
        props.setShowHide(0);
    };

    const onShow = () => {
        documentoService.filter().then((data) => {
            setDocuments(data.data);
        });
    };

    const chooseOptions = {
        label: "Seleccionar documento",
        icon: "pi pi-fw pi-upload",
    };

    const onUploadFile = (file) => {
        // eslint-disable-next-line
        let response = JSON.parse(file.xhr.response);
        //formik.setFieldValue("doc_digital", response.filename);
    };

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header="Filtrar Documento" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                <div className="card">
                    <div className="fluid">
                        <AutoComplete
                            className="w-full"
                            value={selectedDocument}
                            suggestions={filteredDocuments}
                            completeMethod={searchCountry}
                            field="name"
                            dropdown
                            forceSelection
                            itemTemplate={itemTemplate}
                            onChange={(e) => setSelectedDocument(e.value)}
                            aria-label="Documentos"
                            onSelect={onSelect}
                        />
                    </div>
                    <div className="fluid">
                        {selectedPreviewDoc && (
                            <div className="sm:col-12 md:col-12 lg:col-12">
                                <div className="dataViewItem m-2 p-5 border-1 border-solid surface-border card" /* onDoubleClick={() => downloadDocument(data)} */>
                                    <div className="flex align-items-center justify-content-between">
                                        <Button icon="pi pi-folder-open" className="inline-block p-button-rounded p-button-info" aria-label="Descargar.." onClick={(e) => defaultEvent(selectedPreviewDoc)} />

                                        <Button icon="pi pi-cloud-download" className="inline-block p-button-rounded p-button-info" aria-label="Ir a Directorio.." onClick={(e) => defaultEvent(selectedPreviewDoc)} />
                                    </div>
                                    <div className="text-center">
                                        <IconFile data={selectedPreviewDoc} className="mx-5" style={{ fontSize: "10em" }} />
                                        <div className="font-bold text-2xl">{selectedPreviewDoc.name}</div>
                                        <div className="ml-3">
                                            {selectedPreviewDoc.code && (
                                                <>
                                                    <b>Codigo:</b>
                                                    {selectedPreviewDoc.code}
                                                </>
                                            )}
                                        </div>
                                        <div className="ml-3">
                                            {selectedPreviewDoc.version && (
                                                <>
                                                    <b>Version: </b>
                                                    {selectedPreviewDoc.version}
                                                </>
                                            )}
                                        </div>
                                        <div className="ml-3">
                                            {!isAppWeb() && (
                                                <>
                                                    <div className="col-12 md:col-10">
                                                        <sub>Maximo 3MB</sub>
                                                        <FileUpload
                                                            id="doc_digital"
                                                            mode="basic"
                                                            name="doc_digital"
                                                            url={process.env.REACT_APP_UPLOAD_URL}
                                                            chooseOptions={chooseOptions}
                                                            multiple={false}
                                                            onUpload={onUploadFile}
                                                            auto
                                                            maxFileSize="3145728"
                                                            invalidFileSizeMessageSummary="{0} Tamaño de archivo no válido"
                                                            invalidFileSizeMessageDetail="El tamaño máximo de carga es {0}"
                                                            accept=".mp3,.mp4,.webm,.csv,.doc,.docx,.xlsx,.xls,.pdf,image/*"
                                                            className="inputfield w-full"
                                                        ></FileUpload>
                                                        {/* <b>{formik.values.doc_digital}</b>
                                                        {getFormErrorMessage("doc_digital")} */}
                                                    </div>
                                                </>
                                            )}
                                            <b>Ubicación: </b>
                                            /macroprocesos/doc adjunto/estado.pdf
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="p-dialog-footer">
                    <Button label="Cerrar" type="reset" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                </div>
            </Dialog>
        </div>
    );
};
