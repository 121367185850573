import documentos from "../../constants/generico/const.persona";
import { httpGraphql } from "../../helpers/httpClient.helper";

export class PersonaService {
    async getPersonaByIdCertEmit(idCerEmit) {
        const res = await httpGraphql.query(documentos.apiUrl, documentos.queries.getPersonaByCerEmit(idCerEmit));
        let data = res.data.data.personaByCertificadoEmitido;
        return data;
    }
}
