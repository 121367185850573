module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            centros{
                ID
                NOMBRE
                FECHAREG
                USUARIO
                ESTADO
            }`;
        },
        /*getInstitucion: (id) => {
            return `
            institucion(id:${id}){
                RAZON_SOCIAL
                TELEFONO_INS
                DOMICILIO_INST
                REPRESENTANTES{
                    NOMBRE
                }
            }`;
        },*/
    },
};
