import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";

import calConfig from "../../../constants/calendar.config";
import { InstitucionService } from "../../../services/convenios/InstitucionService";
import { InvestigadorService } from "../../../services/convenios/InvestigadorService";

export const DialogAddEditInv = (props) => {
    const [instituciones, setInstituciones] = useState([]);
    const [institucionService] = useState(new InstitucionService());
    const [investigadorService] = useState(new InvestigadorService());

    const formik = useFormik({
        initialValues: {
            cod_empresa: "",
            nombre_rep: "",
            apellidos_rep: "",
            docId_rep: "",
            cargo_rep: "",
        },
        validationSchema: Yup.object({
            cod_empresa: Yup.string(),
            nombre_rep: Yup.string().required("Campo requerido!!"),
            apellidos_rep: Yup.string().required("Campo requerido!!"),
            docId_rep: Yup.string().required("Campo requerido!!"),
            cargo_rep: Yup.string().required("Campo requerido!!"),
        }),
        onSubmit: async (data) => {
            console.log(data);
            if (props.showHide === 1) {
                //Create
                await createData(data);
            } else if (props.showHide === 2) {
                //Update
                await updateData(data);
            }

            formik.resetForm();
            onHide();
            props.loadDataTable();
        },
    });

    const createData = async (data) => {
        investigadorService.create(data);
    };

    const updateData = async (data) => {
        investigadorService.update(props.selectedConvenio.ID, data);
    };

    addLocale(calConfig.sp.name, calConfig.sp.config);

    const onInstitucionChange = (e) => {
        formik.handleChange(e);
    };

    const selectedInstitucionTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.RAZON_SOCIAL}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const institucionOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.RAZON_SOCIAL}</div>
            </div>
        );
    };

    const onHide = () => {
        formik.resetForm();
        props.setShowHide(0);
    };

    const onShow = () => {
        formik.resetForm();
        if (props.selectedConvenio && props.showHide === 2) {
            formik.setFieldValue(
                "cod_empresa",
                props.selectedConvenio.INSTITUCION
                    ? props.selectedConvenio.INSTITUCION.COD_INSTITUCION
                    : ''
            );
            formik.setFieldValue("nombre_rep", props.selectedConvenio.NOMBRE);
            formik.setFieldValue(
                "apellidos_rep",
                props.selectedConvenio.APELLIDOS
            );
            formik.setFieldValue("docId_rep", props.selectedConvenio.CARNET);
            formik.setFieldValue("cargo_rep", props.selectedConvenio.CARGO);
        }
    };

    useEffect(() => {
        institucionService.getInstituciones().then((data) => {
            setInstituciones(data);
        });
    }, [institucionService]);

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <div>
            <Dialog
                header="Agregar/Editar Investigador"
                closeOnEscape={false}
                visible={props.showHide > 0}
                style={{ width: "50vw" }}
                onHide={() => onHide()}
                onShow={() => onShow()}
            >
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="card">
                        <h3>Datos de Empresa</h3>
                        <div className="p-fluid">
                            <div className="p-field p-grid">
                                <label
                                    htmlFor="cod_empresa"
                                    className="p-col-12 p-md-2"
                                >
                                    Nombre:
                                </label>
                                <div className="p-col-12 p-md-10">
                                    <Dropdown
                                        id="cod_empresa"
                                        name="cod_empresa"
                                        value={formik.values.cod_empresa}
                                        options={instituciones}
                                        onChange={onInstitucionChange}
                                        optionLabel="RAZON_SOCIAL"
                                        optionValue="COD_INSTITUCION"
                                        filter
                                        showClear
                                        filterBy="RAZON_SOCIAL"
                                        placeholder="Selecione una empresa"
                                        valueTemplate={
                                            selectedInstitucionTemplate
                                        }
                                        itemTemplate={institucionOptionTemplate}
                                    />
                                    {getFormErrorMessage("cod_empresa")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h3>Datos de Investigador</h3>
                        <div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label
                                        htmlFor="nombre_rep"
                                        className="p-col-12 p-md-2"
                                    >
                                        Nombre(s)
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText
                                            id="nombre_rep"
                                            name="nombre_rep"
                                            type="text"
                                            value={formik.values.nombre_rep}
                                            onChange={formik.handleChange}
                                        />
                                        {getFormErrorMessage("nombre_rep")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label
                                        htmlFor="apellidos_rep"
                                        className="p-col-12 p-md-2"
                                    >
                                        Apellido(s)
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText
                                            id="apellidos_rep"
                                            name="apellidos_rep"
                                            type="text"
                                            value={formik.values.apellidos_rep}
                                            onChange={formik.handleChange}
                                        />
                                        {getFormErrorMessage("apellidos_rep")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label
                                        htmlFor="docId_rep"
                                        className="p-col-12 p-md-2"
                                    >
                                        Doc. Identificación
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText
                                            id="docId_rep"
                                            name="docId_rep"
                                            type="text"
                                            value={formik.values.docId_rep}
                                            onChange={formik.handleChange}
                                        />
                                        {getFormErrorMessage("docId_rep")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label
                                        htmlFor="cargo_rep"
                                        className="p-col-12 p-md-2"
                                    >
                                        Cargo
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText
                                            id="cargo_rep"
                                            name="cargo_rep"
                                            type="text"
                                            value={formik.values.cargo_rep}
                                            onChange={formik.handleChange}
                                        />
                                        {getFormErrorMessage("cargo_rep")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            onClick={() => onHide()}
                            className="p-button-text"
                        />
                        <Button
                            type="submit"
                            label="Guardar"
                            icon="pi pi-save"
                            autoFocus
                        />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
