import React, { useState, useEffect } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

import "./DialogAll.css";
import "primeflex/primeflex.css";

import { CursoService } from "../../../services/teachertraining/CursoService";

export const ConfirmToUnsubscribe = (props) => {
    const [cursoService] = useState(new CursoService());
    const [curso, setCurso] = useState(null);

    useEffect(() => {
        //console.log(props.selectedIdData);
        //console.log(props.showHide);
    });

    const onHide = () => {
        setCurso(null);
        props.setShowHide(0);
    };

    const onShow = async () => {
        console.log(props.selectedIdData);
        const actCurso = await cursoService.getCurso(props.selectedIdData);
        setCurso(actCurso);
    };

    const accept = async () => {
        console.log(props.selectedIdData);
        await cursoService.desuscribirse({ idCurso: props.selectedIdData });
        onHide();
        props.loadAllData();
    };

    return <ConfirmDialog visible={props.showHide > 0} onHide={() => onHide()} onShow={() => onShow()} message={`¿Está seguro de cancelar la suscripción? "${curso && curso.NOMBRE}"`} header="Confirmación" icon="pi pi-exclamation-triangle" accept={accept} reject={onHide} />;
};
