import React from "react";
import {Menu} from "primereact/menu";
import '../../assets/css/graduationProcess.scss';

const DocumentsDownload = ()=>{
    const links = [
        {
            label: 'EMISIÓN DE DIPLOMA ACADÉMICO',
            items:[
                {
                    label:'Requisitos para Emisión de Diploma Académico',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/REQUISITOS_PARA_EMISION_DE_DIPLOMA_ACADEMICO_2024.pdf',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                },
                {
                    label:'Carta Solicitud de Diploma Académico',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/CARTA_SOLICITUD_DE_DIPLOMA_ACADEMICO.docx',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                }
            ],
            className:'title-enlace-grad-process'
        },
        {
            label:'CERTIFICADO SUPLETORIO DE DIPLOMA ACADÉMICO',
            items:[
                {
                    label:'Requisitos para Certificado Supletorio de Diploma Académico',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/REQUISITOS_PARA_CERTIFICADO_SUPLETORIO_DE_DIPLOMA_ACADEMICO.pdf',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                }
            ],
            className:'title-enlace-grad-process'
        },
        {
            label:'TRÁMITE DE TÍTULO PROFESIONAL EN PROVISIÓN NACIONAL',
            items:[
                {
                    label:'Requisitos para Trámite de Título Profesional en Provisión Nacional',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/REQUISITOS_PARA_TRAMITE_DE_TITULO_PROFESIONAL_EN_PROVISION_NACIONAL.pdf',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                }
            ],
            className:'title-enlace-grad-process'
        },
        {
            label:'LEGALIZACIÓN DE DIPLOMA ACADÉMICO',
            items:[
                {
                    label:'Requisitos para Copia Legalizada de Diploma Académico',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/REQUISITOS_PARA_COPIA_LEGALIZADA_DE_DIPLOMA_ACADEMICO.pdf',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                }                
            ],
            className:'title-enlace-grad-process'
        },
        {
            label:'LEGALIZACIÓN DE ACTA DE DEFENSA DE TRABAJO FINAL DE GRADO O EXCELENCIA',
            items:[
                {
                    label:'Requisito para Copia Legalizada de Acta de Defensa de Grado',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/COPIA_LEGALIZADA_DE_ACTA_DE_DEFENSA_DE_GRADO.pdf',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                }
            ],
            className:'title-enlace-grad-process'
        },
        {
            label:'CERTIFICADOS',
            items:[
                {
                    label:'Tipos de Certificados',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/CERTIFICADOS.pdf',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                }
            ],
            className:'title-enlace-grad-process'
        },
        {
            label:'MODALIDAD DE TITULACIÓN TRABAJO DIRIGIDO SUBSEDE LA PAZ',
            items:[
                {
                    label:'Requisitos Modalidad Trabajo Dirigido',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/REQUISITOS_MODALIDAD_DE_TITULACION_TRABAJO_DIRIGIDO_LPZ.pdf',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                },
                {
                    label:'Formulario Anexo al Convenio Trabajo Dirigido',
                    icon:'pi pi-download',
                    url:'secretariaGeneral/GR_ES_F_21_Anexo_al_Convenio_TRABAJO_DIRIGIDO.xls',
                    download: true,
                    target: '_blank',
                    className: 'enlace-grad-process'
                }
            ],
            className:'title-enlace-grad-process'
        }
    ];

    return(
        <div className="card">
            <h5>DOCUMENTACIÓN</h5>
            <div className="flex justify-content-center">                
                <Menu className="col-12" model={links} />
            </div>
        </div>
    );
}

export { DocumentsDownload };