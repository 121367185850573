module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {        
        getAll: () => {
            return `
            allGetGraduates{
                NOM_CARRERA
                FULL_NAME
                AL_PER_COD_PERSONA
                FECHA_TIT
                CAMPUS_GRAD
                COD_AULA
                NOMBRE_AU 
                HORA_DEFENSA
            }`;
        },        
    },   
};