import { formatDate } from "../date.helper";

const iconDocument = {
    file: "pi pi-fw pi-file", //default file
    folder: "pi pi-fw pi-folder",
    folder2: "pi pi-fw pi-folder-plus",
    pdf: "pi pi-fw pi-file-pdf",
    xls: "pi pi-fw pi-file-excel",
    xlsx: "pi pi-fw pi-file-excel",
    png: "pi pi-fw pi-image",
    jpg: "pi pi-fw pi-image",
    gif: "pi pi-fw pi-image",
    jpeg: "pi pi-fw pi-image",
};

const documentDefaulValue = { key: -1, label: "" };

const transformTreeNode = (document) => {
    let nodeFile = {
        key: document.ID,
        parent: document.ID_DOC,
        label: document.NOMBRE,
        data: document.DESCRIPCION,
        type: document.TIPO,
        link: document.RUTA,
        iframe: document.ENLACE,
        position: document.INDICE,
        version: document.VERSION,
    };

    nodeFile.icon = iconDocument[document.EXTENSION] ? iconDocument[document.EXTENSION] : iconDocument.file;

    if (document.TIPO === 1) {
        nodeFile.icon = iconDocument.folder;
        nodeFile.children = [documentDefaulValue];
    }
    return nodeFile;
};

const transformViewFile = (document) => {
    // ID, ID_DOC, CODIGO, NOMBRE, ENLACE, DESCRIPCION, VERSION, RUTA, TAMANIO, EXTENSION, TIPO
    const dtUpdt = document.FECHAREG ? formatDate(new Date(parseInt(document.FECHAREG))) : null;

    let nodeFile = {
        id: document.ID,
        parent: document.ID_DOC,
        code: document.CODIGO,
        name: document.NOMBRE,
        link: document.RUTA,
        iframe: document.ENLACE,
        description: document.DESCRIPCION,
        position: document.INDICE,
        size: document.TAMANIO ? `${document.TAMANIO.toFixed(2)}Mb` : null,
        extension: document.EXTENSION,
        type: document.TIPO,
        version: document.VERSION,
        dateUpdate: dtUpdt,
    };
    return nodeFile;
};

const openFile = (document, directory) => {
    window.open(`${process.env.REACT_APP_UPLOAD_URL}/${directory}/${document.link}`, "_blank");
};

export { transformTreeNode, transformViewFile, documentDefaulValue, iconDocument, openFile };
