import React, { useRef } from "react";
import { Menubar } from "primereact/menubar";
import { Toast } from "primereact/toast";
import { faFlask } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MenubarInvTable = (props) => {
    const toast = useRef(null);
    const items = [
        {
            label: "Nuevo",
            icon: "pi pi-fw pi-plus",
            command: () => {
                props.setSelectedConvenio(null);
                props.dialogAddEdit(1);
            },
        },
        {
            label: "Editar",
            icon: "pi pi-fw pi-pencil",
            //url: "https://www.primefaces.org/primereact",
            command: () => {
                if (props.selectedConvenio) {
                    props.dialogAddEdit(2);
                } else {
                    toastSelectRow();
                }
            },
        },
        {
            label: "Eliminar",
            icon: "pi pi-fw pi-trash",
            command: () => {
                if (!props.selectedConvenio) {
                    toastSelectRow();
                    return;
                }

                if (
                    props.selectedConvenio.ESTUDIANTES &&
                    props.selectedConvenio.ESTUDIANTES.length
                ) {
                    toast.current.show({
                        severity: "warn",
                        summary: "Estudiantes",
                        detail: "Existe estudiante en este convenio!!",
                    });
                    return;
                }

                props.dialogDelete();
            },
        },
        /*{
            label: "Estudiantes",
            icon: "pi pi-fw pi-id-card",
            command: () => {
                if (props.selectedConvenio) {
                    props.dialogStudents(1);
                } else {
                    toastSelectRow();
                }
            },
        },
        {
            label: "Exportar",
            icon: "pi pi-fw pi-cloud-download",
            items: [
                {
                    label: "PDF",
                    icon: "pi pi-fw pi-file-pdf",
                    command: () => {
                        props.exportDataTable(1);
                    },
                },
                {
                    label: "XLS",
                    icon: "pi pi-fw pi-file-excel",
                    command: () => {
                        props.exportDataTable(2);
                    },
                },
                {
                    label: "CSV",
                    icon: "pi pi-fw pi-file-o",
                    command: () => {
                        props.exportDataTable(3);
                    },
                },
            ],
        },*/
    ];

    const toastSelectRow = () => {
        toast.current.show({
            severity: "warn",
            summary: "Seleccione",
            detail: "Seleccione una fila de la tabla!!",
        });
    };

    const start = (
        <div>
            <h1
                style={{
                    color: "var(--blue-700)",
                    borderBottom: "3px dashed",
                }}
            >
                Investigadores{" "}
                <FontAwesomeIcon
                    icon={faFlask}
                    style={{ fontSize: "1em", color: "var(--yellow-600)" }}
                />
            </h1>
        </div>
    );

    return (
        <div>
            <Toast ref={toast}></Toast>
            <div className="card">
                <Menubar model={items} start={start} end={props.inputSearch} />
            </div>
        </div>
    );
};
