const formatDate = (value) => {
    return value.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "America/La_Paz",
    });
};

const formatHours = (value) => {
    return `${value.toLocaleString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false, timeZone: "America/La_Paz" })}`;
};

const sumDate = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
};

const setZeroHours = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};

export { formatDate, formatHours, sumDate, setZeroHours };
