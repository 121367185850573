import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (props) => {
    //const navigate = useNavigate();
    let location = useLocation();

    // navigate("/targetPath")
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    return props.children;
}

export default ScrollToTop;
