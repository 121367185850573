import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Chip } from "primereact/chip";
import { faChalkboardTeacher, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./DialogAll.css";
import "primeflex/primeflex.css";

import { CursoService } from "../../../services/teachertraining/CursoService";
import { formatDate } from "../../../helpers/date.helper";
import { isFullCourse, isRegistrable } from "../../../helpers/formacionDoc.helper";
import { Badge } from "primereact/badge";

export const DialogToSubscribe = (props) => {
    const [cursoService] = useState(new CursoService());
    const [curso, setCurso] = useState(null);

    const formik = useFormik({
        initialValues: {
            idCurso: 0,
        },
        //validationSchema: Yup.object({}),
        onSubmit: async (data) => {
            data.idCurso = props.selectedIdData;
            console.log(data);
            await cursoService.suscribirse(data);

            formik.resetForm();
            onHide();
            props.loadAllData();
        },
    });

    const onHide = () => {
        formik.resetForm();
        setCurso(null);
        props.setShowHide(0);
    };

    const onShow = async () => {
        const actCurso = await cursoService.getCurso(props.selectedIdData);
        setCurso(actCurso);
    };

    return (
        <div>
            <Dialog header="Información/Suscripción a Capacitación" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                {curso && (
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="card">
                            <div>
                                <div className="grid">
                                    <h1>
                                        <FontAwesomeIcon icon={faChalkboardTeacher} />
                                        &nbsp;{curso.NOMBRE} [{curso.NRO_HORAS} hrs]
                                        <Chip label={props.tipoCurso[curso.TIPO]} icon="pi pi-check " className="mr-2 mb-2 custom-chip" />
                                    </h1>
                                    <div className="text-lg text-primary col-12 md:col-2">Objetivo</div>
                                    <p className="parrafo col-12 md:col-10">{curso.OBJETIVO}</p>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Capacitadores</div>
                                    <div className="col-12 md:col-10">
                                        <ul className="my-0">
                                            {curso.CAPACITADORES.map((item) => {
                                                return (
                                                    <li>
                                                        {item.NOMBRE} {item.AP_PATERNO} {item.AP_MATERNO}
                                                        &nbsp;<Badge value={item.TIPO === "INTERNO" ? "UPB" : item.TIPO} severity="success"></Badge>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Fecha(s)</div>
                                    <div className="col-12 md:col-10">
                                        del {formatDate(new Date(parseInt(curso.FECHAINI)))} al {formatDate(new Date(parseInt(curso.FECHAFIN)))}
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Horarios</div>
                                    <div className="col-12 md:col-10">
                                        de {curso.HORAINI} a {curso.HORAFIN} hrs.
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Cupo</div>
                                    <div className="col-12 md:col-10">{curso.CUPO} Pers.</div>
                                </div>
                                <div className="grid">
                                    <div className="text-lg text-primary col-12 md:col-2">Cupo disponible</div>
                                    <div className="col-12 md:col-10">{curso.CUPO - curso.NUM_SUSCRITOS} Pers.</div>
                                </div>
                                {
                                    !(!isFullCourse(curso) && isRegistrable(curso)) &&
                                    <div>
                                        <br />
                                        <div className="grid bg-yellow-500">
                                            <div className="text-xl text-danger col-12 md:col-12">
                                                <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                                                Las suscripciones estarán disponibles una semana antes de la fecha <b>{formatDate(new Date(parseInt(curso.FECHAINI)))}</b>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="p-dialog-footer">
                            <input name="idCurso" type="hidden" />
                            <Button label={isRegistrable(curso) ? "Cancelar" : "Cerrar"} icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                            {!isFullCourse(curso) && isRegistrable(curso) && <Button type="submit" label="Suscribirse" icon="pi pi-save" />}
                        </div>
                    </form>
                )}
            </Dialog>
        </div>
    );
};
