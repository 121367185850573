import React from "react";
import {TableDefensePrograming} from "../components/graduationProcess/TableDefensePrograming";
import { DocumentsDownload } from "../components/graduationProcess/DocumentsDownload";


const GraduationProcess = ()=>{
    return(
        <div className="card">
            {/*}<h5>Proceso de Graduación Secretaria General</h5>{*/}
            <div className="flex flex-wrap gap-3 justify-content-center">
                <div className="relative bg-primary-200 md:my-0 border-round">
                    <TableDefensePrograming/>
                </div>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <div className="relative bg-primary-200 md:my-0 border-round">
                    <DocumentsDownload />
                </div>
            </div>
        </div>
    );
};

export { GraduationProcess };