import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./DialogAddEdit.css";
import "primeflex/primeflex.css";

import calConfig from "../../../constants/calendar.config";
import { InstitucionService } from "../../../services/empresas/InstitucionService";

export const DialogAddEdit = (props) => {
    const [institucionService] = useState(new InstitucionService());

    const formik = useFormik({
        initialValues: {
            razon_social: "",
            documento_legal: "",
            telefono_ins: "",
            fax_inst: "",
            casilla_inst: "",
            email_inst: "",
            url_inst: "",
            domicilio_inst: "",
            ruc: "",
        },
        validationSchema: Yup.object({
            razon_social: Yup.string().required("Campo requerido!!"),
            documento_legal: Yup.string().required("Campo requerido!!"),
            telefono_ins: Yup.string().required("Campo requerido!!"),
            fax_inst: Yup.string().required("Campo requerido!!"),
            casilla_inst: Yup.string().required("Campo requerido!!"),
            email_inst: Yup.string().email("Correo no valido").required("Campo requerido!!"),
            url_inst: Yup.string().url("Pagina no valida").required("Campo requerido!!"),
            domicilio_inst: Yup.string().required("Campo requerido!!"),
            ruc: Yup.string().required("Campo requerido!!"),
        }),
        onSubmit: async (data) => {
            console.log(data);
            if (props.showHide === 1) {
                //Create
                await createData(data);
            } else if (props.showHide === 2) {
                //Update
                await updateData(data);
            }

            formik.resetForm();
            onHide();
            props.loadDataTable();
        },
    });

    const createData = async (data) => {
        institucionService.create(data);
    };

    const updateData = async (data) => {
        institucionService.update(props.selectedConvenio.ID, data);
    };

    addLocale(calConfig.sp.name, calConfig.sp.config);

    const onHide = () => {
        formik.resetForm();
        props.setShowHide(0);
    };

    const onShow = () => {
        formik.resetForm();
        if (props.selectedConvenio && props.showHide === 2) {
            /*formik.setFieldValue("cod_empresa", props.selectedConvenio.INSTITUCION ? props.selectedConvenio.INSTITUCION.COD_INSTITUCION : "");
            formik.setFieldValue("nombre_rep", props.selectedConvenio.NOMBRE);
            formik.setFieldValue("apellidos_rep", props.selectedConvenio.APELLIDOS);
            formik.setFieldValue("docId_rep", props.selectedConvenio.CARNET);
            formik.setFieldValue("cargo_rep", props.selectedConvenio.CARGO);*/
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div>
            <Dialog header="Agregar/Editar Empresa" closeOnEscape={false} visible={props.showHide > 0} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="card">
                        <div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="razon_social" className="p-col-12 p-md-2">
                                        Razon Social
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="razon_social" name="razon_social" type="text" value={formik.values.razon_social} onChange={formik.handleChange} />
                                        {getFormErrorMessage("razon_social")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="documento_legal" className="p-col-12 p-md-2">
                                        Documento Legal
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="documento_legal" name="documento_legal" type="text" value={formik.values.documento_legal} onChange={formik.handleChange} />
                                        {getFormErrorMessage("documento_legal")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="telefono_ins" className="p-col-12 p-md-2">
                                        Telefono
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="telefono_ins" name="telefono_ins" type="text" value={formik.values.telefono_ins} onChange={formik.handleChange} />
                                        {getFormErrorMessage("telefono_ins")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="fax_inst" className="p-col-12 p-md-2">
                                        Fax
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="fax_inst" name="fax_inst" type="text" value={formik.values.fax_inst} onChange={formik.handleChange} />
                                        {getFormErrorMessage("fax_inst")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="casilla_inst" className="p-col-12 p-md-2">
                                        Nro. de Casilla
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="casilla_inst" name="casilla_inst" type="text" value={formik.values.casilla_inst} onChange={formik.handleChange} />
                                        {getFormErrorMessage("casilla_inst")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="email_inst" className="p-col-12 p-md-2">
                                        Correo Institucional
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="email_inst" name="email_inst" type="text" value={formik.values.email_inst} onChange={formik.handleChange} />
                                        {getFormErrorMessage("email_inst")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="url_inst" className="p-col-12 p-md-2">
                                        Pagina Web
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="url_inst" name="url_inst" type="text" value={formik.values.url_inst} onChange={formik.handleChange} />
                                        {getFormErrorMessage("url_inst")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="domicilio_inst" className="p-col-12 p-md-2">
                                        Domicilio
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="domicilio_inst" name="domicilio_inst" type="text" value={formik.values.domicilio_inst} onChange={formik.handleChange} />
                                        {getFormErrorMessage("domicilio_inst")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="ruc" className="p-col-12 p-md-2">
                                        RUC
                                    </label>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="ruc" name="ruc" type="text" value={formik.values.ruc} onChange={formik.handleChange} />
                                        {getFormErrorMessage("ruc")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                        <Button type="submit" label="Guardar" icon="pi pi-save" autoFocus />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
