module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            publicaciones{
                ID
                TITULO
                DESCRIPCION
                ESTADO_PUB
                FECHA_PUB
                FECHA_ACEPT
                ENLACE
                RESUMEN
                FECHA_PRES
                LUGAR_PRES
                MODALIDAD
                ORGANIZADOR
                DOC_DIGITAL
                TIPO_DOC
                ENLACE_SCOPUS
                TIPO_PUBLICACION{
                    ID
                    NOMBRE
                }
                EDITORIAL{
                    ID
                    NOMBRE
                }
                CENTRO_INVESTIGACION{
                    ID
                    NOMBRE
                }
                AUTORES{
                    ID
                    NOMBRES
                    TIPO
                }
                METRICAS{
                    ID
                    ORDEN
                    NOMBRE
                    CANTIDAD
                }
                AREAS_ESPECIALIDAD{
                    ID
                    NOMBRE
                }
                INDEXACIONES{
                    ID
                    NOMBRE
                }
            }`;
        },
        getAllByFilter: (data) => {
            let fecha_pub = "";
            if (data.pub_fecha) {
                fecha_pub = data.pub_fecha
                    .filter((element) => element !== null)
                    .map((element) => `"${element}"`)
                    .join(",");
                fecha_pub = `FECHA_PUB:[${fecha_pub}]`;
            }
            return `
            publicacionesByFilter(
                filtro: {
                    ${data.tipoPublicacion ? `ID_TIPO: ${data.tipoPublicacion}` : ``}
                    ${data.id_centro ? `ID_CENTRO: ${data.id_centro}` : ``}
                    ${data.id_autor ? `ID_AUTOR: ${data.id_autor}` : ``}
                    ${data.pub_titulo ? `TITULO: "${data.pub_titulo}"` : ``}
                    ${fecha_pub}
                }
            ){
                ID
                TITULO
                DESCRIPCION
                ESTADO_PUB
                FECHA_PUB
                FECHA_ACEPT
                ENLACE
                RESUMEN
                FECHA_PRES
                LUGAR_PRES
                MODALIDAD
                ORGANIZADOR
                DOC_DIGITAL
                TIPO_DOC
                ENLACE_SCOPUS
                TIPO_PUBLICACION{
                    ID
                    NOMBRE
                }
                EDITORIAL{
                    ID
                    NOMBRE
                }
                CENTRO_INVESTIGACION{
                    ID
                    NOMBRE
                }
                AUTORES{
                    ID
                    NOMBRES
                    TIPO
                }
                METRICAS{
                    ID
                    ORDEN
                    NOMBRE
                    CANTIDAD
                }
                AREAS_ESPECIALIDAD{
                    ID
                    NOMBRE
                }
                INDEXACIONES{
                    ID
                    NOMBRE
                }
            }`;
        },
        getOne: (id) => {
            return `
            convenio(id:${id}){
                ID
                TITULO
                DESCRIPCION
                ESTADO_PUB
                FECHA_PUB
                FECHA_ACEPT
                ENLACE
                RESUMEN
                FECHA_PRES
                LUGAR_PRES
                MODALIDAD
                ORGANIZADOR
                DOC_DIGITAL
                TIPO_DOC
                ENLACE_SCOPUS
                TIPO_PUBLICACION{
                    ID
                    NOMBRE
                }
                EDITORIAL{
                    ID
                    NOMBRE
                }
                CENTRO_INVESTIGACION{
                    ID
                    NOMBRE
                }
                AUTORES{
                    ID
                    NOMBRES
                    TIPO
                }
                METRICAS{
                    ID
                    ORDEN
                    NOMBRE
                    CANTIDAD
                }
                AREAS_ESPECIALIDAD{
                    ID
                    NOMBRE
                }
                INDEXACIONES{
                    ID
                    NOMBRE
                }
            }`;
        },

        create: (data) => {
            const regex = new RegExp('"', "g");

            const autor = `{ ID_AUTOR: ${data.id_autor} TIPO: 1 }`;
            const co_autores = data.co_autores.map((autor) => {
                return `{ ID_AUTOR: ${autor.ID} TIPO: 2 }`;
            });
            const editor = `{ ID_AUTOR: ${data.id_editor} TIPO: 3 }`;
            const co_editores = data.co_editores.map((editor) => {
                return `{ ID_AUTOR: ${editor.ID} TIPO: 4 }`;
            });
            let allAutores = co_autores.concat(co_editores).concat([autor]).concat([editor]);

            const areasEspecialidad = data.areasEspecialidad
                .map((area) => {
                    return `
                {
                    ID_AREA_ESP: ${area.ID}
                }
                `;
                })
                .join(",");
            const indexaciones = data.indexaciones
                .map((indexacion) => {
                    return `
                {
                    ID_IDX: ${indexacion.ID}
                }`;
                })
                .join(",");
            return `
            createPublicacion(publicacion:{
                TITULO: "${data.pub_titulo.replace(regex, "'")}"
                DESCRIPCION: "${data.pub_descripcion.replace(regex, "'")}"
                FECHA_PUB: "${data.pub_fecha.toJSON()}"
                ENLACE: "${data.pub_link.replace(regex, "'")}"
                TIPO_PUBLICACION: ${data.tipoPublicacion}
                ${data.pub_editorial ? `EDITORIAL: ${data.pub_editorial}` : ``}
                ${data.fecha_pres ? `FECHA_PRES: "${data.fecha_pres}"` : ``}
                ${data.lugar_pres ? `LUGAR_PRES: "${data.lugar_pres.replace(regex, "'")}"` : ``}
                ${data.modalidad ? `MODALIDAD: ${data.modalidad}` : ``}
                ${data.tipo_doc ? `TIPO_DOC: ${data.tipo_doc}` : ``}
                ${data.link_scopus ? `ENLACE_SCOPUS: "${data.link_scopus.replace(regex, "'")}"` : ``}
                ${data.organizador ? `ORGANIZADOR: "${data.organizador.replace(regex, "'")}"` : ``}
                ${data.doc_digital ? `DOC_DIGITAL: "${data.doc_digital.replace(regex, "'")}"` : ``}
                CENTRO_INVESTIGACION: ${data.id_centro}
                ESTADO_PUB: ${data.pub_estadoPub}
                AUTORES: [ ${allAutores.join(",")}]
                ${areasEspecialidad ? "AREAS_ESPECIALIDAD: [ " + areasEspecialidad + " ]" : ""}
                ${indexaciones ? "INDEXACIONES: [ " + indexaciones + " ]" : ""}
                METRICAS: [{
                    ID_METRICA: 1
                    CANTIDAD: ${data.indicador_sjr}
                },
                {
                    ID_METRICA: 2
                    CANTIDAD: ${data.indicador_jcr}
                },
                {
                    ID_METRICA: 3
                    CANTIDAD: ${data.indicador_cs}
                },
                {
                    ID_METRICA: 4
                    CANTIDAD: ${data.indicador_q}
                },
                {
                    ID_METRICA: 5
                    CANTIDAD: ${data.indicador_h5}
                },
                {
                    ID_METRICA: 6
                    CANTIDAD: ${data.indicador_is}
                }]
            }){
                ID
            }`;
        },

        update: (id, data) => {
            const regex = new RegExp('"', "g");

            const autor = `{ ID_AUTOR: ${data.id_autor} TIPO: 1 }`;
            const co_autores = data.co_autores.map((autor) => {
                return `{ ID_AUTOR: ${autor.ID} TIPO: 2 }`;
            });
            const editor = `{ ID_AUTOR: ${data.id_editor} TIPO: 3 }`;
            const co_editores = data.co_editores.map((editor) => {
                return `{ ID_AUTOR: ${editor.ID} TIPO: 4 }`;
            });
            let allAutores = co_autores.concat(co_editores).concat([autor]).concat([editor]);

            const areasEspecialidad = data.areasEspecialidad
                .map((area) => {
                    return `
                {
                    ID_AREA_ESP: ${area.ID}
                }
                `;
                })
                .join(",");
            const indexaciones = data.indexaciones
                .map((indexacion) => {
                    return `
                {
                    ID_IDX: ${indexacion.ID}
                }`;
                })
                .join(",");
            return `
            updatePublicacion(id:${id} publicacion:{
                TITULO: "${data.pub_titulo.replace(regex, "'")}"
                DESCRIPCION: "${data.pub_descripcion.replace(regex, "'")}"
                FECHA_PUB: "${data.pub_fecha.toJSON()}"
                ENLACE: "${data.pub_link.replace(regex, "'")}"
                TIPO_PUBLICACION: ${data.tipoPublicacion}
                ${data.pub_editorial ? `EDITORIAL: ${data.pub_editorial}` : ``}
                CENTRO_INVESTIGACION: ${data.id_centro}
                ESTADO_PUB: ${data.pub_estadoPub}
                ${data.tipo_doc ? `TIPO_DOC: ${data.tipo_doc}` : ``}
                ${data.link_scopus ? `ENLACE_SCOPUS: "${data.link_scopus.replace(regex, "'")}"` : ``}
                ${data.fecha_pres ? `FECHA_PRES: "${data.fecha_pres}"` : ``}
                ${data.lugar_pres ? `LUGAR_PRES: "${data.lugar_pres.replace(regex, "'")}"` : ``}
                ${data.modalidad ? `MODALIDAD: ${data.modalidad}` : ``}
                ${data.organizador ? `ORGANIZADOR: "${data.organizador.replace(regex, "'")}"` : ``}
                ${data.doc_digital ? `DOC_DIGITAL: "${data.doc_digital.replace(regex, "'")}"` : ``}
                AUTORES: [ ${allAutores.join(",")}]
                ${areasEspecialidad ? "AREAS_ESPECIALIDAD: [ " + areasEspecialidad + " ]" : ""}
                ${indexaciones ? "INDEXACIONES: [ " + indexaciones + " ]" : ""}
                METRICAS: [{
                    ID_METRICA: 1
                    CANTIDAD: ${data.indicador_sjr}
                },
                {
                    ID_METRICA: 2
                    CANTIDAD: ${data.indicador_jcr}
                },
                {
                    ID_METRICA: 3
                    CANTIDAD: ${data.indicador_cs}
                },
                {
                    ID_METRICA: 4
                    CANTIDAD: ${data.indicador_q}
                },
                {
                    ID_METRICA: 5
                    CANTIDAD: ${data.indicador_h5}
                },
                {
                    ID_METRICA: 6
                    CANTIDAD: ${data.indicador_is}
                }]
            }){
                ID
            }`;
        },

        delete: (id) => {
            return `
            deletePublicacion(id:${id}){
                ID
                ESTADO
            }`;
        },
    },
};
