import React from "react";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            Copyright&nbsp;
            <FontAwesomeIcon icon={faCopyright} style={{ fontSize: "2em", color: "var(--yellow-600)" }} />
            &nbsp;2022&nbsp;<span className="font-medium ml-2">UPB.</span>&nbsp;Todos los derechos reservados
        </div>
    );
};
