module.exports = {
    apiUrl: process.env.REACT_APP_GRAPHQL,
    queries: {
        getAll: () => {
            return `
            indexaciones{
                ID
                NOMBRE
            }`;
        },

        getOne: (id) => {
            return `
            indexacion(id:${id}){
                ID
                NOMBRE
            }`;
        },

        create: (data) => {
            return `
            createIndexacion(indexacion:{
                NOMBRE: "${data.ed_nombre}"
            }){
                ID
            }`;
        },
    },
};
