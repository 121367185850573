import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFileWord, faFilePowerpoint, faFileExcel, faFileCsv, faFilePdf, faFileImage, faFileVideo, faFileAudio, faFileArchive, faFile, faLink } from "@fortawesome/free-solid-svg-icons";

export const IconFile = (props) => {
    const [iconsFile] = useState({
        dir: faFolder,
        doc: faFileWord,
        ppt: faFilePowerpoint,
        xls: faFileExcel,
        docx: faFileWord,
        pptx: faFilePowerpoint,
        xlsx: faFileExcel,
        csv: faFileCsv,
        pdf: faFilePdf,
        jpg: faFileImage,
        png: faFileImage,
        gif: faFileImage,
        mp4: faFileVideo,
        avi: faFileVideo,
        mkv: faFileVideo,
        mp3: faFileAudio,
        zip: faFileArchive,
        rar: faFileArchive,
        link: faLink,
        other: faFile,
    });

    const iconFileType = (data) => {
        let key = "other";
        if (data.type === 1) {
            key = "dir";
        } else if (data.type === 3) {
            key = "link";
        } else if (data.type === 2 && iconsFile[data.extension]) {
            key = data.extension;
        }
        return iconsFile[key];
    };

    return <FontAwesomeIcon icon={iconFileType(props.data)} style={props.style} />;
};
