import "../dialogs/DialogAddEdit.css";
import "primeflex/primeflex.css";
import React from "react";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
/*import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
*/

export const DocumentView = (props) => {
    /*const [numPages, setNumPages] = useState(props.pageNumber);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }*/

    const onError = (e) => {
        console.log(e, "error in file-viewer");
    };

    return (
        <>
            <div className="col-12 text-center">
                {/*
                <Button icon="pi pi-step-backward" className="p-button-rounded" aria-label="Atras" onClick={() => setPageNumber((prevNumber) => prevNumber - 1)} />
                <Chip label={`Pagina ${pageNumber} de ${numPages}`} className="custom-chip" />
                <Button icon="pi pi-step-forward" className="p-button-rounded" aria-label="Adelante" onClick={() => setPageNumber((prevNumber) => prevNumber + 1)} />
                */}
            </div>
            <div className="col-12">
                {/* <Document file="http://localhost:5000/upload/qualityManagement/03ReglamentoEstudiantilPregrado.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                </Document> */}
                <FileViewer fileType={props.typeDocument} filePath={`${process.env.REACT_APP_UPLOAD_URL}/qualityManagement/${props.documentName}`} onError={onError} onGridSort={() => null} errorComponent={CustomErrorComponent} />
            </div>
        </>
    );
};
