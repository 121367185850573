import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from 'primereact/calendar';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { LostObjectsService } from "../../../services/lostObjects/LostObjectsService";
import DropDowCities from "./DropDowCities";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from 'primereact/dropdown';
import * as Yup from "yup";

export const DialogAddEdit = ( props ) => {

    const toast = useRef(null);
    const {selectCity}=useState('');

    //Definimos de que tipo es el registro
    const tipos = [
        {name: 'HALLAZGO', code: 1},
        {name: 'BUSQUEDA', code: 2},
    ];

        
    const formik = useFormik({
        initialValues: {
            ID:'',
            LUGAR: null,
            TIPO: "",
            DETALLE: "",
            FECHA: new Date(),
            REPORTANTE: "",
            LUGAR_PERDIDA: "",
            RUTA:"",            
        },
        validationSchema: Yup.object(
            {
                LUGAR: Yup.object().nullable().required("Seleccione una opción!"),
                TIPO: Yup.string().required("Seleccione una opción!"),
                DETALLE: Yup.string().max(500,'No puede ingresar más de 500 caracteres.').required("Campo requerido!"),
                FECHA: Yup.string().required("Campo requerido!!"),
                REPORTANTE: Yup.string().max(50,'No debe ingresar más de 30 caracteres.'),
                LUGAR_PERDIDA: Yup.string().max(100,'No puede ingresar más de 100 caracteres.').required("Campo requerido!"),
            }
        ),
        onSubmit: async (data) => {
            const objetosPerdidos = new LostObjectsService();
            //Actualizamos el objeto perdido
            if( data.ID ){
                objetosPerdidos.update(data.ID, data)
                .then(response => {
                    props.updateTable();
                    onHide();
                    toast.current.show({ severity: 'success', summary: 'Correcto', detail: 'Los datos se actualizaron correctamente!' });
                })
                .catch(error=>{
                    console.log(error);
                });
            }
            //Creamos un nuevo objeto perdido
            else{
                objetosPerdidos.create(data)
                .then(response => {
                    props.updateTable();
                    onHide();
                    toast.current.show({ severity: 'success', summary: 'Correcto', detail: 'Los datos se guardaron correctamente!' });
                })
                .catch(error=>{
                    console.log(error);
                });
            }
        },
    });

    
      
    const onHide = () => {
        formik.resetForm();
        props.setShowHide(0);
    };
    const onShow = () => {
        if( props.showHide===2 ){            
            const lugarL =  { name: props.lostObjectSelected.LUGAR, code: props.lostObjectSelected.LUGAR } ; 
            formik.setFieldValue('LUGAR', props.lostObjectSelected.LUGAR ? lugarL : ''  );
            formik.setFieldValue('DETALLE', props.lostObjectSelected.DETALLE ? props.lostObjectSelected.DETALLE : ''  );
            formik.setFieldValue('LUGAR_PERDIDA', props.lostObjectSelected.LUGAR_PERDIDA ? props.lostObjectSelected.LUGAR_PERDIDA : '' );
            formik.setFieldValue('TIPO', props.lostObjectSelected.TIPO ? props.lostObjectSelected.TIPO : ''  );
            formik.setFieldValue('REPORTANTE',props.lostObjectSelected.REPORTANTE ? props.lostObjectSelected.REPORTANTE:'');
            formik.setFieldValue('ID', props.lostObjectSelected.ID ? props.lostObjectSelected.ID : '' );
            formik.setFieldValue('FECHA', props.lostObjectSelected.FECHA ? new Date(props.lostObjectSelected.FECHA ) :new Date() );
        }else{
            formik.resetForm();
            formik.setFieldValue('REPORTANTE',props.lostObjectSelected.REPORTANTE ? props.lostObjectSelected.REPORTANTE:'');
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    //Inicio carga de archivos
    const onUpload = (files) => {
        const response = JSON.parse(files.xhr.response);
        formik.setFieldValue('RUTA', response.filename);
        if( response){
            toast.current.show({ severity: 'info', summary: 'Correcto', detail: 'Archivo cargado!' });
        }else{
            toast.current.show({ severity: 'danger', summary: 'Error', detail: 'Ocurrio un error, por favor vuelva a intentarlo.' });
        }
    };
    //Fin carga de archivos

    
    return(
        <div>
            <Toast ref={toast}></Toast>
            <Dialog header="Registrar objeto encontrado" visible={props.showHide > 0} closeOnEscape={false} style={{ width: "50vw" }} onHide={() => onHide()} onShow={() => onShow()} >
                <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                    <div className="card">
                        <div className="p-fluid">
                            <div className="p-field p-grid">
                                <label htmlFor="LUGAR" className="p-col-12 p-md-2">
                                    Campus
                                </label>
                                <div className="p-col-12 p-md-10">
                                    <DropDowCities id="LUGAR" name="LUGAR" formik={formik} selectCity={selectCity}/>
                                    {getFormErrorMessage("LUGAR")}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid mb-3">
                            <div className="p-field p-grid">
                                <label htmlFor="TIPO" className="p-col-12 p-md-2">TIPO</label>
                                <div className="p-col-12 p-md-10">
                                    <Dropdown
                                        id="TIPO"
                                        value={ formik.values.TIPO }
                                        onChange={ formik.handleChange }
                                        options={ tipos }
                                        optionLabel="name"
                                        optionValue="code"
                                        name="TIPO"
                                        placeholder="--Seleccione--"
                                        className="field col-12 md:col-12 xs:col-12 p-0"
                                    />
                                    {getFormErrorMessage("TIPO")}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="p-field p-grid">
                                <label htmlFor="FECHA" className="p-col-12 p-md-2">
                                    Fecha
                                </label>
                                <div className="p-col-12 p-md-10">
                                    <Calendar className="field col-12 md:col-12 xs:col-12 p-0"
                                        id="FECHA"
                                        value={formik.values.FECHA}
                                        onChange={formik.handleChange}
                                        name='FECHA'
                                        showIcon
                                        dateFormat="dd-mm-yy"
                                        />
                                    {getFormErrorMessage("FECHA")}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="p-field p-grid">
                                <label htmlFor="OB" className="p-col-12 p-md-2">Detalle objeto</label>
                                <div className="p-col-12 p-md-10">
                                    <InputText className="field md:col-12 sm:col-12"
                                        id="DETALLE"
                                        name="DETALLE"
                                        value={ formik.values.DETALLE }
                                        onChange={ formik.handleChange }
                                        onBlur={ formik.handleBlur}
                                     />
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="p-field p-grid">
                                <label htmlFor="REPORTANTE" className="p-col-12 p-md-2">
                                    Reportante
                                </label>
                                <div className="p-col-12 p-md-10">
                                    <InputText className="field md:col-12 sm:col-12" 
                                        id="REPORTANTE" 
                                        value={ formik.values.REPORTANTE } 
                                        onChange={ formik.handleChange }
                                        onBlur={ formik.handleBlur }
                                        name="REPORTANTE"
                                        />
                                    {getFormErrorMessage("REPORTANTE")}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="p-field p-grid">
                                <label htmlFor="LUGAR_PERDIDA" className="p-col-12 p-md-2">
                                Lugar donde se encontró o perdió
                                </label>
                                <div className="p-col-12 p-md-10">
                                    <InputText className="field md:col-12 sm:col-12" 
                                        id="LUGAR_PERDIDA" 
                                        value={formik.values.LUGAR_PERDIDA} 
                                        onChange={formik.handleChange}
                                        name="LUGAR_PERDIDA" 
                                        />
                                    {getFormErrorMessage("LUGAR_PERDIDA")}
                                </div>
                            </div>
                        </div>

                        <input type="hidden" id="IMG" name="IMG" />
                        <input type="hidden" id="ID" name="ID" />

                        <div className="card flex justify-content-left">
                            <FileUpload
                                id="doc_digital"
                                name="doc_digital"
                                url={process.env.REACT_APP_UPLOAD_URL}
                                accept=".jpg, .jpeg, .png"
                                maxFiles={3}
                                maxFileSize={1000000}
                                chooseLabel="Seleccionar imagenes"
                                onUpload={onUpload}
                                mode="basic"
                                auto
                                emptyTemplate={<p className="m-0">Arrastre y suelte los archivos aquí para cargarlos.</p>}
                            >
                            </FileUpload>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button type="button" label="Cancelar" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                        <Button type="submit" label="Guardar" icon="pi pi-save" autoFocus />
                    </div>
                </form>
            </Dialog>
        </div>
    );
}