import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { iconObj } from "./icon.helper";
const menuQuery = ` COD LABEL ICON TO BADGE URL COMMAND DISABLED TARGET `;

const menuGraphql = (limit, num = 1) => {
    if (num === limit) {
        return menuQuery;
    } else {
        return `${menuQuery}
            ITEMS { ${menuGraphql(limit, num + 1)}}
            `;
    }
};

const getIcon = (strIcon) => {
    let icon = <i className={strIcon}></i>;
    if (iconObj[strIcon] && typeof iconObj[strIcon] === "object") {
        icon = <FontAwesomeIcon icon={iconObj[strIcon]} />;
    } else if (iconObj[strIcon]) {
        icon = <i className={iconObj[strIcon]}></i>;
    }
    return icon;
};

export { menuGraphql, getIcon };
