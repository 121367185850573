import React from "react";
import { Navigate } from "react-router-dom";
import { getMainPathame } from "../../helpers/session.helper";
import FormLogin from "../../components/login/FormLogin";
import LayoutLoading from "../other/LayoutLoading";

const LayoutLogin = (props) => {
    const getValidatedComponent = () => {
        if (props.haveAccess === null) {
            return <LayoutLoading />;
        }

        if (props.haveAccess === true) {
            return <Navigate to={getMainPathame()} />;
        }

        if (props.haveAccess === false) {
            return <FormLogin setHaveAccess={props.setHaveAccess} />;
        }
    };
    return <>{getValidatedComponent()}</>;
};

export default LayoutLogin;
