import axios from "axios";
import { getToken } from "../../helpers/token.helper";
import areaEspecialidad from "../../constants/publicaciones/inv.areaEspecialidad";

export class AreaEspecialidadService {
    getAll() {
        return axios
            .post(
                areaEspecialidad.apiUrl,
                {
                    query: `query{${areaEspecialidad.queries.getAll()}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.areasEspecialidad;
            });
    }

    getOne(id) {
        return axios
            .post(
                areaEspecialidad.apiUrl,
                {
                    query: `query{${areaEspecialidad.queries.getOne(id)}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.areaEspecialidad;
            });
    }

    insertAreaEspecialidad(data) {
        return axios
            .post(
                areaEspecialidad.apiUrl,
                {
                    query: `mutation{${areaEspecialidad.queries.create(data)}}`,
                },
                {
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                        "access-token": getToken(),
                    },
                }
            )
            .then((res) => {
                return res.data.data.createAreaEspecialidad;
            });
    }
}
